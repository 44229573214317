import {
  Component,
  AfterViewInit,
  OnDestroy,
  Input,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
} from '@angular/core';
import { ChartConfiguration, LegendElement } from 'chart.js';
import { Chart } from 'chart.js/dist';
import { BaseChartDirective } from 'ng2-charts';

@Component({
  selector: 'ts-chart',
  template: `
    <div>
      <canvas
        baseChart
        [data]="data"
        [options]="options"
        [type]="'bar'"
        [legend]="legend"
        [attr.width]="width"
        [attr.height]="height"
      ></canvas>
    </div>
  `,
})
export class TsChartComponent {
  @Input() type: string = 'bar';

  @Input() width: string = '800px';

  @Input() height: string = '400px';

  _data: any = {};

  @ViewChild(BaseChartDirective)
  private _chart!: BaseChartDirective;

  @Input() public legend: boolean = false;

  // Test data
  public barChartData: ChartConfiguration<'bar'>['data'] = {
    labels: [
      '13:00',
      '13:15',
      '13:30',
      '13:45',
      '14:00',
      '14:15',
      '14:30',
      '14:45',
      '15:00',
      '15:15',
      '15:30',
      '15:45',
      '15:46',
    ],
    datasets: [
      {
        data: [95, 39, 46, 28, 11, 23, 17, 24, 21, 24, 40, 37, 40],
        label: 'Arrival curb',
        backgroundColor: 'red',
        stack: 'bac',
      },
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        label: 'Transfer Res-Ac',
        backgroundColor: 'green',
        stack: 'bac',
      },
      {
        data: [35, 29, 28, 29, 62, 278, 308, 338, 0, 0, 12, 20, 9],
        label: 'Reservoir',
        backgroundColor: 'yellow',
        stack: 'bac',
      },
      {
        data: [0, 1, 6, 8, 0, 0, 0, 1, 23, 53, 83, 57, 68],
        label: 'Transfer Pt-Res',
        backgroundColor: 'black',
        stack: 'bac',
      },
      {
        data: [79, 79, 76, 53, 50, 75, 75, 142, 172, 156, 77, 46, 74],
        label: 'PT',
        backgroundColor: 'blue',
        stack: 'bac',
      },
    ],
  };

  @Input()
  public options: ChartConfiguration<'bar'>['options'] = {
    responsive: true,
    maintainAspectRatio: true,
    animation: false,
  };

  constructor(public el: ElementRef) {}

  @Input() get data(): any {
    return this._data;
  }

  set data(val: any) {
    this._data = val || {};
  }
}
