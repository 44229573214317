import { Component, ViewEncapsulation, Output, EventEmitter } from '@angular/core';

import { Person } from '../model';
import { KeyholdersListComponent } from '../entity-component/keyholders-list.component';

@Component( {
    selector: 'persons-list',
    templateUrl: './persons-list.component.html',
    styleUrls: ['./persons-list.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class PersonsListComponent extends KeyholdersListComponent<Person> {

    @Output()
    public download = new EventEmitter();

    protected getEditItemPath(): string {
        return '/edit-person';
    }

    public getBadgeStatus( item: Person ) {
        return ( !!item && !!item.properties && -1 != Object.keys( item.properties ).indexOf( "PERSON_BADGE_STATUS" ) )
            ? item.properties["PERSON_BADGE_STATUS"] : undefined;
    }
}
