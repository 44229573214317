import { Keyholder } from './keyholder';
import { Address } from './address';

export class Person extends Keyholder {
    firstName?: string;
    lastName?: string
    birthDate?: Date;
    phone?: string;
    email?: string;
    address?: Address;
    identity?: string;
}
