<div class="container-fluid">
    <div>
        <p-table [value]="items" [multiSortMeta]="sortSettings?.multisortmeta" [loading]="loading" (onSort)="onSort($event)"
          selectionMode="single" (onRowSelect)="select($event.data)" [resizableColumns]="true"
          sortMode="multiple" [rows]="rows"  [first]="first" (onSort)="onSort($event)" (onPage)="onPage($event)"
           [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" [reorderableColumns]="true">
            <ng-template pTemplate="caption">
                <div class="pull-left"><h4>Locks</h4></div>

                <div class="pull-right">
                    <button mat-icon-button color="primary" (click)="add()" type="button">
                       <mat-icon fontSet="fa" fontIcon="fa-plus" class="mat-24"></mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="search.emit()" type="button">
                       <mat-icon fontSet="fa" fontIcon="fa-search" class="mat-24"></mat-icon>
                    </button>
                </div>
                <div class="clearfix"></div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>SerialNr</th>
                    <th>Lock ID</th>
                    <th>Description</th>
                    <th>Validity start</th>
                    <th>Validity end</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-lock>
                <tr [pSelectableRow]="rowData">
                    <td>{{ lock.id }}</td>
                    <td>{{ lock.name }}</td>
                    <td>{{ lock.serialnr }}</td>
                    <td>{{ lock.identity }}</td>
                    <td>{{ lock.description }}</td>
                    <td>{{lock.validity?.validityStart | date:'medium'}}</td>
                    <td>{{lock.validity?.validityEnd | date:'medium'}}</td>
                    <td>
                      <button mat-icon-button color="accent" (click)="remove($event, lock)">
                        <mat-icon fontSet="fa" fontIcon="fa-trash-can"></mat-icon>
                      </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>
