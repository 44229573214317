import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component( {
    selector: 'node-address',
    styles: ['label {display: block;max-width: 100%;font-weight: 500;color: rgba(0,0,0,0.9);}', '.full-width { width: 100%;}'],
    template: `<p-panel [formGroup]="form">
                <p-header>Address</p-header>

                <div class="t-grid t-flex-row t-flex-wrap t-align-content-center t-justify-content-start">
                    <div class="t-col-7">
                          <mat-form-field class="full-width">
                              <mat-label>Street</mat-label>
                              <input matInput [readonly]="readonly" class="full-width" type="text" id="street" formControlName="street" name="street"/>
                          </mat-form-field>
                    </div>
                    <div class="t-col-5">
                          <mat-form-field class="full-width">
                              <mat-label>City</mat-label>
                              <input matInput [readonly]="readonly" class="full-width" type="text" id="city" formControlName="city" name="city"/>
                          </mat-form-field>
                    </div>
                    <div class="t-col-3">
                          <mat-form-field class="full-width">
                              <mat-label>ZIP</mat-label>
                              <input matInput [readonly]="readonly" class="full-width" type="text" id="zip" formControlName="zip" name="zip"/>
                          </mat-form-field>
                    </div>
                    <div class="t-col-4">
                          <mat-form-field class="full-width">
                              <mat-label>State</mat-label>
                              <input matInput [readonly]="readonly" class="full-width" type="text" id="state" formControlName="state" name="state"/>
                          </mat-form-field>
                    </div>
                    <div class="t-col-5">
                          <mat-form-field class="full-width">
                              <mat-label>Country</mat-label>
                              <input matInput [readonly]="readonly" class="full-width" type="text" id="country" formControlName="country" name="country"/>
                          </mat-form-field>
                    </div>
                    <ng-content></ng-content>
                </div>
               </p-panel>`
})
export class NodeAddressComponent {

    @Input()
    public form!: FormGroup;

    @Input()
    public readonly: boolean = false;

    get isValid() { return this.form.valid; }
}
