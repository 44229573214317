import { Directive } from '@angular/core';
import { map } from 'rxjs/operators';

import { EntitiesComponent } from '../entity-component/entities.component';

import { Keyholder } from '../model';
import { ItemService } from '../services';

@Directive()
export class KeyholdersComponent<
  Item extends Keyholder
> extends EntitiesComponent<Item> {
  constructor(
    protected override ctor: { new (): Item },
    protected override itemService: ItemService<Item>
  ) {
    super(ctor, itemService);
  }

  override ngOnInit() {
    super.ngOnInit();

    this.items = this.items.pipe(
      map((keyholders) => {
        keyholders.forEach((kh) => {
          (kh as any)['keySerial'] =
            !!kh.keys && 0 < kh.keys.length && !!kh.keys[0].key
              ? kh.keys[0].key.serialnr
              : '';
          (kh as any)['blacklisted'] = !!kh.validity && !kh.validity.valid;
          (kh as any)['companyName'] = !!kh.tfCompany ? kh.tfCompany.name : '';
        });
        return keyholders;
      })
    );
  }
}
