import { TfCompany } from './../model/tfcompany';

export class Features {
  TAXI: CarsPerArea = new CarsPerArea();
  BREAK: CarsPerArea = new CarsPerArea();
  MINIVAN: CarsPerArea = new CarsPerArea();
  TWALTER: CarsPerArea = new CarsPerArea();
  UNITAX: CarsPerArea = new CarsPerArea();
  AUTOTAX: CarsPerArea = new CarsPerArea();
}

type FeaturesType = keyof Features;

export class Car {
  licensePlate: string = '';
  rfID?: string;
  access?: string[];
  carFeatures: string[] = [];
  availableFeatures?: string[];
  assignedTo?: number;
  assignedRequest?: number;
  tagCounter?: string;
  blacklisted?: boolean = false;
  company?: TfCompany;
}

export class CarsPerArea {
  T2: Car[] = [];
  T2T1: Car[] = [];
  T1: Car[] = [];
  T1T0: Car[] = [];
  T0: Car[] = [];
}

export class CarsWithFeaturesInArea {
  TAXI: Car[] = [];
  BREAK: Car[] = [];
  MINIVAN: Car[] = [];
  TWALTER: Car[] = [];
  UNITAX: Car[] = [];
  AUTOTAX: Car[] = [];
}

export class Controller {
  entry: string = '';
  online: boolean = false;
}

export class BoardArea {
  name: string;
  cars: Car[];
  entries: Controller[];
  inUse?: boolean;
  manualInUse?: boolean;
  recoveryMode: boolean;
  occupancy: number;

  public constructor(name: string) {
    this.name = name;
    this.cars = [];
    this.entries = [];
    this.recoveryMode = false;
    this.occupancy = 0;
  }
}

export class AreasType {
  T2: BoardArea = new BoardArea('T2');
  T2T1: BoardArea = new BoardArea('T2T1');
  T1: BoardArea = new BoardArea('T1');
  T1T0: BoardArea = new BoardArea('T1T0');
  T0: BoardArea = new BoardArea('T0');
}

export class RequestPerType {
  type = '';
  requested = 0;
  inT0 = 0;

  public constructor(stype: string) {
    this.type = stype;
  }
}

export class RequestPerTypeMap {
  BREAK: RequestPerType = new RequestPerType('BREAK');
  MINIVAN: RequestPerType = new RequestPerType('MINIVAN');
  TWALTER: RequestPerType = new RequestPerType('TWALTER');
  UNITAX: RequestPerType = new RequestPerType('UNITAX');
  AUTOTAX: RequestPerType = new RequestPerType('AUTOTAX');
}

export class RequestsPerBrigadier {
  requestsPerType: RequestPerTypeMap = new RequestPerTypeMap();
}

export class SpecialCarRequest {
  requestId?: number;
  featureRequested?: string[];
  brigadier?: number;
  requestCreated?: Date;
  inT0?: boolean;
}

export class Display {
  display?: string;
  line1?: string;
  line2?: string;
}

export class Airport {
  displays: Display[] = [];
  areas: BoardArea[] = [];
  cars: Car[] = [];
  requests: SpecialCarRequest[] = [];
  status: any = {};
  expressMode: boolean = false;
  recoveryMode: boolean = false;
  systemDown: boolean = false;
  sensits: { freeSensors: boolean[] } = { freeSensors: [] };
}

export class Alert {
  type?: string;
  timestamp?: Date;
  message?: string;
}
