<h1 class="title">Dashboard<i class="fa" [ngClass]="{'fa-chain':(dashboardOnline | async), 'fa-chain-broken':(!(dashboardOnline | async))}"></i></h1>
<span *ngIf="!(ap | async)">Loading...</span>

<div class="t-grid t-flex-row t-justify-content-start overview-content">
    <div class="t-col-9 t-grid t-flex-column">
      <div class="t-grid t-flex-row">
        <p-panel *ngFor="let disp of (ap | async)?.displays" class="t-col-6 bacdisplay baccard">
            <p-header class="title">
                <i class="fa fa-tv"></i>{{disp.display}}
            </p-header>
          <ul>
            <li>{{disp.line1}}</li>
            <li>{{disp.line2}}</li>
          </ul>
        </p-panel>
      </div>
      <div class="areas-grid">
        <p-panel *ngFor="let area of (areas | async)" class="bacarea baccard">
          <p-header class="title">
              {{area.name}}
              <ul class="pull-right">
              <li *ngFor="let ctrl of area.entries" class="baccontroller">{{ctrl.entry}}:<i class="fa"
                [ngClass]="{'fa-chain':ctrl.online, 'fa-chain-broken':(!ctrl.online)}"></i>
              </li>
              </ul>
          </p-header>
          <ul>
            <li *ngFor="let car of area.cars"
              pTooltip="<table>
            <tr><td>License plate:</td><td>{{car.licensePlate}}</td></tr>
            <tr><td>Company:</td><td>{{car.company?.name}}</td></tr>
            </table>"
              tooltipPosition="top" [escape]="ttEscape" tooltipStyleClass="car-tooltip">
              <i class="fa fa-cab" [ngClass]="{'car-blacklisted': car.blacklisted}"></i>{{car.licensePlate}} ({{car.tagCounter}})
              <i *ngIf="-1 !== car.carFeatures.indexOf('BREAK')" class="fa fa-truck" ></i>
                        <i *ngIf="-1 !== car.carFeatures.indexOf('PRM')" class="fa fa-credit-card"></i>
                        <i *ngIf="-1 !== car.carFeatures.indexOf('MINIVAN')" class="fa fa-bus"></i>
                        <i *ngIf="-1 !== car.carFeatures.indexOf('TWALTER')">TW</i>
                        <i *ngIf="-1 !== car.carFeatures.indexOf('UNITAX')">UT</i>
                        <i *ngIf="-1 !== car.carFeatures.indexOf('AUTOTAX')">AL</i>
              </li>
          </ul>
        </p-panel>
      </div>
    </div>

    <div class="t-col-3">
        <p-panel class="t-col-12 sensors baccard" *ngIf="false">
           <p-header class="title">
               Sensors
           </p-header>
           <div fxLayout="row" fxLayoutAlign="center">
                <div fxLayout="row" fxLayoutAlign="space-evenly" fxLayoutWrap fxFlex="1 1 auto">
                    <span fxFlex="1 0 20%" class="fa fa-podcast" *ngFor="let s of sensits | async" [ngClass]="{'free':s, 'busy':!s}"></span>
                </div>
                <div fxFlex="0 1 auto" class="sas" fxLayout="row" fxLayoutAlign="center center">
                    <span>SAS</span>
                </div>
            </div>
        </p-panel>
        <p-panel class="t-col-12 alerts baccard">
          <p-header class="title">
              Alert messages
          </p-header>
          <ul>
              <li *ngFor="let alert of alerts | async" class="bacalert">
                    <i class="fa" [ngClass]="{'fa-info-circle':(alert.type==='INFO'), 'fa-warning':(alert.type==='WARN')}"></i>
                    <span class="datetime">{{alert.timestamp | date:'short'}}</span> {{alert.message}}
              </li>
		   </ul>
      </p-panel>
    </div>
    <div class="t-col-2" *ngIf="false">
        <p-panel class="t-col-12 bacarea baccard offsite">
            <p-header class="title">
                 {{(offsite|async)?.name}}
                 <ul class="pull-right">
                    <li *ngFor="let ctrl of ((offsite|async)?.entries || [])" class="baccontroller">{{ctrl.entry}}:<i class="fa"
                        [ngClass]="{'fa-chain':ctrl.online, 'fa-chain-broken':(!ctrl.online)}"></i>
                    </li>
                 </ul>
            </p-header>
            <ul>
                <li *ngFor="let car of (offsite|async)?.cars"
                    pTooltip="<table>
                <tr><td>License plate:</td><td>{{car.licensePlate}}</td></tr>
                <tr><td>Company:</td><td>{{car.company.name}}</td></tr>
                </table>"
                    tooltipPosition="top" [escape]="ttEscape" tooltipStyleClass="car-tooltip">
                    <i class="fa fa-cab" [ngClass]="{'car-blacklisted': car.blacklisted}"></i>{{car.licensePlate}} ({{car.tagCounter}})
                    <i *ngIf="-1 !== car.carFeatures.indexOf('BREAK')" class="fa fa-truck" ></i>
                    <i *ngIf="-1 !== car.carFeatures.indexOf('PRM')" class="fa fa-credit-card"></i>
                    <i *ngIf="-1 !== car.carFeatures.indexOf('MINIVAN')" class="fa fa-bus"></i>
                    <i *ngIf="-1 !== car.carFeatures.indexOf('TWALTER')">TW</i>
                    <i *ngIf="-1 !== car.carFeatures.indexOf('UNITAX')">UT</i>
                    <i *ngIf="-1 !== car.carFeatures.indexOf('AUTOTAX')">AL</i>
                 </li>
            </ul>
        </p-panel>
    </div>


</div>
