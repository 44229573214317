import { Validity } from '../model';

export function dateReviver(key: string, value: any): any {
    if (typeof value === 'string') {
        // "2017-12-21T14:24:32.716+0000"
        let a = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?).*$/.exec(value);
        if (a) {
            //return new Date(Date.UTC(+a[1], +a[2] - 1, +a[3], +a[4], +a[5], +a[6]));
            return new Date(value);
        }
    }
    return value;
}
;

export function clone<Item>(o: Item): Item {
    return JSON.parse(JSON.stringify(o), dateReviver);
}
;

function minDate(a: Date, b: Date): Date {
    if (!a) return b;
    if (!b) return a;
    return (a <= b) ? a : b;
}

function maxDate(a: Date, b: Date): Date {
    if (!a) return b;
    if (!b) return a;
    return (a >= b) ? a : b;
}

function dateInterval(a: Date, b: Date): boolean {
    if (!a || !b) return true;
    return (a <= b);
}

export function intersectValidity(a: Validity, b: Validity): Validity | null {
    if (!a) return b;
    if (!b) return a;

    let v: Validity = new Validity();
    v.validityStart = maxDate(a.validityStart, b.validityStart);
    v.validityEnd = minDate(a.validityEnd, b.validityEnd);
    return dateInterval(v.validityStart, v.validityEnd) ? v : null;
}
