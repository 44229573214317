
import {map} from 'rxjs/operators';
import {Component, OnInit} from '@angular/core';

import {EntitiesComponent} from '../entity-component/entities.component';

import {TfCompany} from '../model';
import {TfCompaniesService} from '../services/tfcompanies.service';

export {TfCompaniesListComponent} from './tfcompanies-list.component';
export {TfCompanyFormComponent} from './tfcompany-form.component';

@Component({
  selector: 'app-tfcompanies',
  templateUrl: './tfcompanies.component.html',
  styleUrls: ['./tfcompanies.component.scss'],
})
export class TfCompaniesComponent extends EntitiesComponent<TfCompany> implements OnInit {

  constructor(protected tfcompanyService: TfCompaniesService) {
    super(TfCompany, tfcompanyService);
  }

  override ngOnInit() {
    super.ngOnInit()

    this.items = this.items.pipe(map(companies => {
      companies.forEach(comp => {
        (comp as any)['blacklisted'] = !!comp.validity && !comp.validity.valid;
      });
      return companies;
    }));
  }

}
