<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd>
   <p-footer>
        <button type="button" pButton icon="fa-check" label="Yes" (click)="cd.accept()"></button>
        <button type="button" pButton icon="fa-close" label="Cancel" (click)="cd.reject()"></button>
    </p-footer>
</p-confirmDialog>

<search-form #searchForm (search)="setFilter([
    {'field':'firstname', 'value':firstname.value},
    {'field':'lastname', 'value':lastname.value},
    {'field':'email', 'value':email.value}
    ])" (reset)="firstname.value=''; lastname.value=''; email.value=''; setFilter([]); searchForm.hide()">
    <mat-form-field class="t-col-4">
        <input matInput #firstname placeholder="Filter by first name" [autofocus]="true" class="full-width" />
    </mat-form-field>
    <mat-form-field class="t-col-4">
        <input matInput #lastname placeholder="Filter by last name" class="full-width" />
    </mat-form-field>
    <mat-form-field class="t-col-4">
        <input matInput #email placeholder="Filter by e-mail" class="full-width" />
    </mat-form-field>
</search-form>

<admins-list [items]="(items | async) || []" *ngIf="!editing" (selected)="itemSelected($event)" (added)="newItem()" (search)="searchForm.show()"
(deleted)="deleteItem($event)"
[(sortSettings)]="sortSettings" [(paging)]="paging"></admins-list>
<admin-form [item]="selectedItem | async" *ngIf="!!editing" [errorMsgs]="(errorMsg | async) || []" (canceled)="editCancel()" (saved)="saveItem($event)"></admin-form>
