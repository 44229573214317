<div class="container-fluid">
       <form class="full-width t-grid t-flex-row t-flex-wrap t-gap-1 t-justify-content-start t-align-content-center" #searchForm>
            <mat-form-field class="t-flex-auto plate-field">
                <input matInput #licensePlate placeholder="Filter by license plate" [autofocus]="true" class="full-width" (keyup.enter)="sendCmd(searchForm)" />
            </mat-form-field>
            <div class="t-flex-auto calendar-field" *ngIf="showDate">
                <label for="filterDate" fxFlex="0 0 auto" style="margin-right: 10px;">For date:</label>
                <p-calendar class="t-flex-auto" id="filterDate" name="filterDate" #filterDate [showTime]="false" [showIcon]="true"></p-calendar>
            </div>
            <div class="t-flex-auto buttons-field" >
                <div style="height: 10px;"></div>
                <div class="t-flex-grow-1 t-grid t-flex-row t-gap-2">
                    <button mat-raised-button (click)="sendCmd(searchForm)" type="button" class="t-flex-auto btn btn-primary">Filter</button>
                    <button mat-raised-button (click)="searchForm.licensePlate.value = null; searchForm.filterDate.value = null; sendCmd(searchForm)" type="button" class="t-flex-auto btn btn-primary">Reset</button>
                </div>
                <div style="height: 10px;"></div>
            </div>
       </form>

	   <p-table #dt [value]="(alerts | async) || []" [loading]="loading" [resizableColumns]="true"  [responsive]="true"
	       [rows]="20" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[10,20,50,100]" [reorderableColumns]="true" >
            <ng-template pTemplate="caption">
                <div class="pull-left"><h4>Transitions</h4></div>
                <div class="clearfix"></div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Time</th>
                    <th>Car</th>
                    <th>Driver</th>
                    <th>Entry</th>
                    <th>Old state</th>
                    <th>New state</th>
                    <th>Granted</th>
                    <th>Message</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-car>
                <tr>
                    <td>{{ car.time | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td>{{ car.car }}</td>
                    <td>{{ car.driver }}</td>
                    <td>{{ car.entry }}</td>
                    <td>{{ car.from }}</td>
                    <td>{{ car.to }}</td>
                    <td><span [ngClass]="{'red': !car.granted, 'green': car.granted }">{{car.granted}}</span></td>
                    <td><span class="trans-message">{{car.msg}}</span></td>
                </tr>
            </ng-template>
        </p-table>
</div>
