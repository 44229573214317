
<form *ngIf="selectedItem"  [formGroup]="form" (ngSubmit)="save(selectedItem)">
    <p-panel>
        <p-header>
                <h2 *ngIf="selectedItem.id">Editing '{{originalName}}'</h2>
                <h2 *ngIf="!selectedItem.id">Create New {{entityName}}</h2>
        </p-header>

        <div class="t-grid t-flex-row t-flex-wrap t-gap-3 t-align-content-center t-justify-content-start">
            <!-- Item specifics start here -->
            <div class="t-col-1">
                <mat-form-field class="full-width">
                    <mat-label>Person ID</mat-label>
                    <input matInput class="full-width" type="text" id="personID" formControlName="identity" readonly/>
                </mat-form-field>
            </div>
            <div class="t-col-2">
                <!-- dividerColor="{{ !form.controls.firstName.valid ? 'warn' : 'primary' }}" -->
                <mat-form-field class="full-width" >
                    <mat-label>First name</mat-label>
                    <input matInput [readonly]="isFieldReadonly('firstName')" class="full-width" type="text" id="firstName" formControlName="firstName" required />
                    <mat-hint align="start" [class.alert-danger]="!form.controls.firstName.valid" *ngIf="!form.controls.firstName.valid">First name must not be empty</mat-hint>
                </mat-form-field>
            </div>
            <div class="t-col-2">
                <!-- dividerColor="{{ !form.controls.lastName.valid ? 'warn' : 'primary' }}" -->
                <mat-form-field class="full-width" >
                  <mat-label>Last name</mat-label>
                    <input matInput [readonly]="isFieldReadonly('lastName')" class="full-width" type="text" id="lastName"
                        required formControlName="lastName" name="lastName" />
                    <mat-hint align="start" [class.alert-danger]="!form.controls.lastName.valid" *ngIf="!form.controls.lastName.valid">Last name must not be empty</mat-hint>
                </mat-form-field>
            </div>
            <div class="t-col-2 company-select" [formGroup]="$any(form.controls.tfCompany)">
              <mat-form-field class="full-width">
                <mat-label>Company</mat-label>
                <mat-select class="full-width" formControlName="id" required>
                    <mat-option *ngFor="let company of tfCompanies" [value]="company.id">{{ company.name }}</mat-option>
                </mat-select>
                </mat-form-field>
            </div>
            <div class="t-col-2">
                <mat-form-field class="full-width">
                    <mat-label>Nationality</mat-label>
                    <input matInput [readonly]="isFieldReadonly('nationality')" type="text" id="nationality" formControlName="nationality"
                        name="nationality"/>
                </mat-form-field>
            </div>
            <div class="t-col-2">
                <mat-form-field class="full-width">
                    <mat-label>Rijksregisternummer</mat-label>
                    <input matInput [readonly]="isFieldReadonly('rijksregisternummer')" type="text" id="rijksregisternummer" formControlName="rijksregisternummer"
                        name="rijksregisternummer"/>
                </mat-form-field>
            </div>

            <p-tabView class="t-col-12">
              <p-tabPanel header="Personal info" leftIcon="fa fa-id-badge">
                  <div class="t-grid t-flex-row t-gap-3">
                      <div class="t-col-3 t-grid t-flex-wrap t-flex-column t-align-content-stretch">
                        <p-panel class="t-col-12">
                            <p-header>Access rights</p-header>
                            <mat-checkbox formControlName="pickup" class="t-col-12" aria-label="pickup">PickUp flow</mat-checkbox>
                            <mat-checkbox formControlName="dropoff" class="t-col-12" aria-label="dropodd">DropOff flow</mat-checkbox>
                            <mat-checkbox formControlName="p12p13" class="t-col-12" aria-label="p12p13">P12/P13 flow</mat-checkbox>
                            <mat-checkbox formControlName="isBrigadier" class="t-col-12 brigadier" aria-label="isBrigadier">Brigadier</mat-checkbox>
                            <div class="clearfix"></div>
                        </p-panel>
                        <p-panel class="t-col-12">
                            <p-header>Badge ( {{badgeStatus}} )</p-header>
                            <mat-form-field class="full-width">
                                <mat-label>Person's badge ID</mat-label>
                              <input matInput type="text" formControlName="keyRFID" type="text" id="keyRFID" name="keyRFID">
                              <mat-hint align="start" [class.alert-danger]="!form.controls.keyRFID.valid" *ngIf="!form.controls.keyRFID.valid">{{form.controls.keyRFID.errors?.error}}</mat-hint>
                            </mat-form-field>
                        </p-panel>
                      </div>

                      <p-panel class="t-col-3 t-grid t-flex-wrap t-flex-column t-align-content-stretch">
                          <p-header>Blacklist</p-header>
                          <node-blacklisting class="t-flex-auto" [form]="$any(form.controls.blacklisting)"></node-blacklisting>
                          <div class="clearfix"></div>
                      </p-panel>

                      <node-address class="t-col-6 t-grid t-flex-wrap t-flex-column t-align-content-stretch" [readonly]="isFieldReadonly('address')" [form]="$any(form.controls.address)">
                              <div class="t-col-3">
                                <mat-form-field class="full-width">
                                  <mat-label>GSM number</mat-label>
                                    <input matInput [readonly]="isFieldReadonly('phone')" class="full-width" type="text"
                                        formControlName="phone" name="phone"/>
                                </mat-form-field>
                              </div>
                              <div class="t-col-4">
                                <mat-form-field class="full-width">
                                  <mat-label>E-mail</mat-label>
                                    <input matInput [readonly]="isFieldReadonly('email')" class="full-width" type="text"
                                        formControlName="email" name="email" />
                                </mat-form-field>
                              </div>
                              <div class="t-col-5 t-flex-auto">
                                <mat-form-field class="full-width">
                                  <mat-label>Birth date</mat-label>
                                  <input matInput [matDatepicker]="picker" readonly formControlName="birthDate">
                                  <mat-datepicker-toggle mdSuffix [for]="$any(picker)" *ngIf="!isFieldReadonly('birthDate')"></mat-datepicker-toggle>
                                  <mat-datepicker #picker [startAt]="bdStartDate" (selectedChanged)="setBirthDate($event)"></mat-datepicker>
                                </mat-form-field>
                              </div>
                      </node-address>
                  </div>
              </p-tabPanel>
            </p-tabView>
            <!-- Item specifics end here -->
        </div>

        <div class="clearfix"></div>
        <ul class="t-col-12" *ngIf="errorMsgs?.length" class="alert alert-danger">
          <li *ngFor="let err of errorMsgs">{{err}}</li>
        </ul>

        <p-footer>
            <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Submit</button>
            <button mat-raised-button (click)="cancel(selectedItem)" type="button">Cancel</button>
        </p-footer>

    </p-panel>
</form>

<div class="t-col-12" *ngIf="debug">
    <pre>{{ debug | json }}</pre>
</div>


<div *ngIf="!selectedItem">{{entityName}} does not exists</div>

