import { Component, ViewEncapsulation, OnInit, OnChanges, Inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';


import { TfCompany, Validity } from '../model';
import { EntityFormComponent } from '../entity-component/entity-form.component';
import { TfCompaniesService } from '../services';


@Component( {
    selector: 'tfcompany-form',
    templateUrl: './tfcompany-form.component.html',
    styleUrls: ['./tfcompany-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TfCompanyFormComponent extends EntityFormComponent<TfCompany> implements OnInit, OnChanges {

    constructor( protected override _itemService: TfCompaniesService ) {
        super( _itemService );
    }

    protected createForm(): FormGroup {
        return this.fb.group( {
            name: ['', [Validators.required]],
            number: ['', [Validators.required]],
            description: '',
            email: '',
            blacklisting: this.fb.group( {
                blacklisted: true,
                blacklistingStart: new Date(),
                blacklistingEnd: new Date()
            })
        });
    }

    override ngOnInit() {
        super.ngOnInit();
    }

    override ngOnChanges() {
        this.form.patchValue( this.selectedItem || {} );
        this.form.controls['blacklisting'].setValue( this.blacklisting );
        this.form.controls['email'].setValue(this.getProperty('email'));
    }

    public override save( item: TfCompany ) {
        console.log( 'Form value: ', this.form.value );
        let x = Object.assign( new TfCompany(), this.selectedItem, this.form.value );
        x = this.setValidity( x );
        x.properties = {
                ...x.properties,
                'email': x.email
        };
        delete(x.email);
        console.log( 'Object value: ', x );
        super.save( x );
    }

    protected getEntityName(): string {
        return 'TfCompany';
    }

    public get blacklisting(): any {
        let l = this.selectedItem?.['validity'];
        let x = ( !l || l.valid )
            ? { blacklisted: false, blacklistingStart: new Date(), blacklistingEnd: new Date() }
            : { blacklisted: true, blacklistingStart: l.validityStart, blacklistingEnd: l.validityEnd };

        return x;
    }

    protected setValidity( x: any ) {
        if ( !x.blacklisting.blacklisted ) {
            delete x['validity'];
            delete x['blacklisting'];
            return x;
        }
        if ( !x.validity ) {
            x.validity = new Validity();
        }
        x.validity.valid = false;
        x.validity.validityStart = x.blacklisting.blacklistingStart;
        x.validity.validityEnd = x.blacklisting.blacklistingEnd;
        delete x.validity['id'];
        delete x['blacklisting'];
        return x;
    }
}
