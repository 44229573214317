import { map, filter, tap } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, Action } from '@ngrx/store';
import { Filter, StoreAction } from '../store/tayarac-store';
import { clone } from '../utils/utils';

import { baseURL, ItemService } from './item.service';
import { Area, Validity, JsonResponse } from '../model';

import { dateReviver } from '../utils/utils';

export class AREAS_ACTIONS {
  static SET_AREAS = 'SET_AREAS';
  static CREATE_AREA = 'CREATE_AREA';
  static UPDATE_AREA = 'UPDATE_AREA';
  static DELETE_AREA = 'DELETE_AREA';
  static SELECT_AREA = 'SELECT_AREA';
  static SET_AREAS_FILTER = 'SET_AREAS_FILTER';
  static CLEAR_AREAS_FILTER = 'CLEAR_AREAS_FILTER';
}

// The 'areas' reducer performs actions on our list of areas
export const areas = areasFn;
export function areasFn(state: Area[] = [], action: StoreAction) {
  switch (action.type) {
    case AREAS_ACTIONS.SET_AREAS:
      return action.payload;
    case AREAS_ACTIONS.CREATE_AREA:
      let x = state.filter((item) => item.id === action.payload.id).length;
      return x ? state : [...state, action.payload];
    case AREAS_ACTIONS.UPDATE_AREA:
      return state.map((item) => {
        let val = clone(action.payload);
        return item.id === action.payload.id ? val : item;
      });
    case AREAS_ACTIONS.DELETE_AREA:
      return state.filter((item) => {
        return item.id !== action.payload.id;
      });
    default:
      return state;
  }
}

// The 'selectedArea' reducer handles the currently selected area
export const selectedArea = selectedAreaFn;
export function selectedAreaFn(state: Area | null = null, action: StoreAction) {
  switch (action.type) {
    case AREAS_ACTIONS.SELECT_AREA:
      return action.payload;
    default:
      return state;
  }
}

export const areasFilter = areasFilterFn;
export function areasFilterFn(state: Filter[] = [], action: StoreAction) {
  switch (action.type) {
    case AREAS_ACTIONS.SET_AREAS_FILTER:
      return action.payload;
    case AREAS_ACTIONS.CLEAR_AREAS_FILTER:
      return [];
    default:
      return state;
  }
}

@Injectable()
export class AreasService extends ItemService<Area> {
  private _areas: Observable<Array<Area>> = this.store?.select('areas');
  private _selectedArea: Observable<Area> = this.store?.select('selectedArea');
  private _filter: Observable<Array<Filter>> =
    this.store?.select('areasFilter');

  constructor() {
    super();
  }

  get items(): Observable<Array<Area>> {
    if (!this._areas) this._areas = this.store?.select('areas');
    return this._areas;
  }

  get selectedItem(): Observable<Area> {
    if (!this._selectedArea)
      this._selectedArea = this.store?.select('selectedArea');
    return this._selectedArea;
  }

  get filter(): Observable<Array<Filter>> {
    if (!this._filter) this._filter = this.store?.select('areasFilter');
    return this._filter;
  }

  get baseUrl(): string {
    return this._baseUrl + '/api/areas';
  }

  protected override init() {
    super.init();

    this.wsService
      .asObservable()
      ?.pipe(
        map((d) => {
          return JSON.parse(d.data, dateReviver);
        }),
        filter((d) => {
          return !!d.area && !!d.action;
        })
      )
      .subscribe((d) => {
        console.log('Area: ', d.area);
        switch (d.action) {
          case 'CREATED':
            this.store.dispatch({
              type: AREAS_ACTIONS.CREATE_AREA,
              payload: d.area,
            });
            break;
          case 'UPDATED':
            this.store.dispatch({
              type: AREAS_ACTIONS.UPDATE_AREA,
              payload: d.area,
            });
            break;
          case 'DELETED':
            this.store.dispatch({
              type: AREAS_ACTIONS.DELETE_AREA,
              payload: d.area,
            });
            break;
        }
      });
  }

  public loadItems(): void {
    this.clearErrors();

    super
      ._getItems()
      .pipe(
        map((as) => this.sanitizeItems(as)),
        map((as) => ({ type: AREAS_ACTIONS.SET_AREAS, payload: as }))
      )
      .subscribe((action) => this.store.dispatch(action));
  }

  public deleteItem(area: Area): void {
    this.clearErrors();
    super
      ._deleteItem(area.id)
      .pipe(map(() => ({ type: AREAS_ACTIONS.DELETE_AREA, payload: area })))
      .subscribe((action) => this.store.dispatch(action));
  }

  public selectItem(area: Area): void {
    this.clearErrors();
    this.store.dispatch({ type: AREAS_ACTIONS.SELECT_AREA, payload: area });
  }

  public setFilter(filters: any) {
    this.store.dispatch({
      type: AREAS_ACTIONS.SET_AREAS_FILTER,
      payload: filters,
    });
  }

  protected createItem(area: Area): Observable<Area> {
    return super
      ._addItem(area)
      .pipe(
        tap((a) =>
          this.store.dispatch({ type: AREAS_ACTIONS.CREATE_AREA, payload: a })
        )
      );
  }

  protected updateItem(area: Area): Observable<Area> {
    return super
      ._updateItem(area)
      .pipe(
        tap((a) =>
          this.store.dispatch({ type: AREAS_ACTIONS.UPDATE_AREA, payload: a })
        )
      );
  }
}
