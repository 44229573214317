
import { Observable, BehaviorSubject, combineLatest } from 'rxjs';
import { takeUntil, map, tap } from 'rxjs/operators';
import { Component, OnChanges, Inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { tokenHasTfCompanyId } from '../services/jwt.service';

import { Admin, CompanyRole, Role } from '../model/admin';
import { BaseEntityFormComponent } from '../entity-component/base-entity-form.component';
import { AdminsService, TfCompaniesService, ServiceLocator, TayaracCompany } from '../services';

import { TfCompany } from '../model';


@Component( {
    selector: 'admin-form',
    templateUrl: './admin-form.component.html',
    styleUrls: ['./admin-form.component.scss']
} )
export class AdminFormComponent extends BaseEntityFormComponent<Admin> implements OnChanges {

    protected _roles = new BehaviorSubject<{ 'label': string, 'value': number }[]>( [] );
    protected _rolesChange = new BehaviorSubject<any>( [] );

    protected tfCompaniesService: TfCompaniesService;
    protected tfCompanies: TfCompany[] = [];
    protected showCompany: boolean = false;

    constructor( protected adminService: AdminsService, @Inject( TayaracCompany ) private tyCompany: string ) {
        super( adminService );

        this.tfCompaniesService = ServiceLocator.injector.get( TfCompaniesService );

        adminService.getRoles().pipe( takeUntil( this.ngUnsubscribe ) ).subscribe( roles => {
            let formRoles = roles.map( role => {
                return { label: role.name|| '', value: role.id };
            } );
            this._roles.next( formRoles );
        } );

        this.tfCompaniesService.items.pipe(
            takeUntil( this.ngUnsubscribe ) )
            .pipe(
                map( es => {
                    let tfcId = tokenHasTfCompanyId();
                    if ( 0 == tfcId )
                        return es;

                    let newES = es.filter( tfc => {
                        return tfc.id == tfcId;
                    } );

                    return newES;
                } ),
                takeUntil( this.ngUnsubscribe )
            )
            .subscribe( es => {
                setTimeout( () => this.tfCompanies = es, 0 );
                if ( !es || 0 == es.length ) {
                    setTimeout( () => {
                        this.tfCompaniesService.loadItems();
                    }, 0 );
                } else {
                    //                    this.form.controls['tfCompany'].patchValue(  es[0] );
                }
            } );
    }

    protected getEntityName(): string {
        return 'Admin';
    }

    protected get roles$(): Observable<{ 'label': string, 'value': number }[]> {
        return this._roles.asObservable();
    }

    protected get rolesChange(): Observable<any> {
        return this._rolesChange.asObservable();
    }

    protected createForm(): FormGroup {
        let form = this.fb.group( {
            firstname: ['', [Validators.required]],
            lastname: ['', [Validators.required]],
            password1: '*****',
            password2: '*****',
            phone: '',
            email: ['', [Validators.required]],
            roles: [[], [Validators.required]],
            tfCompanyId: ''
        } );

        form.controls.roles.valueChanges.pipe(
            takeUntil( this.ngUnsubscribe )
        ).subscribe( v => this._rolesChange.next( v ) );
        return form;
    }

    public override ngOnInit() {
        super.ngOnInit();

        combineLatest( [this.rolesChange, this.roles$]).pipe(
            map( ([vs, rs]) => {
              return { 'values': vs, 'roles': rs };
            } ),
            tap( v => {
                console.log( "Roles change:", v.values );
                let showCompany = false;
                let taxiABR = v.roles.filter( r => r.label === 'Taxi ABR' );
                if ( taxiABR.length > 0 ) {
                    let taxiAbrId = taxiABR[0].value;
                    v.values.forEach( (r: any) => {
                        if ( r === taxiAbrId ) {
                            showCompany = true;
                        }
                    } )
                }
                this.showCompany = showCompany;
                if ( !this.showCompany ) {
                    this.form.controls.tfCompanyId.setValue( null );
                    this.form.controls.tfCompanyId.clearValidators();
                    this.form.controls.tfCompanyId.updateValueAndValidity()
                } else {
                    this.form.controls.tfCompanyId.setValue( this.selectedItem?.tfCompanyId );
                }
            } ),
            takeUntil( this.ngUnsubscribe )
        ).subscribe();
    }

    override ngOnChanges() {
        super.ngOnChanges();
        this.form.controls['roles'].setValue( this.adminRoles );
    }

    public get isValid(): boolean {
        return this.form.valid && this.form.value.password1 === this.form.value.password2
            && ( ( !!this.selectedItem?.id && 0 !== this.selectedItem?.id ) || !!this.form.value.password1 );
    }

    get adminRoles(): number[] {

        if ( !this.selectedItem || !this.selectedItem.usercompanyrole ) {
            return [];
        }

        let r = this.selectedItem.usercompanyrole
            .filter( ucr => ucr.company?.name === this.tyCompany )
            .map( ucr => ucr.role?.id ).reduce( ( a: any[], id: any ) => {
                if ( -1 === a.indexOf( id ) ) {
                    a.push( id );
                }
                return a;
            }, [] );

        return r;
    }

    public override save( item: Admin ) {
        console.log( 'Form value: ', this.form.value );
        let x = Object.assign( new Admin(), item, this.form.value );
        x = this.setRoles( x );
        x = this.setPassword( x );
        if ( !x ) {
            return;
        }

        console.log( 'Object value: ', x );
        // this.debug = x;
        super.save( x );
    }

    private setRoles( x: any ): Admin {

        x.usercompanyrole = x.roles.map( (rid: any) => new CompanyRole( undefined, new Role( rid ) ) ).reduce( ( a: any, cr: any  ) => [...a, cr], [] );
        delete x['roles'];
        return x;
    }

    private setPassword( x: any ): Admin | boolean {

        if ( x.password1 !== x.password2 ) {
            this.form.controls['password2'].setErrors( ['Passwords do not match!'] );
            return false;
        }

        x.password = x.password1;
        if ( !x.id && !x.password ) {
            this.form.controls['password1'].setErrors( ['Passwords is required!'] );
            return false;
        }

        delete x['password1'];
        delete x['password2'];
        return x;
    }
}
