import { Keyholder } from './keyholder';

export class Vehicle extends Keyholder {
    licensePlate?: string;
    numberOfSeats?: number;
    phone?: string;
    vehicleBreak?: boolean;
    prmEquipment?: boolean;
    minivan?: boolean;
    twalter?: boolean;
    unitax?: boolean;
    autotax?: boolean;
}
