import { Component, OnChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { KeysService } from '../services';
import { Key, Validity, Token } from '../model';
import { EntityFormComponent } from '../entity-component/entity-form.component';
import { ServiceLocator } from '../services/service.locator';

@Component( {
    selector: 'key-form',
    templateUrl: './key-form.component.html',
    styleUrls: ['./key-form.component.scss']
})
export class KeyFormComponent extends EntityFormComponent<Key> implements OnChanges {

    protected override fb: FormBuilder;
    public override form: FormGroup;
    public override debug: any;

    constructor( itemService: KeysService ) {
        super( itemService );

        this.fb = ServiceLocator.injector.get( FormBuilder );

        this.form = this.createForm();
    };

    protected getEntityName(): string {
        return 'Key';
    };

    protected createForm(): FormGroup {
        return this.fb.group( {
            serialnr: ['', [Validators.required]],
            name: ['', [Validators.required]],
            description: '',
            carBooster: false,
            keyRFID: ['', [Validators.required]]
        });
    }

    override ngOnChanges() {
        this.form.patchValue( this.selectedItem || {} );
        this.form.controls['carBooster'].setValue( this.getProperty( 'carBooster' ) );
    }

    get isValid() { return this.form.valid; }

    public override save( item: Key ) {
        console.log( "Form value: ", this.form.value );
        let x = Object.assign( new Key(), item, this.form.value );
        x = this.setRFID( x );

        x.properties = Object.assign( {}, x.properties, { 'carBooster': x.carBooster });

        delete x['carBooster'];

        console.log( "Object value: ", x );
        // this.debug = x;
        super.save( x );
    }

    public get keyRFID(): string {
        let l = this.selectedItem?.keyRFID;
        return l || "";
    }

    protected setRFID( x: any ) {

        let rfid = x['keyRFID'];
        if ( rfid ) {
            if ( this.keyRFID != rfid ) {
                let token: Token = new Token();
                token.identity = rfid;
                x.tokens = [token];
            }
        }

        delete x['keyRFID'];
        return x;
    }
}
