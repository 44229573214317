import {
  startWith,
  scan,
  map,
  timeout,
  filter,
  tap,
  retryWhen,
  repeatWhen,
  delay,
  timeoutWith,
} from 'rxjs/operators';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  InjectionToken,
} from '@angular/core';
import { ServiceLocator } from '../services/service.locator';

import { Observable, Subject, of as observableOf, EMPTY } from 'rxjs';

import {
  WebSocketService,
  wsTimeout,
} from '../services/web-socket-service.service';
import { dateReviver } from '../utils/utils';
import { tokenHasTfCompanyId, tokenHasAnyRole } from '../services/jwt.service';
import { AuthenticationService } from '../services';

export let wsReportURL = new InjectionToken<string>('wsReportURL');

@Component({
  selector: 'app-transitions',
  templateUrl: './transitions.component.html',
  styleUrls: ['./transitions.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TransitionsComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  protected wsReport: WebSocketService;
  protected wsReportUrl: string;
  protected wsTimeout: number = 5000;

  public alerts: Observable<any[]> = EMPTY;
  public loading = false;
  private hasFilterDate = false;

  constructor() {
    this.wsReportUrl = ServiceLocator.injector.get(wsReportURL);
    this.wsTimeout = ServiceLocator.injector.get(wsTimeout);

    let authSvc = ServiceLocator.injector.get(AuthenticationService);
    this.wsReport = new WebSocketService(authSvc);
  }

  ngOnInit() {
    let wsd = this.wsReport.connect(this.wsReportUrl).pipe(
      map((d) => {
        let c = JSON.parse(d.data, dateReviver);
        return c;
      }),
      // Add ping check
      timeout(this.wsTimeout),
      filter((c) => {
        // Filter empty or pings
        return !!c && !('ping' in c);
      }),
      tap(
        (d) => {},
        (e) => {
          console.log('Error from ws: ', e);
        },
        () => {}
      ),
      retryWhen((failures) => {
        return failures.pipe(delay(5000));
      }),
      repeatWhen((failures) => {
        return failures.pipe(delay(5000));
      })
    );

    this.alerts = wsd.pipe(
      filter((c) => !!c.transition),
      map((c) => {
        if (!!c.clear) {
          return c;
        }

        let tr = c.transition;
        if (Array.isArray(tr)) {
          let trAr = tr.map((atr) => {
            return {
              time: atr.grantEvent.timestamp,
              car: atr.car.licensePlate,
              driver: atr.driver
                ? atr.driver.firstName + ' ' + atr.driver.lastName
                : '',
              from: atr.prevState,
              to: atr.nextState,
              granted: atr.grantEvent.granted,
              entry: atr.grantEvent.entry,
              msg: atr.grantEvent.message,
            };
          });

          return {
            transition: trAr,
            clear: false,
          };
        }
        return {
          transition: [
            {
              time: tr.grantEvent.timestamp,
              car: tr.car.licensePlate,
              driver: tr.driver
                ? tr.driver.firstName + ' ' + tr.driver.lastName
                : '',
              from: tr.prevState,
              to: tr.nextState,
              granted: tr.grantEvent.granted,
              entry: tr.grantEvent.entry,
              msg: tr.grantEvent.message,
            },
          ],
          clear: false,
        };
      }),
      scan((a: any[], v: any) => {
        if (!!v.clear) return [];

        let res = v.transition.concat(a);
        if (!this.hasFilterDate) {
          res = res.slice(0, 300);
        }
        return res;
      }, []),
      //            timeoutWith( 1000000, observableOf([]) ),
      tap((_) => {
        this.loading = false;
      }),
      startWith([]),
      map((trAr) => {
        return trAr.sort((l, r) => {
          if (l.time === r.time) return 0;
          return l.time > r.time ? -1 : 1;
        });
      })
    );
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get showDate(): boolean {
    return tokenHasAnyRole(['Administrators']);
  }

  public sendCmd(searchForm: any) {
    this.loading = true;
    this.hasFilterDate = !!searchForm[1].value;
    this.wsReport.send({
      action: 'FOLLOW_CAR',
      payload: {
        licensePlate: !!searchForm[0].value ? searchForm[0].value : '',
        filterDate: !!searchForm[1].value ? searchForm[1].value : '',
        tfCompanyId: tokenHasTfCompanyId(),
      },
    });
  }
}
