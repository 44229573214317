import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { ModalModule } from 'ngx-bootstrap/modal';
// import { DeviceDetectorModule } from 'ngx-device-detector';
import { CustomFormsModule } from 'ng2-validation';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { StoreModule } from '@ngrx/store';
import {
  accessrules,
  accessrulesFilter,
  AccessRulesService,
  admins,
  adminsFilter,
  AdminsService,
  areas,
  areasFilter,
  AreasService,
  AuthConfig,
  AuthenticationService,
  AuthGuard,
  AuthModule,
  baseURL,
  entries,
  entriesFilter,
  EntriesService,
  keys,
  keysFilter,
  KeysService,
  locks,
  locksFilter,
  LocksService,
  persons,
  personsFilter,
  PersonsService,
  selectedAccessRule,
  selectedAdmin,
  selectedArea,
  selectedEntry,
  selectedKey,
  selectedLock,
  selectedPerson,
  selectedTfCompany,
  selectedVehicle,
  ServiceLocator,
  TayaracCompany,
  tfCompanies,
  tfCompaniesFilter,
  TfCompaniesService,
  UsersService,
  vehicles,
  vehiclesFilter,
  VehiclesService,
  wsBaseURL,
  wsTimeout,
} from './services';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  AutofocusDirective,
  ConfirmationService,
  MaterialModule,
  PrimengModule,
  TsChartComponent,
} from './utils';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  BrigadierComponent,
  DashboardComponent,
  DisplayAreaComponent,
  OverviewComponent,
  TestboardComponent,
  wsDebugURL,
} from './dashboard';
import { TransitionsComponent, wsReportURL } from './transitions';
import {
  AdminFormComponent,
  AdminsComponent,
  AdminsListComponent,
} from './admins/admins.component';
import { ApocComponent } from './apoc/apoc.component';
import {
  AreaFormComponent,
  AreasComponent,
  AreasListComponent,
} from './areas/areas.component';
import { DiagnosticComponent } from './diagnostic/diagnostic.component';
import {
  KeyFormComponent,
  KeysComponent,
  KeysListComponent,
} from './keys/keys.component';
import {
  LockFormComponent,
  LocksComponent,
  LocksListComponent,
} from './locks/locks.component';
import { MobilePodiumComponent } from './mobile-podium/mobile-podium.component';
import { MobileTransitionsComponent } from './mobile-transitions/mobile-transitions.component';
import {
  PersonFormComponent,
  PersonsComponent,
  PersonsListComponent,
} from './persons/persons.component';
import { SettingsComponent } from './settings/settings.component';
import {
  TfCompaniesComponent,
  TfCompaniesListComponent,
  TfCompanyFormComponent,
} from './tfcompanies/tfcompanies.component';
import {
  VehicleFormComponent,
  VehiclesComponent,
  VehiclesListComponent,
} from './vehicles/vehicles.component';
import { DataFilterPipe } from './pipes';
import { LoginComponent } from './login/login.component';
import { ForgottenPasswordComponent } from './login/forgotten-password.component';
import { ResetPasswordComponent } from './login/reset-password.component';
import { NodeEntryComponent } from './areas/node-entry.component';
import { NodeAddressComponent } from './entity-component/node-address.component';
import { NodeBlacklistingComponent } from './entity-component/node-blacklisting.component';
import { NodeUniqueNameComponent } from './entity-component/node-unique-name.component';
import { NodeValidityComponent } from './entity-component/node-validity.component';
import { SearchFormComponent } from './search-form/search-form.component';
import { TableModule } from 'ngx-easy-table';
import {
  dashboardOnline,
  errorMsg,
  brigadierOnline,
  StoreAction,
  TayaracStore,
  apocOnline,
  diagnosticOnline,
} from './store/tayarac-store';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalRedirectComponent,
  MsalService,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
} from '@azure/msal-angular';
import {
  PublicClientApplication,
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
  IPublicClientApplication,
} from '@azure/msal-browser';
import { NgChartsModule } from 'ng2-charts';
import { environment } from 'src/environments/environment';

let SERVER_IP = window.location.hostname;
let HTTP_PROT = window.location.protocol;
let HTTP_PORT = window.location.port || 80;
let COMPANY = 'Oktima';

let PORT_VALUE = HTTP_PORT === 443 || HTTP_PORT === 80 ? '' : ':' + HTTP_PORT;
let HOST_VALUE = SERVER_IP + PORT_VALUE;

let DASHBOARD_URL =
  HTTP_PORT !== "4200"
    ? HTTP_PROT + '//' + HOST_VALUE + '/taxiflow/dashboard'
    : HTTP_PROT + '//' + HOST_VALUE + '/dashboard';

let authConfig = new AuthConfig();

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: environment.authority,
      // clientId: 'c71ea99f-c91b-4614-b5d7-e9cf9fb7b8f7', // Constalant
      // authority: 'https://login.microsoftonline.com/71e985ea-429d-4a5f-af1f-bb089f913783',
      // clientId: 'dd0bf203-ec68-4e12-9562-57497ac6bd2e', // BAC Stag
      // clientId: 'b83ce2e4-ef9b-4d21-8eed-2c40e8809059',  // BAC Prod
      // authority: 'https://login.microsoftonline.com/1dbedab5-849a-4c83-9c43-46581df437d8',
      // redirectUri: environment.postLoginURL,
      postLogoutRedirectUri: '/',
      navigateToLoginRequestUrl: true,
      //redirectUri: 'https://taxiflow-uat.brusselsairport.be/taxiflow/dashboard'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
      secureCookies: true
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('http://' + SERVER_IP + ':8008/trs', [environment.scope]);
  protectedResourceMap.set( HTTP_PROT + '//' + SERVER_IP + '/taxiflow/trs', [environment.scope]);
  // protectedResourceMap.set('http://' + SERVER_IP + ':8008/trs', ['api://taxiflow-api/Taxiflow.Acces']);
  // protectedResourceMap.set( HTTP_PROT + '//' + SERVER_IP + '/taxiflow/trs', ['api://taxiflow-api/Taxiflow.Acces']);
  // protectedResourceMap.set('http://' + SERVER_IP + ':8008/trs', ['api://taxiflow-api-prod/Taxiflow.Access']);
  // protectedResourceMap.set( HTTP_PROT + '//' + SERVER_IP + '/taxiflow/trs', ['api://taxiflow-api-prod/Taxiflow.Access']);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: [environment.scope]
      // scopes: ['api://taxiflow-api/Taxiflow.Acces'],
      // scopes: ['api://taxiflow-api-prod/Taxiflow.Access']
    },
    loginFailedRoute: '/dashboard',
  };
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StoreModule.forRoot({}, {}),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    // Include the routing module
    AppRoutingModule,
    HttpClientModule,
    ModalModule.forRoot(),
    CustomFormsModule,
    // DeviceDetectorModule.forRoot(),
    PrimengModule,
    TableModule,
    FontAwesomeModule,
    MsalModule,
    NgChartsModule,
  ],
  declarations: [
    AppComponent,
    DashboardComponent,
    OverviewComponent,
    TestboardComponent,
    TransitionsComponent,
    BrigadierComponent,
    ApocComponent,
    DiagnosticComponent,
    LocksComponent,
    KeysComponent,
    TfCompaniesComponent,
    MobilePodiumComponent,
    PersonsComponent,
    VehiclesComponent,
    AreasComponent,
    SettingsComponent,
    MobileTransitionsComponent,
    AdminsComponent,
    DataFilterPipe,
    LoginComponent,
    ForgottenPasswordComponent,
    ResetPasswordComponent,
    DashboardComponent,
    OverviewComponent,
    TestboardComponent,
    TransitionsComponent,
    MobileTransitionsComponent,
    BrigadierComponent,
    DisplayAreaComponent,
    ApocComponent,
    DiagnosticComponent,
    LocksComponent,
    LocksListComponent,
    LockFormComponent,
    AreasComponent,
    AreasListComponent,
    AreaFormComponent,
    KeysComponent,
    KeysListComponent,
    KeyFormComponent,
    PersonsComponent,
    PersonsListComponent,
    PersonFormComponent,
    VehiclesComponent,
    VehiclesListComponent,
    VehicleFormComponent,
    TfCompaniesComponent,
    TfCompaniesListComponent,
    TfCompanyFormComponent,
    AdminsComponent,
    AdminFormComponent,
    AdminsListComponent,
    SettingsComponent,
    SearchFormComponent,
    NodeValidityComponent,
    NodeAddressComponent,
    NodeBlacklistingComponent,
    NodeUniqueNameComponent,
    NodeEntryComponent,
    AutofocusDirective,
    MobilePodiumComponent,
    TsChartComponent,
  ],
  providers: [
    {
      provide: baseURL,
      useValue:
        HTTP_PORT !== "4200"
          ? HTTP_PROT + '//' + SERVER_IP + '/taxiflow/trs'
          : 'http://' + SERVER_IP + ':8008/trs',
    },
    {
      provide: wsBaseURL,
      useValue:
        HTTP_PORT !== "4200"
          ? HTTP_PROT + '//' + SERVER_IP + '/taxiflow/ws1'
          : 'http://' + SERVER_IP + ':8081/ws',
    },
    {
      provide: wsDebugURL,
      useValue:
        HTTP_PORT !== "4200"
          ? HTTP_PROT + '//' + SERVER_IP + '/taxiflow/ws2'
          : 'http://' + SERVER_IP + ':8082/ws',
    },
    {
      provide: wsReportURL,
      useValue:
        HTTP_PORT !== "4200"
          ? HTTP_PROT + '//' + SERVER_IP + '/taxiflow/ws3'
          : 'http://' + SERVER_IP + ':8083/ws',
    },
    { provide: wsTimeout, useValue: 5000 },
    { provide: TayaracCompany, useValue: COMPANY },
    // AuthModule.forRoot( authConfig ).providers || [],
    AuthGuard,
    AuthenticationService,
    // {provide:WebSocketService, useValue: new WebSocketService()},
    LocksService,
    KeysService,
    PersonsService,
    VehiclesService,
    ConfirmationService,
    AreasService,
    EntriesService,
    TfCompaniesService,
    AdminsService,
    UsersService,
    AccessRulesService,
    StoreModule.forRoot<TayaracStore, StoreAction>(
      {
        keys,
        selectedKey,
        keysFilter,
        locks,
        selectedLock,
        locksFilter,
        admins,
        selectedAdmin,
        adminsFilter,
        persons,
        selectedPerson,
        personsFilter,
        vehicles,
        selectedVehicle,
        vehiclesFilter,
        tfCompanies,
        selectedTfCompany,
        tfCompaniesFilter,
        areas,
        selectedArea,
        areasFilter,
        entries,
        selectedEntry,
        entriesFilter,
        accessrules,
        selectedAccessRule,
        accessrulesFilter,
        errorMsg,
        dashboardOnline,
        brigadierOnline,
        apocOnline,
        diagnosticOnline,
      },
      {
        // https://stackoverflow.com/questions/60631071/cannot-add-property-x-object-is-not-extensible-angular-9
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      }
    ).providers || [],
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {
  constructor(private injector: Injector) {
    ServiceLocator.injector = this.injector;
  }
}
