<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd>
   <p-footer>
        <button type="button" pButton icon="fa-check" label="Yes" (click)="cd.accept()"></button>
        <button type="button" pButton icon="fa-close" label="Cancel" (click)="cd.reject()"></button>
    </p-footer>
</p-confirmDialog>

<search-form #searchForm (search)="setFilter([
    {'field':'number', 'value':number.value},
    {'field':'name', 'value':name.value}
    ])" (reset)="name.value=''; number.value=''; setFilter([]); searchForm.hide()">
    <mat-form-field class="t-col-6">
        <input matInput #number placeholder="Filter by number" [autofocus]="true" class="full-width" (keyup.enter)="searchForm.search.emit()"  />
    </mat-form-field>
    <mat-form-field class="t-col-6">
        <input matInput #name placeholder="Filter by name" class="full-width" (keyup.enter)="searchForm.search.emit()"  />
    </mat-form-field>
</search-form>

<tfcompanies-list [items]="(items | async) || []" *ngIf="!editing" (selected)="itemSelected($event)" (added)="newItem()" (search)="searchForm.show(); setFocus(number)"
(deleted)="deleteItem($event)" [(sortSettings)]="sortSettings" [(paging)]="paging"></tfcompanies-list>
<tfcompany-form [item]="(selectedItem | async) || undefined" *ngIf="!!editing" [errorMsgs]="(errorMsg | async) || []" (canceled)="editCancel()" (saved)="saveItem($event)"></tfcompany-form>
