import { retry, tap } from 'rxjs/operators';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthenticationService, AuthGuard, UsersService } from '../services';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  model: any = {};
  loading = false;
  error = '';

  deviceInfo?: DeviceInfo;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
    private authGuard: AuthGuard,
    private deviceService: DeviceDetectorService
  ) {}

  ngOnInit() {
    // reset login status
    //this.authenticationService.logout();

    // setTimeout(() => {
    //   this.login();
    // }, 1);
  }

  login() {
    this.loading = true;
    this.authenticationService
      .login(this.model.username || '', this.model.password || '')
      .pipe(
        retry({
          delay: (errors)=>{
            return errors.pipe(tap(() => console.log('Login retrying...')));
          }
        }),
        finalize(() => {
          this.loading = false;
        })
      )
      .subscribe((result: any) => {
        console.log('hello `Home` component');
        this.deviceInfo = this.deviceService.getDeviceInfo();
        console.log(this.deviceInfo);

        if (result === true) {
          if (this.isMobile()) {
            this.router.navigateByUrl('/dashboard');
          }
          // login successful
          for (let config of this.router.config.slice(2)) {
            if (
              this.authGuard.urlIsAllowed('/' + config?.path) &&
              config?.path
            ) {
              this.router.navigateByUrl(config.path);
              return;
            }
          }
          this.router.navigate(['/']);
        } else {
          // login failed
          this.error =
            typeof result === 'string'
              ? result
              : 'Username or password is incorrect';
        }
      });
  }

  isMobile(): boolean {
    return this.authGuard.isMobile();
  }
}
