<form *ngIf="selectedItem" [formGroup]="form" (ngSubmit)="save(selectedItem)" >
    <p-panel>
      <p-header>
        <h2 *ngIf="selectedItem.id">Editing '{{originalName}}'</h2>
        <h2 *ngIf="!selectedItem.id">Create New {{entityName}}</h2>
      </p-header>

      <div class="t-grid t-flex-row t-flex-wrap">
          <!-- Item specifics start here -->
          <p-panel lass="t-col-9 t-flex-auto">
              <p-header>Company info</p-header>
            <div class="t-col-12 t-flex-auto t-grid t-flex-row t-flex-wrap">
              <div class="t-col-4 t-flex-auto">
                  <mat-form-field class="full-width"  [color]="!form.controls.number.valid ? 'warn' : 'primary'">
                    <mat-label>Number</mat-label>
                    <input matInput class="full-width" type="text" id="number" formControlName="number" required/>
                    <mat-hint align="start" [class.alert-danger]="!form.controls.number.valid" *ngIf="!form.controls.number.valid">Company number must not be empty</mat-hint>
                  </mat-form-field>
              </div>
              <div class="t-col-8 t-flex-auto">
                  <mat-form-field class="full-width" [color]="!form.controls.name.valid ? 'warn' : 'primary'">
                    <mat-label>Company name</mat-label>
                          <input matInput class="full-width" type="text" id="name" formControlName="name" required />
                          <mat-hint align="start" [class.alert-danger]="!form.controls.name.valid" *ngIf="!form.controls.name.valid">Company name must not be empty</mat-hint>
                  </mat-form-field>
              </div>
              <div class="t-col-4 t-flex-auto">
                  <mat-form-field class="full-width">
                    <mat-label>Roepnaam</mat-label>
                    <input matInput type="text" id="description" formControlName="description" />
                  </mat-form-field>
              </div>
                <div class="t-col-8 t-flex-auto">
                    <mat-form-field class="full-width">
                      <mat-label>Email address</mat-label>
                          <input matInput type="text" id="email" formControlName="email" />
                    </mat-form-field>
                </div>
            </div>
          </p-panel>

          <p-panel class="t-col-3 t-flex-auto">
              <p-header>Blacklist</p-header>
              <node-blacklisting class="full-width" [form]="$any(form.controls.blacklisting)"></node-blacklisting>
          </p-panel>
        <!-- Item specifics end here -->
      </div>

      <ul *ngIf="errorMsgs?.length" class="alert alert-danger">
        <li *ngFor="let err of errorMsgs">{{err}}</li>
      </ul>

      <p-footer>
        <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Submit</button>
        <button mat-raised-button (click)="cancel(selectedItem)" type="button">Cancel</button>
      </p-footer>
    </p-panel>
</form>

<div *ngIf="!selectedItem">{{entityName}} does not exists</div>

