import { EMPTY, merge as observableMerge, Observable, Subject } from 'rxjs';

import {
  scan,
  filter,
  delay,
  repeatWhen,
  retryWhen,
  map,
} from 'rxjs/operators';
import {
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  InjectionToken,
} from '@angular/core';
import { ServiceLocator } from '../services/service.locator';

import { WebSocketService } from '../services/web-socket-service.service';
import { dateReviver } from '../utils/utils';

import { Car, Airport } from './model.boards';
import { tokenHasAnyRole } from '../services/jwt.service';
import { ConfirmationService } from '../utils';
import { AuthenticationService } from '../services';

export let wsDebugURL = new InjectionToken<string>('wsDebugURL');

@Component({
  selector: 'app-testboard',
  templateUrl: './testboard.component.html',
  styleUrls: ['./testboard.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TestboardComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  protected wsDebug: WebSocketService;
  protected wsDebugUrl: string;

  public ap: Observable<Airport> = EMPTY;
  public cars: Observable<any[]> = EMPTY;

  public alerts: Observable<any> = EMPTY;

  public t2InUse: Observable<String> = EMPTY;
  public t1InUse: Observable<String> = EMPTY;

  public airportDown: Observable<boolean> = EMPTY;
  public recoveryMode: Observable<boolean> = EMPTY;

  public selectedCar?: Car;

  protected confirmationService: ConfirmationService;

  constructor(private authSvc: AuthenticationService) {
    this.wsDebug = new WebSocketService(authSvc);
    this.wsDebugUrl = ServiceLocator.injector.get(wsDebugURL);
    this.confirmationService = ServiceLocator.injector.get(ConfirmationService);
  }

  ngOnInit() {
    let wsd = this.wsDebug.connect(this.wsDebugUrl).pipe(
      map((d) => {
        let c = JSON.parse(d.data, dateReviver);
        return c;
      }),
      retryWhen((failures) => {
        return failures.pipe(delay(5000));
      }),
      repeatWhen((failures) => {
        return failures.pipe(delay(5000));
      })
    );

    this.ap = wsd.pipe(
      filter((c) => !!c.airport),
      map((c) => c.airport)
    );
    this.cars = this.ap.pipe(
      filter((ap) => !!ap.cars),
      map((a) => {
        return a.cars;
      }),
      map((cars) =>
        cars.sort((a, b) => {
          return a.licensePlate.localeCompare(b.licensePlate);
        })
      ),
      map((cars) =>
        cars.map((car) => {
          return { label: car.licensePlate, value: car };
        })
      )
    );

    let obsWsAlerts = wsd.pipe(
      filter((c) => !!c.alert),
      map((c) => c.alert)
    );

    this.alerts = observableMerge(obsWsAlerts).pipe(
      scan((ar, al) => [al, ...ar].slice(0, 300), [] as any[])
    );

    this.t2InUse = this.ap.pipe(
      filter((ap) => !!ap.areas),
      map((ap) => {
        let T2 = ap.areas.filter((a) => a.name === 'T2');
        return T2[0];
      }),
      map((a) => {
        if (!a) return '';

        if (a.manualInUse) {
          return a.inUse ? 'MI' : 'MO';
        } else {
          return 'A';
        }
      })
    );

    this.t1InUse = this.ap.pipe(
      filter((ap) => !!ap.areas),
      map((ap) => {
        let T1 = ap.areas.filter((a) => a.name === 'T1');
        return T1[0];
      }),
      map((a) => {
        if (!a) return '';

        if (a.manualInUse) {
          return a.inUse ? 'MI' : 'MO';
        } else {
          return 'A';
        }
      })
    );

    this.airportDown = this.ap.pipe(map((ap) => ap.systemDown));

    this.recoveryMode = this.ap.pipe(map((ap) => ap.recoveryMode));
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public emulateGoc(device: string, entry: string) {
    if (!!this.selectedCar) {
      let rfID = this.selectedCar.rfID;
      if (!!rfID) {
        this.wsDebug.send({
          action: 'gocEvent',
          payload: {
            device: device,
            entry: entry,
            car: rfID,
          },
        });
      }
    }
  }

  public sendCmd(command: string, toArea: string, fromArea?: string) {
    if (!!this.selectedCar) {
      let rfID = this.selectedCar.rfID;
      if (!!rfID) {
        this.wsDebug.send({
          action: command,
          payload: {
            fromArea: fromArea,
            toArea: toArea,
            car: rfID,
          },
        });
      }
    }
  }

  public sendInUseCmd(command: string, area: string, state: string) {
    this.wsDebug.send({
      action: command,
      payload: {
        area: area,
        state: state,
      },
    });
  }

  public sendResetCmd() {
    this.wsDebug.send({
      action: 'resetAirportCmd',
      payload: {},
    });
  }

  public systemDown(down: boolean, express: boolean) {
    if (!!down) {
      this.confirmationService.confirm({
        message:
          'Bringing the TMS down...<br>This will stop the automatic flow control!<br>Are you sure?',
        accept: () => {
          this.wsDebug.send({
            action: 'systemDown',
            payload: {
              down: down,
              express: express,
            },
          });
        },
      });
    } else {
      this.wsDebug.send({
        action: 'systemDown',
        payload: {
          down: down,
          express: express,
        },
      });
    }
  }

  protected getAccessRights(car: Car): string {
    return car.access ? car.access.join(', ') : '';
  }

  public isAdministrator(): boolean {
    return tokenHasAnyRole(['Administrators']);
  }
}
