import { Key, Lock, Person, Vehicle, TfCompany, Area, Entry, AccessRule, Admin } from '../model';
import { Action } from '@ngrx/store';

export interface Filter {
    field: string;
    operation: string;
    value?: any;
}

export class STORE_ACTIONS {
    static SET_ERRORS = 'SET_ERRORS';
    static ADD_ERROR = 'ADD_ERROR';
    static CLEAR_ERRORS = 'CLEAR_ERRORS';
    static DASHBOARD_ONLINE = 'DASHBOARD_ONLINE';
    static BRIGADIER_ONLINE = 'BRIGADIER_ONLINE';
    static APOC_ONLINE = 'APOC_ONLINE';
    static DIAGNOSTIC_ONLINE = 'DIAGNOSTIC_ONLINE';
}

export class StoreAction implements Action {
    public readonly type: string;
    public payload: any;

    public constructor( o: { type: string, payload?: any } ) {
        this.type = o.type;
        this.payload = o.payload;
    };
}

// The "errorMsg" reducer performs actions on our list of errors
export const errorMsg = errorMsgFn;
export function errorMsgFn( state: string[] = [], action: StoreAction ) {
    //    console.debug("Action:" , JSON.stringify(action));
    switch ( action.type ) {
        case STORE_ACTIONS.SET_ERRORS:
            return action.payload;
        case STORE_ACTIONS.ADD_ERROR:
            return [...state, action.payload];
        case STORE_ACTIONS.CLEAR_ERRORS:
            return [];
        default:
            return state;
    }
};

export const dashboardOnline = dashboardOnlineFn;
export function dashboardOnlineFn( state = false, action: StoreAction ) {
    //    console.debug("Action:" , JSON.stringify(action));
    switch ( action.type ) {
        case STORE_ACTIONS.DASHBOARD_ONLINE:
            return action.payload;
        default:
            return state;
    }
};

export const brigadierOnline = brigadierOnlineFn;
export function brigadierOnlineFn( state = false, action: StoreAction ) {
    //    console.debug("Action:" , JSON.stringify(action));
    switch ( action.type ) {
        case STORE_ACTIONS.BRIGADIER_ONLINE:
            return action.payload;
        default:
            return state;
    }
};

export const apocOnline = apocOnlineFn;
export function apocOnlineFn( state = false, action: StoreAction ) {
    switch ( action.type ) {
        case STORE_ACTIONS.APOC_ONLINE:
            return action.payload;
        default:
            return state;
    }
};

export const diagnosticOnline = diagnosticOnlineFn;
export function diagnosticOnlineFn( state = false, action: StoreAction ) {
    switch ( action.type ) {
        case STORE_ACTIONS.DIAGNOSTIC_ONLINE:
            return action.payload;
        default:
            return state;
    }
};

export interface TayaracStore {
    keys: Key[];
    selectedKey: Key;
    keysFilter: Filter[];

    locks: Lock[];
    selectedLock: Lock;
    locksFilter: Filter[];

    admins: Admin[];
    selectedAdmin: Admin;
    adminsFilter: Filter[];

    persons: Person[];
    selectedPerson: Person;
    personsFilter: Filter[];

    vehicles: Vehicle[];
    selectedVehicle: Vehicle;
    vehiclesFilter: Filter[];

    tfCompanies: TfCompany[];
    selectedTfCompany: TfCompany;
    tfCompaniesFilter: Filter[];

    areas: Area[];
    selectedArea: Area;
    areasFilter: Filter[];

    entries: Entry[];
    selectedEntry: Entry;
    entriesFilter: Filter[];

    accessrules: AccessRule[];
    selectedAccessRule: AccessRule;
    accessrulesFilter: Filter[];

    errorMsg: string[];

    dashboardOnline: boolean;
    brigadierOnline: boolean;
    apocOnline: boolean;
    diagnosticOnline: boolean;
}

