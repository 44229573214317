import { Component, ViewEncapsulation } from '@angular/core';
import { TransitionsComponent } from '../transitions/transitions.component';
import { tokenHasAnyRole } from '../services/jwt.service';

@Component( {
    selector: 'mobile-transitions',
    templateUrl: './mobile-transitions.component.html',
    styleUrls: ['./mobile-transitions.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class MobileTransitionsComponent extends TransitionsComponent {


    constructor() {
        super()
    }


    public override get showDate(): boolean {
        return tokenHasAnyRole(['Administrators']);
    }

}
