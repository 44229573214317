<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd>
  <p-footer>
    <button type="button" pButton icon="fa-check" label="Yes" (click)="cd.accept()"></button>
    <button type="button" pButton icon="fa-close" label="Cancel" (click)="cd.reject()"></button>
  </p-footer>
</p-confirmDialog>

<search-form #searchForm
	(search)="setFilter([
    {'field':'firstName', 'value':firstName.value},
    {'field':'lastName', 'value':lastName.value},
    {'field':'identity', 'value':identity.value},
    {'field':'keyRFID', 'value':rfid.value}
    ])"
	(reset)="firstName.value=''; lastName.value=''; identity.value= ''; rfid.value=''; setFilter([]); searchForm.hide()">
    <mat-form-field class="t-col-3">
        <input matInput #identity placeholder="Filter by Person ID" [autofocus]="true" class="full-width" />
    </mat-form-field>
    <mat-form-field class="t-col-3">
        <input matInput #firstName placeholder="Filter by First name" class="full-width" />
    </mat-form-field>
    <mat-form-field class="t-col-3">
        <input matInput #lastName placeholder="Filter by Last name" class="full-width" />
    </mat-form-field>
    <mat-form-field class="t-col-3">
        <input matInput #rfid placeholder="Filter by Badge" class="full-width" />
    </mat-form-field>
</search-form>

<persons-list [items]="(items | async) || []" *ngIf="!editing" (selected)="itemSelected($event)" (added)="newItem()"  (download)="downloadData()"
	(search)="searchForm.show()" (deleted)="deleteItem($event)"
	[(sortSettings)]="sortSettings" [(paging)]="paging"
	></persons-list>
<person-form [item]="(selectedItem | async) || undefined" *ngIf="!!editing" [errorMsgs]="(errorMsg | async) || []"
	(canceled)="editCancel()" (saved)="saveItem($event)"></person-form>
