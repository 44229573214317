<form *ngIf="selectedItem" (ngSubmit)="save(selectedItem)" [formGroup]="form">

    <div fxLayout="row" fxLayoutAlign="center">
        <div fxFlex="0 1 auto">

		    <p-panel>
		        <p-header>
		                <h2 *ngIf="selectedItem.id">Editing '{{originalName}}'</h2>
		                <h2 *ngIf="!selectedItem.id">Create New {{entityName}}</h2>
		        </p-header>

		        <div class="t-grid">
		            <!-- Item specifics start here -->
		            <div class="t-col-6 form-group">
                        <node-unique-name [item]="selectedItem" [service]="itemService" [input]="$any(form.controls.name)"></node-unique-name>
		            </div>
		            <div class="t-col-1 form-group">
		                <label for="capacity">Capacity:</label>
		                <p-spinner id="capacity" name="capacity" formControlName="capacity" [min]="0"></p-spinner>
		            </div>
		            <div class="t-col-5 form-group"></div>
		            <div class="t-col-12 form-group">
                         <mat-form-field class="full-width">
                            <input matInput placeholder="Description" type="text" id="description" name="description" formControlName="description"/>
                         </mat-form-field>
		            </div>

		            <p-panel class="t-col-12 form-group">
		                <p-header>
			                <div class="pull-left"><h4>Entries</h4></div>

			                <div class="pull-right">
			                    <button mat-icon-button color="primary" (click)="addEntry()" type="button">
			                       <mat-icon fontSet="fa" fontIcon="fa-plus" class="mat-24"></mat-icon>
			                    </button>
			                </div>
			                <div class="clearfix"></div>
			            </p-header>
			            <div formArrayName="entries">
			            <div *ngFor="let ent of $any(form.controls.entries).controls; let i=index" class="t-col-6 mat-card-wrapper">
				            <mat-card [formGroupName]="i">
				               <div class="full-width">
			                        <button mat-icon-button class="pull-right" color="accent" (click)="removeEntry(i)" type="button">
			                           <mat-icon fontSet="fa" fontIcon="fa-trash-can" class="mat-24"></mat-icon>
			                        </button>
			                        <div class="clearfix"></div>
			                    </div>
                                <node-entry  [entryForm]="ent" [locks]="locks" (lockSelected)="lockSelected($event)" (lockDeselected)="lockDeselected($event)"></node-entry>
			                </mat-card>
		                </div>
		                </div>
		                <div class="clearfix"></div>
		            </p-panel>

		            <ul class="t-col-12 form-group" *ngIf="errorMsgs?.length" class="alert alert-danger">
		              <li *ngFor="let err of errorMsgs">{{err}}</li>
		            </ul>
		            <!-- Item specifics end here -->
		        </div>

		        <p-footer>
                    <button mat-raised-button color="primary" type="submit" [disabled]="!isValid">Submit</button>
                    <button mat-raised-button (click)="cancel(selectedItem)" type="button">Cancel</button>
		        </p-footer>

		    </p-panel>
        </div>
    </div>
</form>

<div class="t-col-12" *ngIf="debug">
    <pre>{{ debug | json }}</pre>
</div>

<div *ngIf="!selectedItem">{{entityName}} does not exists</div>

