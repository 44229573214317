import { map, filter, tap } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, Action } from '@ngrx/store';
import { Filter, StoreAction } from '../store/tayarac-store';
import { clone } from '../utils/utils';

import { baseURL, ItemService } from './item.service';
import { Entry, Validity, JsonResponse } from '../model';

import { dateReviver } from '../utils/utils';

export class ENTRIES_ACTIONS {
  static SET_ENTRIES = 'SET_ENTRIES';
  static CREATE_ENTRY = 'CREATE_ENTRY';
  static UPDATE_ENTRY = 'UPDATE_ENTRY';
  static DELETE_ENTRY = 'DELETE_ENTRY';
  static SELECT_ENTRY = 'SELECT_ENTRY';
  static SET_ENTRIES_FILTER = 'SET_ENTRIES_FILTER';
  static CLEAR_ENTRIES_FILTER = 'CLEAR_ENTRIES_FILTER';
}

// The "entries" reducer performs actions on our list of entries
export const entries = entriesFn;
export function entriesFn(state: Entry[] = [], action: StoreAction) {
  switch (action.type) {
    case ENTRIES_ACTIONS.SET_ENTRIES:
      return action.payload;
    case ENTRIES_ACTIONS.CREATE_ENTRY:
      let x = state.filter((item) => item.id === action.payload.id).length;
      return x ? state : [...state, action.payload];
    case ENTRIES_ACTIONS.UPDATE_ENTRY:
      return state.map((item) => {
        let val = clone(action.payload);
        return item.id === action.payload.id ? val : item;
      });
    case ENTRIES_ACTIONS.DELETE_ENTRY:
      return state.filter((item) => {
        return item.id !== action.payload.id;
      });
    default:
      return state;
  }
}

// The "selectedEntry" reducer handles the currently selected entry
export const selectedEntry = selectedEntryFn;
export function selectedEntryFn(
  state: Entry | null = null,
  action: StoreAction
) {
  switch (action.type) {
    case ENTRIES_ACTIONS.SELECT_ENTRY:
      return action.payload;
    default:
      return state;
  }
}

export const entriesFilter = entriesFilterFn;
export function entriesFilterFn(state: Filter[] = [], action: StoreAction) {
  switch (action.type) {
    case ENTRIES_ACTIONS.SET_ENTRIES_FILTER:
      return action.payload;
    case ENTRIES_ACTIONS.CLEAR_ENTRIES_FILTER:
      return [];
    default:
      return state;
  }
}

@Injectable()
export class EntriesService extends ItemService<Entry> {
  private _entries: Observable<Array<Entry>> = this.store?.select('entries');
  private _selectedEntry: Observable<Entry> =
    this.store?.select('selectedEntry');
  private _filter: Observable<Array<Filter>> =
    this.store?.select('entriesFilter');

  constructor() {
    super();
  }

  get items(): Observable<Array<Entry>> {
    if (!this._entries) this._entries = this.store?.select('entries');
    return this._entries;
  }

  get selectedItem(): Observable<Entry> {
    if (!this._selectedEntry)
      this._selectedEntry = this.store?.select('selectedEntry');
    return this._selectedEntry;
  }

  get filter(): Observable<Array<Filter>> {
    if (!this._filter) this._filter = this.store?.select('entriesFilter');
    return this._filter;
  }

  get baseUrl(): string {
    return this._baseUrl + '/api/entries';
  }

  protected override init() {
    super.init();

    this.wsService
      .asObservable()
      ?.pipe(
        map((d) => {
          return JSON.parse(d.data, dateReviver);
        }),
        filter((d) => {
          return !!d.entry && !!d.action;
        })
      )
      .subscribe((d) => {
        console.log('Entry: ', d.entry);
        switch (d.action) {
          case 'CREATED':
            this.store.dispatch({
              type: ENTRIES_ACTIONS.CREATE_ENTRY,
              payload: d.entry,
            });
            break;
          case 'UPDATED':
            this.store.dispatch({
              type: ENTRIES_ACTIONS.UPDATE_ENTRY,
              payload: d.entry,
            });
            break;
          case 'DELETED':
            this.store.dispatch({
              type: ENTRIES_ACTIONS.DELETE_ENTRY,
              payload: d.entry,
            });
            break;
        }
      });
  }

  public loadItems(): void {
    this.clearErrors();

    super
      ._getItems()
      .pipe(
        map((es) => this.sanitizeItems(es)),
        map((es) => ({ type: ENTRIES_ACTIONS.SET_ENTRIES, payload: es }))
      )
      .subscribe((action) => this.store.dispatch(action));
  }

  public deleteItem(entry: Entry): void {
    this.clearErrors();
    super
      ._deleteItem(entry.id)
      .pipe(map(() => ({ type: ENTRIES_ACTIONS.DELETE_ENTRY, payload: entry })))
      .subscribe((action) => this.store.dispatch(action));
  }

  public selectItem(entry: Entry): void {
    this.clearErrors();
    this.store.dispatch({ type: ENTRIES_ACTIONS.SELECT_ENTRY, payload: entry });
  }

  public setFilter(filters: any) {
    this.store.dispatch({
      type: ENTRIES_ACTIONS.SET_ENTRIES_FILTER,
      payload: filters,
    });
  }

  protected createItem(entry: Entry): Observable<Entry> {
    return super._addItem(entry).pipe(
      tap((e) =>
        this.store.dispatch({
          type: ENTRIES_ACTIONS.CREATE_ENTRY,
          payload: e,
        })
      )
    );
  }

  protected updateItem(entry: Entry): Observable<Entry> {
    return super._updateItem(entry).pipe(
      tap((e) =>
        this.store.dispatch({
          type: ENTRIES_ACTIONS.UPDATE_ENTRY,
          payload: e,
        })
      )
    );
  }
}
