import { Component, Input, Output, EventEmitter } from '@angular/core';
@Component( {
    selector: 'search-form',
    templateUrl: './search-form.component.html',
    styleUrls: ['./search-form.component.scss']
})
export class SearchFormComponent {

    public showSearch = false;

    @Output()
    search =  new EventEmitter<void>();
    @Output()
    reset =  new EventEmitter<void>();

    @Input()
    public minWidth: number = 300;
    @Input()
    public width: number = 800;

    constructor() { }

    ngAfterViewInit() {
    }

    public show() {
        this.showSearch = true;
    }

    public hide() {
        this.showSearch = false;
    }

    public keyDownFunction( event: any ) {
        if ( event.keyCode == 13 ) {
            this.hide();
        }
    }
}
