import { NgModule } from '@angular/core';

import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { ListboxModule } from 'primeng/listbox';
import { MultiSelectModule } from 'primeng/multiselect';
import { TabViewModule } from 'primeng/tabview';
import { TooltipModule } from 'primeng/tooltip';
import { ChartModule } from 'primeng/chart';
import { PanelModule } from 'primeng/panel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CalendarModule } from 'primeng/calendar';
import { SpinnerModule } from 'primeng/spinner';
import { OverlayPanelModule } from 'primeng/overlaypanel';

export { ConfirmationService} from 'primeng/api';

@NgModule({
  imports: [
    TableModule,
    DialogModule,
    ListboxModule,
    MultiSelectModule,
    TabViewModule,
    TooltipModule,
    ChartModule,
    PanelModule,
    ConfirmDialogModule,
    CalendarModule,
    SpinnerModule,
    OverlayPanelModule,
  ],
  exports: [
    TableModule,
    DialogModule,
    ListboxModule,
    MultiSelectModule,
    TabViewModule,
    TooltipModule,
    ChartModule,
    PanelModule,
    ConfirmDialogModule,
    CalendarModule,
    SpinnerModule,
    OverlayPanelModule,
  ],
  declarations: [],
})
export class PrimengModule {
  constructor() {}
}
