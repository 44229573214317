
<!--  [style]="{width:'{{width}}'; minWidth:'{{minWidth}}';}" -->
 <p-dialog header="Search" [(visible)]="showSearch">
    <ng-content></ng-content>
    <p-footer style="padding: 10px; display:block;">
        <button mat-raised-button (click)="reset.emit()" type="button" class="btn btn-primary pull-left">Reset</button>
        <button mat-raised-button (click)="search.emit();hide()" type="button" class="btn btn-primary pull-right">Close</button>
        <button mat-raised-button (click)="search.emit()" type="button" class="btn btn-primary pull-right">Search</button>
        <div class="clearfix"></div>
    </p-footer>
</p-dialog>
