
import {tap, map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';


import { Store, Action } from '@ngrx/store';
import { Filter, StoreAction } from '../store/tayarac-store';
import { clone } from '../utils/utils';

import { ItemService } from './item.service';
import { AccessRule, Validity, JsonResponse } from '../model';

export class ACCESSRULES_ACTIONS {
    static SET_ACCESSRULES = 'SET_ACCESSRULES';
    static CREATE_ACCESSRULE = 'CREATE_ACCESSRULE';
    static UPDATE_ACCESSRULE = 'UPDATE_ACCESSRULE';
    static DELETE_ACCESSRULE = 'DELETE_ACCESSRULE';
    static SELECT_ACCESSRULE = 'SELECT_ACCESSRULE';
    static SET_ACCESSRULES_FILTER = 'SET_ACCESSRULES_FILTER';
    static CLEAR_ACCESSRULES_FILTER = 'CLEAR_ACCESSRULES_FILTER';
}

// The "accessrules" reducer performs actions on our list of accessrules
export const accessrules = accessrulesFn;
export function accessrulesFn( state: AccessRule[] = [], action: StoreAction ) {
    switch ( action.type ) {
        case ACCESSRULES_ACTIONS.SET_ACCESSRULES:
            return action.payload;
        case ACCESSRULES_ACTIONS.CREATE_ACCESSRULE:
            let x = state.filter( item => item.id === action.payload.id ).length;
            return x ? state : [...state, action.payload];
        case ACCESSRULES_ACTIONS.UPDATE_ACCESSRULE:
            return state.map( item => {
                return item.id === action.payload.id ? clone( action.payload ) : item;
            });
        case ACCESSRULES_ACTIONS.DELETE_ACCESSRULE:
            return state.filter( item => {
                return item.id !== action.payload.id;
            });
        default:
            return state;
    }
};

// The "selectedAccessRule" reducer handles the currently selected accessrule
export const selectedAccessRule = selectedAccessRuleFn;
export function selectedAccessRuleFn( state: AccessRule|null = null, action: StoreAction ) {
    switch ( action.type ) {
        case ACCESSRULES_ACTIONS.SELECT_ACCESSRULE:
            return action.payload;
        default:
            return state;
    }
};

export const accessrulesFilter = accessrulesFilterFn;
export function accessrulesFilterFn( state: Filter[] = [], action: StoreAction ) {
    switch ( action.type ) {
        case ACCESSRULES_ACTIONS.SET_ACCESSRULES_FILTER:
            return action.payload;
        case ACCESSRULES_ACTIONS.CLEAR_ACCESSRULES_FILTER:
            return [];
        default:
            return state;
    }
}

@Injectable()
export class AccessRulesService extends ItemService<AccessRule> {

    private _accessrules: Observable<Array<AccessRule>> = this.store?.select( 'accessrules' );
    private _selectedAccessRule: Observable<AccessRule> = this.store?.select( 'selectedAccessRule' );
    private _filter: Observable<Array<Filter>> = this.store?.select( 'accessrulesFilter' );

    constructor() {
        super();
    }

    get items(): Observable<Array<AccessRule>> {
        if ( !this._accessrules ) {
            this._accessrules = this.store?.select( 'accessrules' );
        }
        return this._accessrules;
    };

    get selectedItem(): Observable<AccessRule> {
        if ( !this._selectedAccessRule ) {
            this._selectedAccessRule = this.store?.select( 'selectedAccessRule' );
        }
        return this._selectedAccessRule;
    };

    get filter(): Observable<Array<Filter>> {
        if ( !this._filter ) {
            this._filter = this.store?.select( 'accessrulesFilter' );
        }
        return this._filter;
    };

    get baseUrl(): string { return this._baseUrl + '/api/accessrules'; };

    public loadItems(): void {
        this.clearErrors();

        super._getItems().pipe(
            map( ars => this.sanitizeItems( ars ) ),
            map( ars => ( { type: ACCESSRULES_ACTIONS.SET_ACCESSRULES, payload: ars }) ),)
            .subscribe( action => this.store.dispatch( action ) );
    }

    public deleteItem( accessrule: AccessRule ): void {
        this.clearErrors();
        super._deleteItem( accessrule.id ).pipe(
            map(() => ( { type: ACCESSRULES_ACTIONS.DELETE_ACCESSRULE, payload: accessrule }) ))
            .subscribe( action => this.store.dispatch( action ) );
    }

    public selectItem( accessrule: AccessRule ): void {
        this.clearErrors();
        this.store.dispatch( { type: ACCESSRULES_ACTIONS.SELECT_ACCESSRULE, payload: accessrule });
    }

    public setFilter( filters: any ) {
        this.store.dispatch( { type: ACCESSRULES_ACTIONS.SET_ACCESSRULES_FILTER, payload: filters });
    }

    protected createItem( accessrule: AccessRule ): Observable<AccessRule> {
        return super._addItem( accessrule ).pipe(
            tap( ar => this.store.dispatch( { type: ACCESSRULES_ACTIONS.CREATE_ACCESSRULE, payload: ar }) ));
    }

    protected updateItem( accessrule: AccessRule ): Observable<AccessRule> {
        return super._updateItem( accessrule ).pipe(
            tap( ar => this.store.dispatch( { type: ACCESSRULES_ACTIONS.UPDATE_ACCESSRULE, payload: ar }) ));
    }
}
