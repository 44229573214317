<form *ngIf="selectedItem" [formGroup]="form" (ngSubmit)="save(selectedItem)">
  <p-panel>
    <p-header>
      <h2 *ngIf="selectedItem.id">Editing '{{ originalName }}'</h2>
      <h2 *ngIf="!selectedItem.id">Create New {{ entityName }}</h2>
    </p-header>

    <div class="t-grid t-flex-row t-flex-wrap t-gap-3 t-align-content-center t-justify-content-start">
      <!-- Item specifics start here -->
      <div class="t-col-3">
        <mat-form-field
          class="full-width"
          [color]="!form.controls.licensePlate.valid ? 'warn' : 'primary'"
        >
          <mat-label>License plate</mat-label>
          <input
            matInput
            [readonly]="isFieldReadonly('licensePlate')"
            class="full-width"
            type="text"
            id="licensePlate"
            formControlName="licensePlate"
            required
          />
          <mat-hint
            align="start"
            [class.alert-danger]="!form.controls.licensePlate.valid"
            *ngIf="!form.controls.licensePlate.valid"
            >License plate must not be empty</mat-hint
          >
        </mat-form-field>
      </div>
      <div class="t-col-2 company-select" [formGroup]="$any(form.controls.tfCompany)">
        <mat-form-field class="full-width">
          <mat-label>Company</mat-label>
          <mat-select class="full-width" formControlName="id" required>
              <mat-option *ngFor="let company of tfCompanies" [value]="company.id">{{ company.name }}</mat-option>
          </mat-select>
          </mat-form-field>
      </div>

      <p-tabView class="full-width">
        <p-tabPanel header="Vehicle info" leftIcon="fa fa-car">
          <div class="t-grid t-flex-row t-gap-3">
            <div class="t-col-4 t-grid t-flex-wrap t-flex-row t-align-content-start">
              <div class="t-col-6 t-flex-auto">
                <mat-form-field>
                  <mat-label>Registration date</mat-label>
                  <input matInput [matDatepicker]="pickerReg" readonly formControlName="plaatsingsDatum" />
                  <mat-datepicker-toggle mdSuffix [for]="pickerReg" ></mat-datepicker-toggle>
                  <mat-datepicker #pickerReg></mat-datepicker>
                </mat-form-field>
              </div>
              <div class="t-col-6 t-flex-auto">
                <mat-form-field>
                  <mat-label>Chassis number</mat-label>
                  <input matInput type="text" id="chassisnr" formControlName="chassisnr" />
                </mat-form-field>
              </div>
              <div class="t-col-6 t-flex-auto">
                <mat-form-field
                  [color]="
                    !form.controls.numberOfSeats.valid ? 'warn' : 'primary'
                  "
                >
                  <input
                    matInput
                    placeholder="Places"
                    class="full-width"
                    type="number"
                    [min]="1"
                    id="places"
                    formControlName="numberOfSeats"
                  />
                </mat-form-field>
              </div>
              <div class="t-col-6 t-flex-auto">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Merk"
                    class="full-width"
                    type="text"
                    id="merk"
                    formControlName="merk"
                  />
                </mat-form-field>
              </div>
              <div class="t-col-6 t-flex-auto">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Model"
                    class="full-width"
                    type="text"
                    id="model"
                    formControlName="model"
                  />
                </mat-form-field>
              </div>
              <div class="t-col-6 t-flex-auto">
                <mat-form-field>
                  <input
                    matInput
                    placeholder="Color"
                    class="full-width"
                    type="text"
                    id="color"
                    formControlName="color"
                  />
                </mat-form-field>
              </div>
              <p-panel class="col-12">
                <p-header>Features</p-header>
                <mat-radio-group
                  class="feature-radio-group"
                  formControlName="carFeature"
                >
                  <mat-radio-button
                    value="vehicleBreak"
                    class="t-col-4"
                    aria-label="Break"
                    (click)="selectType($event, 'vehicleBreak')"
                    >Break</mat-radio-button
                  >
                  <!-- <mat-radio-button value="prmEquipment" class="t-col-4" aria-label="PRM" (click)="selectType($event, 'prmEquipment')">PRM</mat-radio-button> -->
                  <mat-radio-button
                    value="minivan"
                    class="t-col-4"
                    aria-label="VITO"
                    (click)="selectType($event, 'minivan')"
                    >VITO</mat-radio-button
                  >
                </mat-radio-group>
                <div class="clearfix"></div>
              </p-panel>
            </div>
            <div class="t-col-4 t-grid t-flex-column t-align-content-center t-justify-content-start">
              <p-panel class="t-col-12">
                <p-header>
                  Access rights ( Category: {{ category }} )
                </p-header>

                <mat-checkbox
                  formControlName="pickup"
                  class="t-col-12"
                  aria-label="pickup"
                  [disabled]="isFieldReadonly('pickup')"
                  >PickUp flow</mat-checkbox
                >
                <mat-checkbox
                  formControlName="dropoff"
                  class="t-col-12"
                  aria-label="dropoff"
                  [disabled]="isFieldReadonly('dropoff')"
                  >DropOff flow</mat-checkbox
                >
                <mat-checkbox
                  formControlName="p12p13"
                  class="t-col-12"
                  aria-label="p12p13"
                  [disabled]="isFieldReadonly('p12p13')"
                  >P12/P13 flow</mat-checkbox
                >
                <div class="clearfix"></div>
              </p-panel>
              <p-panel class="t-col-12">
                <p-header>Company</p-header>
                <mat-radio-group
                  class="voucher-radio-group"
                  formControlName="carVoucher"
                >
                  <mat-radio-button
                    value="twalter"
                    class="t-col-12"
                    aria-label="TWalter"
                    (click)="selectType($event, 'twalter')"
                    >TWalter</mat-radio-button
                  >
                  <mat-radio-button
                    value="unitax"
                    class="t-col-12"
                    aria-label="Unitax"
                    (click)="selectType($event, 'unitax')"
                    >UniTax</mat-radio-button
                  >
                  <mat-radio-button
                    value="autotax"
                    class="t-col-12"
                    aria-label="Autolux"
                    (click)="selectType($event, 'autotax')"
                    >AutoLux</mat-radio-button
                  >
                </mat-radio-group>
                <div class="clearfix"></div>
              </p-panel>
            </div>
            <div class="t-col-4 t-grid t-flex-column t-align-content-center t-justify-content-start">
              <p-panel class="t-col-12">
                <p-header>Booster</p-header>
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    formControlName="keySerial"
                    [matAutocomplete]="auto"
                  />
                  <mat-hint
                    align="start"
                    [class.alert-danger]="!form.controls.keySerial.valid"
                    *ngIf="!form.controls.keySerial.valid"
                    >{{ form.controls.keySerial.errors?.error }}</mat-hint
                  >
                </mat-form-field>
                <button
                  mat-icon-button
                  color="accent"
                  type="button"
                  (click)="onBoosterRemove()"
                >
                  <mat-icon fontSet="fa" fontIcon="fa-times"></mat-icon>
                </button>
                <mat-autocomplete #auto="matAutocomplete">
                  <mat-option
                    *ngFor="let key of filteredKeys | async"
                    [value]="key?.serialnr"
                  >
                    {{ key?.serialnr }}
                  </mat-option>
                </mat-autocomplete>
                <mat-checkbox
                  formControlName="needsDriverID"
                  class="t-col-12"
                  aria-label="needsDriverID"
                  [disabled]="isFieldReadonly('needsDriverID')"
                  >Require driver's ID</mat-checkbox
                >
                <div class="clearfix"></div>
              </p-panel>
              <p-panel class="t-col-12">
                <p-header>Blacklist</p-header>
                <node-blacklisting
                  [disabled]="isFieldReadonly('blacklisting')"
                  [form]="$any(form.controls['blacklisting'])"
                ></node-blacklisting>
                <div class="clearfix"></div>
              </p-panel>
            </div>
          </div>
        </p-tabPanel>
      </p-tabView>
      <!-- Item specifics end here -->
    </div>

    <ul
      class="t-col-12"
      *ngIf="errorMsgs?.length"
      class="alert alert-danger"
    >
      <li *ngFor="let err of errorMsgs">{{ err }}</li>
    </ul>

    <p-footer>
      <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Submit</button>
      <button mat-raised-button (click)="cancel(selectedItem)" type="button">Cancel</button>
    </p-footer>
  </p-panel>
</form>

<div class="t-col-12" *ngIf="debug">
  <pre>{{ debug | json }}</pre>
</div>

<div *ngIf="!selectedItem">{{ entityName }} does not exists</div>
