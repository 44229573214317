<div class="t-grid" [formGroup]="entryForm">

	<div class="t-col-8">
	    <!-- dividerColor="{{(!nameCtrl.valid && !nameCtrl.pristine) ? 'warn' : 'primary' }}" -->
		<mat-form-field class="full-width" >
		      <input matInput placeholder="Name" class="full-width" type="text" id="name" required formControlName="name" name="name"/>
		      <mat-hint align="start" [class.alert-danger]="!nameCtrl.valid && !nameCtrl.pristine" *ngIf="!nameCtrl.valid">Name must not be empty and must be unique within the company</mat-hint>
		</mat-form-field>
	</div>

	<div class="t-col-4">
		<mat-select class="t-col-3" id="direction" name="direction" required aria-labelledby="direction" formControlName="direction"
		  i18n-placeholder="Select direction placeholder" placeholder="Direction">
		  <mat-option value="inout" i18n="In/Out">In / Out</mat-option>
		  <mat-option value="in" i18n="In">In</mat-option>
		  <mat-option value="out" i18n="Out">Out</mat-option>
		</mat-select>
	</div>

    <div class="t-col-6">
        <mat-select class="full-width" id="lock" name="lock" aria-labelledby="lock" formControlName="lock"
          i18n-placeholder="Select lock placeholder" placeholder="Lock">
          <mat-option *ngFor="let lock of locks" [value]="lock">{{lock?.name}}</mat-option>
        </mat-select>
    </div>
    <div class="t-col-6"></div>
</div>
