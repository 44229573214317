import { Component, ViewEncapsulation } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from '@angular/forms';

import {
  Person,
  Address,
  Key,
  Token,
  KeyholderHasKey,
  Validity,
} from '../model';
import { KeyholderFormComponent } from '../entity-component/keyholder-form.component';
import { PersonsService } from '../services';
import { tokenHasAnyRole } from '../services/jwt.service';

@Component({
  selector: 'person-form',
  templateUrl: './person-form.component.html',
  styleUrls: ['./person-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PersonFormComponent extends KeyholderFormComponent<Person> {
  public bdStartDate? = new Date(2000, 1, 1);

  constructor(protected override _itemService: PersonsService) {
    super(_itemService);
  }

  protected createForm(): FormGroup {
    return this.fb.group({
      identity: '',
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      nationality: '',
      rijksregisternummer: '',
      tfCompany: this.fb.group({
        id: [
          { value: '', disabled: this.isFieldReadonly('tfCompany.id') },
          [Validators.required],
        ],
      }),
      description: '',
      birthDate: '',
      phone: '',
      email: '',
      address: this.fb.group({
        street: '',
        zip: '',
        city: '',
        state: '',
        country: '',
      }),
      blacklisting: this.fb.group({
        blacklisted: true,
        blacklistingStart: new Date(),
        blacklistingEnd: new Date(),
      }),
      pickup: { value: false, disabled: false }, // this.isFieldReadonly( 'pickup' ) },
      dropoff: { value: false, disabled: false }, // this.isFieldReadonly( 'dropoff' ) },
      p12p13: { value: false, disabled: false }, // this.isFieldReadonly( 'p12p13' ) },
      isBrigadier: { value: false, disabled: false }, // this.isFieldReadonly( 'isBrigadier' ) },
      keyRFID: '',
    });
  }

  override ngOnInit() {
    super.ngOnInit();
  }

  override ngOnChanges() {
    super.ngOnChanges();
    this.form.controls['pickup'].setValue(this.pickup);
    this.form.controls['dropoff'].setValue(this.dropoff);
    this.form.controls['p12p13'].setValue(this.p12p13);
    this.form.controls['isBrigadier'].setValue(this.isBrigadier);
    this.form.controls['keyRFID'].setValue(this.keyRFID);
    this.form.controls['blacklisting'].setValue(this.blacklisting);
    this.form.controls['nationality'].setValue(this.nationality);
    this.form.controls['rijksregisternummer'].setValue(
      this.rijksregisternummer
    );

    this.bdStartDate = this.selectedItem?.birthDate;
  }

  public override save(item: Person) {
    console.log('Form value: ', this.form.value);
    let x = Object.assign(new Person(), this.selectedItem, this.form.value);
    x.address = Object.assign(
      new Address(),
      this.selectedItem?.address || {},
      this.form.controls['address'].value
    );

    x = this.setAccessRule(x, 'pickup', 'PICKUP');
    x = this.setAccessRule(x, 'dropoff', 'DROPOFF');
    x = this.setAccessRule(x, 'p12p13', 'P12P13');
    x = this.setProperty(x, 'isBrigadier');
    x = this.setRFID(x);
    x = this.setValidity(x);

    //delete x['properties'];
    x = this.setProperty(x, 'nationality');
    x = this.setProperty(x, 'rijksregisternummer');

    console.log('Object value: ', x);
    // this.debug = x;
    super.save(x);
  }

  protected getEntityName(): string {
    return 'Person';
  }

  // Fix to avoid day shift due to TZ and DST
  public setBirthDate(ev: any) {
    const d: Date = ev;
    d.setHours(12, 0, 0, 0);
    this.form.controls['birthDate'].setValue(d, { onlySelf: true });
  }

  protected setProperty(x: Person, prop: string) {
    x.properties = x.properties || {};
    x.properties[prop] = (x as any)[prop];
    delete (x as any)[prop];
    return x;
  }

  public get nationality() {
    return this.getProperty('nationality');
  }

  public get rijksregisternummer() {
    return this.getProperty('rijksregisternummer');
  }

  public get keyRFID(): string {
    let l = this.selectedItem
      ? (this.selectedItem as any)['keyRFID']
      : undefined;
    //            && this.selectedItem.keys[0]
    //            && this.selectedItem.keys[0].key
    //            && this.selectedItem.keys[0].key.tokens
    //            && this.selectedItem.keys[0].key.tokens[0]
    //            ? this.selectedItem.keys[0].key.tokens[0].identity : "";
    return l || '';
  }

  protected setRFID(x: Person) {
    let rfid = (x as any)['keyRFID'];
    let key: Key | undefined = undefined;
    if (rfid) {
      if (this.keyRFID != rfid) {
        let token: Token = new Token();
        token.identity = this.convertDecToXecRFID(rfid);

        key = new Key();
        key.identity = rfid;
        key.serialnr = rfid;
        key.tokens = [token];
        key.properties = Object.assign({}, key.properties, {
          carBooster: false,
        });
        key.properties['carBooster'] = false;

        let khhk = new KeyholderHasKey();
        khhk.key = key;
        khhk.start = new Date();
        x.keys = [khhk];
      }
    } else {
      x.keys = [];
    }

    delete (x as any)['keyRFID'];
    return x;
  }

  public isFieldReadonly(field: string): boolean {
    const result = !tokenHasAnyRole(['Administrators', 'Badge operator']);
    return result;
  }

  public get isBrigadier() {
    return this.getProperty('isBrigadier');
  }

  public get badgeStatus() {
    return this.getProperty('PERSON_BADGE_STATUS');
  }

  private convertDecToXecRFID(rfid: string): string {
    let decNumber = parseInt(rfid, 10);
    let hexString = decNumber.toString(16);
    while (hexString.length < 4) {
      hexString = '0' + hexString;
    }
    hexString = '0a' + hexString;
    return hexString;
  }

  protected filterKeysArray(keys: Array<Key>): Array<Key> {
    return !!keys
      ? keys.filter((k) => !k.properties || !k.properties['carBooster'])
      : keys;
  }

  protected findKeyBySerial(serial: string): Key | undefined {
    let key = this.keysArray.find(
      (k) =>
        k.serialnr === serial && (!k.properties || !k.properties['carBooster'])
    );
    return key;
  }
}
