import { Component, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { EntitiesComponent } from '../entity-component/entities.component';

import { Lock } from '../model';
import { LocksService } from '../services/locks.service';

export { LocksListComponent } from './locks-list.component';
export { LockFormComponent } from './lock-form.component';

@Component( {
    selector: 'app-locks',
    templateUrl: './locks.component.html',
    styleUrls: ['./locks.component.scss'],
})
export class LocksComponent extends EntitiesComponent<Lock> {

    constructor( protected override route: ActivatedRoute, protected override router: Router, protected lockService: LocksService ) {
        super( Lock, lockService);
    }

    override ngOnInit() {
        super.ngOnInit()

        this.sortSettings = {
            multisortmeta: [{ field: 'name', order: 1 }]
        }
    }
}
