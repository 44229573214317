import { Component } from '@angular/core';

import { Key } from '../model';
import { EntityListComponent } from '../entity-component/entity-list.component';

@Component( {
    selector: 'keys-list',
    templateUrl: './keys-list.component.html',
    styleUrls: ['./keys-list.component.scss'],
})
export class KeysListComponent extends EntityListComponent<Key> {

    protected getEditItemPath(): string {
        return '/edit-key';
    }

}
