import { Input, Output, OnInit, EventEmitter, ViewChild, Directive } from '@angular/core';
import { ServiceLocator } from '../services/service.locator';
import { ConfirmationService } from '../utils';
import { Table } from 'primeng/table'

@Directive()
export abstract class BaseEntityListComponent<Item> implements OnInit {

    private _items: Item[] = [];
    private _loaded = false;
    private _setItemsCnt = 0;

    @ViewChild( Table ) dtable!: Table;

    @Output()
    public added = new EventEmitter();
    @Output()
    public selected = new EventEmitter<Item>();
    @Output()
    public deleted = new EventEmitter<Item>();
    @Output()
    public search = new EventEmitter();

    @Output()
    public sortSettingsChange = new EventEmitter();
    private _sortSettings: any;
    @Input()
    public set sortSettings( value ) {
        this._sortSettings = value;
    }

    public get sortSettings() {
        return this._sortSettings;
    }

    @Output()
    public pagingChange = new EventEmitter<{ first: number, rows: number }>();
    private _paging: { first: number, rows: number } = { first: 0, rows: 10 };

    private _first = 0;

    @Input()
    public set paging( value: { first: number, rows: number } ) {
        this._paging = value;
        setTimeout(() => {
            this._first = this._paging.first;
        }, 0);
    }
    public get first(): number {
        return this._first;
    }
    public get rows(): number {
        return this._paging.rows;
    }
    public onPage( event: any ) {
        this.pagingChange.emit( event );
    }

    protected confirmationService: ConfirmationService;

    constructor() {
        console.log( 'EntityListComponent created' );
        this.confirmationService = ServiceLocator.injector.get( ConfirmationService );
    }

    public ngOnInit(): void {
    }

    protected abstract getEditItemPath(): string;

    @Input()
    public set items( value: Item[] ) {
        this._items = value;
        if ( this._setItemsCnt > 0 || ( !!value && value.length > 0 ) ) {
            this._loaded = true;
        }

        this._setItemsCnt += 1;
    }

    public get loading() {
        return !this._loaded;
    }

    public get items(): Item[] {
        return this._items;
    }

    public onSort( event: any ) {
        console.log( event );
        this.sortSettingsChange.emit( event );
    }

    public add() {
        this.added.emit();
    }

    public select( item: Item ) {
        this.selected.emit( item );
    }

    public remove( event: any, item: Item ) {
        event.stopPropagation();
        this.confirmationService.confirm( {
            message: 'Are you sure that you want to delete \'' + this.getName( item ) + '\'?',
            accept: () => {
                this.deleted.emit( item );
            }
        } );
    }

    protected abstract getName( item: Item ): string;
}
