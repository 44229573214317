<div class="container-fluid">

        <p-table [value]="items"  [multiSortMeta]="sortSettings?.multisortmeta" [loading]="loading"
          selectionMode="single" (onRowSelect)="select($event.data)" [resizableColumns]="true"
          sortMode="multiple" [rows]="rows"  [first]="first" (onSort)="onSort($event)" (onPage)="onPage($event)"
          [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">
            <ng-template pTemplate="caption">
                <div class="pull-left"><h4>Vehicles</h4></div>

                <div class="pull-right">
                    <button mat-icon-button color="primary" (click)="search.emit()" type="button">
                       <mat-icon fontSet="fa" fontIcon="fa-search" class="mat-24"></mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="add()" type="button" *ngIf="canAddVehicle()">
                       <mat-icon fontSet="fa" fontIcon="fa-plus" class="mat-24"></mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="download.emit()" type="button">
                       <mat-icon fontSet="fa" fontIcon="fa-file-archive-o" class="mat-24"></mat-icon>
                    </button>
                </div>
                <div class="clearfix"></div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="licensePlate">License Plate <p-sortIcon field="licensePlate"></p-sortIcon></th>
                    <th pSortableColumn="companyName">Company <p-sortIcon field="companyName"></p-sortIcon></th>
                    <th pSortableColumn="keySerial">Booster <p-sortIcon field="keySerial"></p-sortIcon></th>
                    <th>Access rules</th>
                    <th>Features</th>
                    <th pSortableColumn="blacklisted" class="col-center">BL <p-sortIcon field="blacklisted"></p-sortIcon></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-car>
              <tr [pSelectableRow]="rowData">
                  <td>{{car.licensePlate}}</td>
                  <td>{{car.companyName}}</td>
                  <td>{{car.keySerial}}</td>
                  <td>{{getAccessRights(car)}}</td>
                  <td class="feature-list">
                      <i *ngIf="car.properties?.prmEquipment" class="fa fa-credit-card"></i>
                      <i *ngIf="car.properties?.vehicleBreak" class="fa fa-truck" ></i>
                      <i *ngIf="car.properties?.minivan" class="fa fa-bus"></i>
                      <i *ngIf="car.properties?.twalter">TW</i>
                      <i *ngIf="car.properties?.unitax">UT</i>
                      <i *ngIf="car.properties?.autotax">AL</i>
                  </td>
                  <td class="col-center"><i class="fa" [ngClass]="{'fa-hand':car.blacklisted, 'fa-location-arrow':!car.blacklisted}" ></i></td>
                  <td>
                      <button mat-icon-button color="accent" (click)="remove($event, car)">
                          <mat-icon fontSet="fa" fontIcon="fa-trash-can"></mat-icon>
                      </button>
                  </td>
              </tr>
            </ng-template>
        </p-table>

</div>
