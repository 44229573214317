import { Component, Input, OnInit } from '@angular/core';

import { KeyholdersComponent } from '../entity-component/keyholders.component';

import { Vehicle } from '../model';
import { VehiclesService } from '../services/vehicles.service';

export { VehiclesListComponent } from './vehicles-list.component';
export { VehicleFormComponent } from './vehicle-form.component';

@Component( {
    selector: 'app-vehicles',
    templateUrl: './vehicles.component.html',
    styleUrls: ['./vehicles.component.scss'],
} )
export class VehiclesComponent extends KeyholdersComponent<Vehicle> {

    constructor( protected vehicleService: VehiclesService ) {
        super( Vehicle, vehicleService );
    }

    override ngOnInit(): void {
        super.ngOnInit();

        this.sortSettings = {
            multisortmeta: [{ field: 'licensePlate', order: 1 }]
        }
    }


    public downloadData() {
        this.vehicleService.getFullReport().subscribe({
          next: data => this.downloadFile( data ),
          error: error => console.log( "Error downloading the file." ),
          complete: () => console.info( "OK" )
        });
    }

    downloadFile( data: string ) {
        //        var blob = new Blob( [data], { type: 'text/csv' } );
        //        var url = window.URL.createObjectURL( blob );
        //        window.open( url );

        const blob: Blob = new Blob( [data], { type: 'text/csv' } );
        const fileName: string = 'vehicles.csv';

        const nav = (window.navigator as any);
        if ( nav.msSaveOrOpenBlob ) {
            nav.msSaveOrOpenBlob( blob, fileName);
        } else {
            const objectUrl: string = URL.createObjectURL( blob );
            const a: HTMLAnchorElement = document.createElement( 'a' ) as HTMLAnchorElement;

            a.href = objectUrl;
            a.download = fileName;
            document.body.appendChild( a );
            a.click();

            document.body.removeChild( a );
            URL.revokeObjectURL( objectUrl );
        }
    }

}
