import {share, mergeMap, filter, map, retry, delay, repeat, tap} from 'rxjs/operators';
import {Injectable, Inject, InjectionToken} from '@angular/core';
import {Observable, Observer, Subject, BehaviorSubject, of, defer} from 'rxjs';
import {AnonymousSubject} from 'rxjs/internal/Subject';
import {AuthenticationService} from './authentication.service';
import {STORE_ACTIONS} from "../store/tayarac-store";

export let wsBaseURL = new InjectionToken<string>('wsBaseURL');
export let wsTimeout = new InjectionToken<number>('wsTimeout');

// Based on https://medium.com/@lwojciechowski/websockets-with-angular2-and-rxjs-8b6c5be02fac#.r2xlpj4q0
export class WebSocketService {
  private subject?: Subject<any>;

  constructor(private authSvc: AuthenticationService) {
  }

  public send(data: any) {
    this.subject?.next(data);
  }

  public asObservable(): Observable<any> | undefined {
    return this.subject?.asObservable().pipe(share());
  }

  public connect(url: string, onOpen?: (e: any) => void): Observable<any> {
    if (!this.subject) {
      this.subject = this.create(url, onOpen);
    }
    let obs = this.asObservable();
    return obs!;
  }

  protected create(url: string, onOpen?: (e: any) => void): Subject<any> {
    let ws: any;
    let observable = defer(() => this.authSvc.requestWSToken()).pipe(
      filter((resp) => resp.status === 'SUCCESS'),
      map((resp) => resp.data),
      mergeMap((token) => {
        let x = new Observable((obs: Observer<any>) => {
          console.log('Connecting to: ', url);
          ws = new WebSocket(
            url.replace('http://', 'ws://').replace('https://', 'wss://')
          );

          ws.onopen = (e: any) => {
            const callAction = {
              action: 'AUTHENTICATE',
              payload: token,
            };
            ws.send(JSON.stringify(callAction));
            if (onOpen) onOpen.call(this, e);
            //                    this.store.dispatch( { type: STORE_ACTIONS.DASHBOARD_ONLINE, payload: true });
          };
          ws.onmessage = obs.next.bind(obs);
          ws.onerror = (e: any) => {
            //                    this.store.dispatch( { type: STORE_ACTIONS.DASHBOARD_ONLINE, payload: false });
            obs.error(e);
          };
          ws.onclose = () => {
            //                    this.store.dispatch( { type: STORE_ACTIONS.DASHBOARD_ONLINE, payload: false });
            obs.complete();
          };
          return ws.close.bind(ws);
        });

        return x;
      }),
      tap({
        next: d => {
        }, error: e => {
          console.log('Error from ws: ', e);
        }, complete: () => {
        }
      }),
      retry({
        delay: _ => {
          return of(0).pipe(delay(5000));
        }
      })
    );

    let observer: Observer<any> = {
      next: (data: any) => {
        if (ws && ws.readyState === WebSocket.OPEN) {
          ws.send(JSON.stringify(data));
        }
      },
      error: (e: any) => {
        console.error(`Error processing WebSocket: ${e}`);
      },
      complete: () => {
        console.debug(`WebSocket completed`);
      },
    };

    return new AnonymousSubject(observer, observable);
  }
} // end class WebSocketService
