import { Component, Input, Output, EventEmitter } from '@angular/core';

import { Validity } from '../model';

@Component( {
    selector: 'node-validity',
    styles: ['label {display: block;max-width: 100%;font-weight: 500;color: rgba(0,0,0,0.9);}'],
    template: `<div class="t-col-3">
                    <mat-checkbox id="hasValidity" name="hasValidity" [(ngModel)]="hasValidity">Has validity</mat-checkbox>
                </div>
                <div class="t-col-9 t-col-nopad">
                    <div class="t-col-12"  *ngIf="hasValidity">
                        <label for="validityStart">Validity start:</label>
                        <p-calendar id="validityStart" name="validityStart" [ngModel]="validity.validityStart"
                        (ngModelChange)="validity.validityStart = $event" [showTime]="true" [showIcon]="true"></p-calendar>
                    </div>
                    <div class="t-col-12" *ngIf="hasValidity">
                        <label for="validityEnd">Validity end:</label>
                        <p-calendar id="validityEnd" name="validityEnd" #validityEnd [ngModel]="validity.validityEnd" [required]="true"
                        (ngModelChange)="validity.validityEnd = $event" [showTime]="true" [showIcon]="true"></p-calendar>
                    </div>
                </div>`
})
export class NodeValidityComponent {

    protected _lastValidity?: Validity;
    protected _validity: Validity = new Validity();
    protected _hasValidity: boolean = false;

    @Output()
    protected hasValidityChange = new EventEmitter<boolean>();
    @Output()
    protected validityChange = new EventEmitter<Validity>();

    constructor() {
    }

    get hasValidity(): boolean {
        return this._hasValidity;
    }

    // @Input()
    set hasValidity( value: boolean ) {
        if ( value ) {
            if ( !this._lastValidity ) {
                this._lastValidity = new Validity();
            }

            this._validity = this._lastValidity;

        } else {
            this._validity = new Validity();
        }
        this._hasValidity = value;
        this.validityChange.emit( this._validity );
    }

    get validity(): Validity {
        return this._validity;
    }

    @Input()
    set validity( value: Validity ) {
        if ( value ) {
            this._lastValidity = value;
            this._hasValidity = true;
        } else {
            this._hasValidity = false;
        }
        this._validity = value || new Validity();
    }

}
