<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd>
   <p-footer>
        <button type="button" pButton icon="fa fa-check" label="Yes" (click)="cd.accept()"></button>
        <button type="button" pButton icon="fa fa-close" label="No" (click)="cd.reject()"></button>
    </p-footer>
</p-confirmDialog>

<h1 class="title">Debug</h1>
<span *ngIf="!(ap | async)">Loading...</span>


<div class="t-grid">

    <div class="t-col-3">
        <p-panel class="t-col-12 bacarea baccard offsite">
            <p-header class="title">
                 CARS
            </p-header>
            <p-listbox [options]="(cars|async) || []" [(ngModel)]="selectedCar" class="car-list">
                <ng-template let-car pTemplate="item">
                    <i class="fa fa-cab" [ngClass]="{'car-blacklisted': car.value.blacklisted}"></i>{{car.value.licensePlate}} [{{getAccessRights(car.value)}}]
                    <i *ngIf="-1 !== car.value.carFeatures.indexOf('BREAK')" class="fa fa-truck" ></i>
                    <i *ngIf="-1 !== car.value.carFeatures.indexOf('PRM')" class="fa fa-credit-card"></i>
                    <i *ngIf="-1 !== car.value.carFeatures.indexOf('MINIVAN')" class="fa fa-bus"></i>
                    <i *ngIf="-1 !== car.value.carFeatures.indexOf('TWALTER')">TW</i>
                    <i *ngIf="-1 !== car.value.carFeatures.indexOf('UNITAX')">UT</i>
                    <i *ngIf="-1 !== car.value.carFeatures.indexOf('AUTOTAX')">AL</i>
                </ng-template>
            </p-listbox>
        </p-panel>
    </div>
    <div class="t-col-3 action-area">
        <button mat-raised-button color="accent" type="button" (click)="systemDown(true, false)" *ngIf="!(airportDown | async)">Take system down</button>
        <div class="t-grid t-flex-row t-flex-nowrap t-justify-content-between t-align-content-center t-gap-2" *ngIf="!(recoveryMode | async) && (airportDown | async)">
            <button class="t-flex-auto multiline recovery" mat-raised-button type="button" (click)="systemDown(false, false)">Recovery<br />Normal</button>
            <button class="t-flex-auto multiline recovery" mat-raised-button type="button" (click)="systemDown(false, true)">Recovery<br />EXPRESS</button>
        </div>

        <br/>
        <button mat-raised-button color="primary" type="button" (click)="emulateGoc('t2i', 'T2I')">T2 Entry</button>
        <button mat-raised-button color="primary" type="button" (click)="emulateGoc('t2o', 'T2O')">T2 Exit</button>
        <button mat-raised-button color="primary" type="button" (click)="emulateGoc('t1i', 'T1I')">T1 Entry</button>
        <button mat-raised-button color="primary" type="button" (click)="emulateGoc('t1o', 'T1O')">T1 Exit</button>
        <button mat-raised-button color="primary" type="button" (click)="emulateGoc('t0il', 'T0IL')">T0 Left Entry</button>
        <button mat-raised-button color="primary" type="button" (click)="emulateGoc('t0ir', 'T0IR')">T0 Right Entry</button>
        <button mat-raised-button color="primary" type="button" (click)="emulateGoc('t0o', 'T0O')">T0 Exit</button>
        <button mat-raised-button color="primary" type="button" (click)="emulateGoc('p12p13i', 'P12P13I')">T0 P12/P13 Exit</button>
        <button mat-raised-button color="accent" type="button" (click)="sendCmd('moveCmd', 'OFFSITE')">Kick car out to OFFSITE</button>
        <div class="t-grid t-flex-row t-flex-nowrap t-justify-content-between t-align-content-center t-gap-2">
            <button class="t-flex-auto multiline" mat-raised-button color="{{(t2InUse | async)=='MI'?'primary':'#d0d0d0'}}" type="button" (click)="sendInUseCmd('areaInUseCmd', 'T2', 'MI')">Pt Manual<br />In Use</button>
            <button class="t-flex-auto multiline" mat-raised-button color="{{(t2InUse | async)=='MO'?'primary':'#d0d0d0'}}" type="button" (click)="sendInUseCmd('areaInUseCmd', 'T2', 'MO')">Pt Manual<br />Out of Use</button>
            <button class="t-flex-auto multiline" mat-raised-button color="{{(t2InUse | async)=='A'?'primary':'#d0d0d0'}}" type="button" (click)="sendInUseCmd('areaInUseCmd', 'T2', 'A')">Pt<br />Automatic</button>
        </div>
        <div class="t-grid t-flex-row t-flex-nowrap t-justify-content-between t-align-content-center t-gap-2" *ngIf="isAdministrator()">
            <button class="t-flex-auto multiline" mat-raised-button color="{{(t1InUse | async)=='MI'?'primary':'#d0d0d0'}}" type="button" (click)="sendInUseCmd('areaInUseCmd', 'T1', 'MI')">Res Manual<br />In Use</button>
            <button class="t-flex-auto multiline" mat-raised-button color="{{(t1InUse | async)=='MO'?'primary':'#d0d0d0'}}" type="button" (click)="sendInUseCmd('areaInUseCmd', 'T1', 'MO')">Res Manual<br />Out of Use</button>
            <button class="t-flex-auto multiline" mat-raised-button color="{{(t1InUse | async)=='A'?'primary':'#d0d0d0'}}" type="button" (click)="sendInUseCmd('areaInUseCmd', 'T1', 'A')">Res<br />Automatic</button>
        </div>
<!--
        <button mat-raised-button color="accent" type="button" (click)="sendResetCmd()" *ngIf="isAdministrator()">Reset Airport</button>
 -->
    </div>
    <div class="t-col-6">
        <p-panel class="t-col-12 alerts baccard">
           <p-header class="title">
               Alert messages
           </p-header>
           <ul>
              <li *ngFor="let alert of alerts | async" class="bacalert">
                    <i class="fa" [ngClass]="{'fa-info-circle':(alert.type==='INFO'), 'fa-warning':(alert.type==='WARN')}"></i>
                    <span class="datetime">{{alert.timestamp | date:'short'}}</span> {{alert.message}}
              </li>
           </ul>
        </p-panel>
    </div>


</div>
