
import {map, filter, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';


import { Store, Action } from '@ngrx/store';
import { Filter, StoreAction } from '../store/tayarac-store';
import { clone } from '../utils/utils';

import { ItemService } from './item.service';
import { Lock, Validity, JsonResponse } from '../model';

import { dateReviver } from '../utils/utils';

export class LOCKS_ACTIONS {
    static SET_LOCKS = 'SET_LOCKS';
    static CREATE_LOCK = 'CREATE_LOCK';
    static UPDATE_LOCK = 'UPDATE_LOCK';
    static DELETE_LOCK = 'DELETE_LOCK';
    static SELECT_LOCK = 'SELECT_LOCK';
    static SET_LOCKS_FILTER = 'SET_LOCKS_FILTER';
    static CLEAR_LOCKS_FILTER = 'CLEAR_LOCKS_FILTER';
}

// The "locks" reducer performs actions on our list of locks
export const locks = locksFn;
export function locksFn( state: Lock[] = [], action: StoreAction ) {
    switch ( action.type ) {
        case LOCKS_ACTIONS.SET_LOCKS:
            return action.payload;
        case LOCKS_ACTIONS.CREATE_LOCK:
            let x = state.filter( item => item.id === action.payload.id ).length;
            return x ? state : [...state, action.payload];
        case LOCKS_ACTIONS.UPDATE_LOCK:
            return state.map( item => {
                return item.id === action.payload.id ? clone( action.payload ) : item;
            });
        case LOCKS_ACTIONS.DELETE_LOCK:
            return state.filter( item => {
                return item.id !== action.payload.id;
            });
        default:
            return state;
    }
};

// The "selectedLock" reducer handles the currently selected lock
export const selectedLock = selectedLockFn;
export function selectedLockFn( state: Lock|null = null, action: StoreAction ) {
    switch ( action.type ) {
        case LOCKS_ACTIONS.SELECT_LOCK:
            return action.payload;
        default:
            return state;
    }
};

export const locksFilter = locksFilterFn;
export function locksFilterFn( state: Filter[] = [], action: StoreAction ) {
    switch ( action.type ) {
        case LOCKS_ACTIONS.SET_LOCKS_FILTER:
            return action.payload;
        case LOCKS_ACTIONS.CLEAR_LOCKS_FILTER:
            return [];
        default:
            return state;
    }
}

@Injectable()
export class LocksService extends ItemService<Lock> {

    private _locks = this.store?.select( 'locks' );
    private _selectedLock= this.store?.select( 'selectedLock' );
    private _filter = this.store?.select( 'locksFilter' );

    constructor() {
        super();
    }

    get items(): Observable<Array<Lock>> {
        if ( !this._locks )
            this._locks = this.store?.select( 'locks' );
        return this._locks;
    };

    get selectedItem(): Observable<Lock> {
        if ( !this._selectedLock )
            this._selectedLock = this.store?.select( 'selectedLock' );
        return this._selectedLock;
    };

    get filter(): Observable<Array<Filter>> {
        if ( !this._filter )
            this._filter = this.store?.select( 'locksFilter' );
        return this._filter;
    };

    get baseUrl(): string { return this._baseUrl + '/api/locks'; };


    protected override init() {
        super.init();

        this.wsService.asObservable()?.pipe(
            map( d => {
                return JSON.parse( d.data, dateReviver );
            }),filter( d => {
                return ( !!d.lock ) && ( !!d.action );
            }),)
            .subscribe( d => {
                console.log( "Lock: ", d.lock );
                switch ( d.action ) {
                    case 'CREATED':
                        this.store.dispatch( { type: LOCKS_ACTIONS.CREATE_LOCK, payload: d.lock })
                        break;
                    case 'UPDATED':
                        this.store.dispatch( { type: LOCKS_ACTIONS.UPDATE_LOCK, payload: d.lock })
                        break;
                    case 'DELETED':
                        this.store.dispatch( { type: LOCKS_ACTIONS.DELETE_LOCK, payload: d.lock })
                        break;
                }
            });
    }

    public loadItems(): void {
        this.clearErrors();

        super._getItems().pipe(
            map( ls => this.sanitizeItems( ls ) ),
            map( ls => ( { type: LOCKS_ACTIONS.SET_LOCKS, payload: ls }) ),)
            .subscribe( action => this.store.dispatch( action ) );
    }

    public deleteItem( lock: Lock ): void {
        this.clearErrors();
        super._deleteItem( lock.id ).pipe(
            map(() => ( { type: LOCKS_ACTIONS.DELETE_LOCK, payload: lock }) ))
            .subscribe( action => this.store.dispatch( action ) );
    }

    public selectItem( lock: Lock ): void {
        this.clearErrors();
        this.store.dispatch( { type: LOCKS_ACTIONS.SELECT_LOCK, payload: lock });
    }

    public setFilter( filters: any ) {
        this.store.dispatch( { type: LOCKS_ACTIONS.SET_LOCKS_FILTER, payload: filters });
    }

    protected createItem( lock: Lock ): Observable<Lock> {
        return super._addItem( lock ).pipe(
            tap( l => this.store.dispatch( { type: LOCKS_ACTIONS.CREATE_LOCK, payload: l }) ));
    }

    protected updateItem( lock: Lock ): Observable<Lock> {
        return super._updateItem( lock ).pipe(
            tap( l => this.store.dispatch( { type: LOCKS_ACTIONS.UPDATE_LOCK, payload: l }) ));
    }
}
