<div class="container-fluid">
        <p-table [value]="items" [multiSortMeta]="sortSettings?.multisortmeta" [loading]="loading"
          selectionMode="single" (onRowSelect)="select($event.data)" [resizableColumns]="true"
          sortMode="multiple" [rows]="rows"  [first]="first" (onSort)="onSort($event)" (onPage)="onPage($event)"
          [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]">

            <ng-template pTemplate="caption">
                <div class="pull-left"><h4>Persons</h4></div>

                <div class="pull-right">
                    <button mat-icon-button color="primary" (click)="search.emit()" type="button">
                      <mat-icon fontSet="fa" fontIcon="fa-search" class="mat-24"></mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="add()" type="button"  *ngIf="false">
                      <mat-icon fontSet="fa" fontIcon="fa-plus" class="mat-24"></mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="download.emit()" type="button">
                       <mat-icon fontSet="fa" fontIcon="fa-file-zipper" class="mat-24"></mat-icon>
                    </button>
                </div>
                <div class="clearfix"></div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="identity" pResizableColumn>Person ID <p-sortIcon field="identity"></p-sortIcon></th>
                    <th pSortableColumn="firstName" pResizableColumn>First name <p-sortIcon field="firstName"></p-sortIcon></th>
                    <th pSortableColumn="lastName" pResizableColumn>Last name <p-sortIcon field="lastName"></p-sortIcon></th>
                    <th pSortableColumn="companyName" pResizableColumn>Company <p-sortIcon field="companyName"></p-sortIcon></th>
                    <th pSortableColumn="keyRFID" pResizableColumn>Badge ID <p-sortIcon field="keyRFID"></p-sortIcon></th>
                    <th>Badge status</th>
                    <th>Access rules</th>
                    <th pSortableColumn="blacklisted" class="col-center">BL <p-sortIcon field="blacklisted"></p-sortIcon></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-person>
                <tr [pSelectableRow]="rowData">
                    <td>{{ person.identity }}</td>
                    <td>{{ person.firstName }}</td>
                    <td>{{ person.lastName }}</td>
                    <td>{{ person.companyName }}</td>
                    <td>{{ person.keyRFID }}</td>
                    <td>{{getBadgeStatus(person)}}</td>
                    <td>{{getAccessRights(person)}}</td>
                    <td class="col-center"><i class="fa" [ngClass]="{'fa-hand':person.blacklisted, 'fa-location-arrow':!person.blacklisted}" ></i></td>
                    <td class="col-center">
                      <button mat-icon-button color="accent" (click)="remove($event, person)">
                        <mat-icon fontSet="fa" fontIcon="fa-trash-can"></mat-icon>
                      </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

</div>
