import { of as observableOf, Observable, onErrorResumeNext, defer } from 'rxjs';
import {
  map,
  catchError,
  retryWhen,
  tap,
  retry,
  filter,
  flatMap,
  mergeMap,
  take,
  timeout,
} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {
  Router,
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';

import { JsonResponse } from '../model';
import { baseURL, TayaracCompany } from './item.service';
import { tokenNotExpired, tokenHasAnyRole, tokenRoles } from './jwt.service';

import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { PopupRequest } from '@azure/msal-browser';

type PermissionType = {
  open: boolean;
  mobile?: boolean;
  roles: string[];
};

let permissions: {
  [key: string]: PermissionType;
} = {
  '/dashboard': { open: true, roles: [] },
  '/login': { open: false, mobile: true, roles: ['User'] },
  '/overview': { open: false, roles: ['Administrators', 'Taxi ABR'] },
  '/podium': { open: true, roles: ['Dispatch'] },
  '/mpodium': { open: true, mobile: true, roles: ['Dispatch', 'Brigadier'] },
  '/brigadier': { open: false, roles: ['Brigadier'] },
  '/apoc': { open: false, roles: ['Administrators'] },
  '/diagnostic': { open: false, roles: ['Administrators'] },
  '/debug': { open: false, roles: ['Administrators'] },
  '/transitions': {
    open: false,
    roles: ['Administrators', 'Taxi ABR', 'Brigadier'],
  },
  '/mtransitions': {
    open: false,
    mobile: true,
    roles: ['Administrators', 'Taxi ABR', 'Brigadier'],
  },
  '/keys': { open: false, roles: ['N/A'] },
  '/locks': { open: false, roles: ['N/A'] },
  '/persons': {
    open: false,
    roles: ['Administrators', 'Taxi ABR', 'Fedpol', 'Badge operator'],
  },
  '/system': { open: false, roles: ['Administrators'] },
  '/companies': { open: false, roles: ['Administrators'] },
  '/admins': { open: false, roles: ['Administrators'] },
  '/settings': { open: false, roles: ['Administrators'] },
  '/vehicles': {
    open: false,
    roles: ['Administrators', 'Taxi ABR', 'Metrotax', 'Fedpol'],
  },
  '/areas': { open: false, roles: ['N/A'] },
  '/reporting': { open: false, roles: ['N/A'] },
};
@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {
    if (!tokenNotExpired()) {
      // not logged in so redirect and return false
      this.router.navigate(['/login']);
      return false;
    }

    return this.urlIsAllowed(state.url);
  }

  public urlIsAllowed(url: string): boolean {
    if (
      permissions.hasOwnProperty(url) &&
      (permissions[url].open || tokenHasAnyRole(permissions[url].roles)) &&
      this.checkIfMobileAndAllowed(permissions[url])
    ) {
      return true;
    }
    return false;
  }

  public isOpenURL(url: string): boolean {
    return permissions.hasOwnProperty(url) && permissions[url].open;
  }

  public isMobile(): boolean {
    return this.deviceService.isMobile();
  }

  private checkIfMobileAndAllowed(perm: PermissionType): boolean {
    return this.isMobile() ? perm.mobile || false : !perm.mobile;
  }
}

@Injectable()
export class AuthenticationService {
  public token?: string;

  private error: string = '';

  private callbackUrl?: string;

  constructor(
    private http: HttpClient,
    private msalService: MsalService,
    private broadcastService: MsalBroadcastService,
    private router: Router,
    @Inject(baseURL) private _baseUrl: string,
    @Inject(TayaracCompany) private tyCompany: string
  ) {
    // set token if saved in local storage
    let currentUser = JSON.parse(localStorage.getItem('currentUser') || '{}');
    this.token = currentUser && currentUser.token;
  }

  public signUp() {
    this.callbackUrl = '/dashboard';
    this.signUpInt();
  }

  private signUpInt() {
    defer(() => {
      // alert('Going to signin at MS');
      return this.msalService.loginPopup();
    })
      .pipe(
        mergeMap((event) => {
          // alert('Going to signin at TF');
          return this.login();
        }),
        retry({
          delay: (errors) => {
            // alert('Error signin at MS: ' + JSON.stringify(errors));
            return errors.pipe(tap(() => console.log('Login retrying...')));
          },
        }),
        take(1)
      )
      .subscribe({
        next: (result: any) => {
          // alert('Signed at TF');
          if (result === true) {
            this.router.navigateByUrl(this.callbackUrl ?? '/dashboard');
          } else {
            // login failed
            this.error =
              typeof result === 'string'
                ? result
                : 'Username or password is incorrect';
          }
        },
        error: (err) => {
          // alert('Error signin at TS: ' + JSON.stringify(err));
        },
        complete: () => {
          // alert('Finished signing at TF');
        },
      });
  }

  login(username?: string, password?: string): Observable<boolean | string> {
    let headers = new HttpHeaders({ 'Content-Type': 'application/json' });

    localStorage.removeItem('currentUser');

    return this.http
      .post<JsonResponse<any>>(
        this._baseUrl + '/api/authenticate', //JSON.stringify(
        { username: username, password: password, company: this.tyCompany }, // ),
        { headers: headers, observe: 'response' }
      )
      .pipe(
        catchError((e) => {
          return observableOf(e);
        }),

        map((response) => {
          if (response.status === 403) {
            this.error = 'Username or password incorrect!';
            return this.error;
          }

          if (response.type === 'error') {
            this.error = 'Communication error!';
            return this.error;
          }

          let jsonResponse = response.body; //this.getJsonResponse( response );
          if (typeof jsonResponse === 'boolean') {
            this.error = response.statusText;
            return this.error;
          }

          if (!response.ok || jsonResponse?.status !== 'SUCCESS') {
            this.error =
              jsonResponse?.errorMsg || 'Username or password incorect!';
            return this.error;
          }

          let token = jsonResponse.data.token;
          if (token) {
            // set token property
            this.token = token;

            // store username and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(
              'currentUser',
              JSON.stringify({ username: username, token: token })
            );

            // return true to indicate successful login
            return true;
          } else {
            // return false to indicate failed login
            this.error = 'Server error: token unavailable!';
            return this.error;
          }
        })
      );
  }

  logout(): void {
    // clear token remove user from local storage to log user out
    this.token = undefined;
    localStorage.removeItem('currentUser');

    this.msalService
      .logoutPopup({
        mainWindowRedirectUri: '/',
      })
      .pipe(take(1))
      .subscribe({
        next: (res) => {
          console.log('Loged out...');
        },
        error: (err) => {},
        complete: () => {
          localStorage.removeItem('currentUser');
        },
      });
  }

  loggedIn(): boolean {
    //  return this.authService.instance.getAllAccounts().length > 0; //tokenNotExpired();
    const ok = tokenNotExpired();
    return ok;
  }

  public requestNewPassword(email: string): Observable<any> {
    const val = {
      email: email,
    };
    return this.http.post<any>(
      this._baseUrl + '/api/authenticate/newpassword',
      val,
      {}
    );
  }

  public resetPassword(token: string, password: string): Observable<any> {
    const val = {
      activation: token,
      password: password,
    };
    return this.http.post<any>(
      this._baseUrl + '/api/authenticate/resetpassword',
      val,
      {}
    );
  }

  public requestWSToken(): Observable<JsonResponse<string>> {

    if( !localStorage.getItem('currentUser')) {
      this.callbackUrl = '/podium';
      this.signUpInt();
    }

    return this.http.get<JsonResponse<string>>(
      this._baseUrl + '/api/authenticate/ws',
      {}
    );
  }

  public authError(): string {
    return ''; //' - ' + this.error + ' (' + tokenNotExpired() + '[' + tokenRoles() + ']' + ')';
  }
}
