import { Component } from '@angular/core';

import { Admin } from '../model';
import { AdminsService } from '../services/admins.service';
import { BaseEntitiesComponent } from '../entity-component/base-entities.component';

export { AdminsListComponent } from './admins-list.component';
export { AdminFormComponent } from './admin-form.component';

@Component( {
    selector: 'app-admins',
    templateUrl: './admins.component.html',
    styleUrls: ['./admins.component.scss']
})
export class AdminsComponent extends BaseEntitiesComponent<Admin> {

    constructor( protected adminService: AdminsService ) {
        super( Admin, adminService );
    }

    override ngOnInit() {
        super.ngOnInit()

        this.sortSettings = {
            multisortmeta: [{ field: 'lastname', order: 1 }]
        }
    }

}
