import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';


import { AuthenticationService } from './authentication.service';

import { User } from '../model';
import { ServiceLocator } from './service.locator';
import { baseURL } from './base-item.service';

@Injectable()
export class UsersService {
    private _baseUrl;

    constructor(
        private http: HttpClient,
        private authenticationService: AuthenticationService ) {
        this._baseUrl = ServiceLocator.injector.get( baseURL );
    }

    getUsers(): Observable<User[]> {
        // add authorization header with jwt token
        let headers = new HttpHeaders( { 'Authorization': 'Bearer ' + this.authenticationService.token } );

        // get users from api
        return this.http.get<User[]>( this._baseUrl + '/api/users', { headers: headers } );
        //            .map((response: Response) => response.json());
    }
}
