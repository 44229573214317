<div fxLayout="row" fxLayoutAlign="center">
    <div fxFlex="0 1 auto">
		<p-panel>
            <p-header>
                <h2>Forgotten password</h2>
            </p-header>
            <form name="form" (ngSubmit)="f1.form.valid && requestNewPass()" #f1="ngForm" novalidate>
                <div class="form-group" [ngClass]="{ 'has-error': f1.submitted && !email.valid }">
                    <label for="email">Enter your email</label>
                    <input type="text" class="form-control" name="email" [(ngModel)]="model.email" #email="ngModel" required />
                    <div *ngIf="f1.submitted && !email.valid" class="help-block">Email is required</div>
                </div>
                <div class="form-group">
                    <button [disabled]="loading || !email.valid" class="btn btn-primary">Submit</button>
                </div>
                <div *ngIf="error" class="alert alert-danger">{{error}}</div>
                <div *ngIf="requestMsg">{{requestMsg}}<br><a [routerLink]="['/login']">Back to login</a></div>
            </form>
        </p-panel>
    </div>
</div>    