
<div class="t-grid t-flex-row t-flex-wrap t-gap-3 t-align-content-end t-justify-content-center">
		<h1 class="title">Brussels Airport TaxiFlow</h1>
<!--
 		<code>
		Device: {{this.deviceInfo.device}}
		<br>
		Info: {{deviceInfo | json}}
		</code>
-->
</div>
