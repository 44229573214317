import { FormGroup } from '@angular/forms';
import { map, takeUntil, tap } from 'rxjs/operators';

import { Observable, Subject, BehaviorSubject, EMPTY } from 'rxjs';

import {
  Keyholder,
  Key,
  AccessRule,
  Entry,
  KeyholderHasKey,
  TfCompany,
  Validity,
} from '../model';
import { EntityFormComponent } from './entity-form.component';
import {
  ItemService,
  KeysService,
  EntriesService,
  TfCompaniesService,
} from '../services';
import { ServiceLocator } from '../services/service.locator';

import { tokenHasTfCompanyId } from '../services/jwt.service';
import { Component, Directive } from '@angular/core';

@Directive()
export abstract class KeyholderFormComponent<
  Item extends Keyholder
> extends EntityFormComponent<Item> {
  public keys: Observable<Array<Key>> = EMPTY;

  protected keysArray: Array<Key> = new Array();
  protected entries: Array<Entry> = new Array();
  protected tfCompanies: Array<TfCompany> = new Array();

  protected keysService: KeysService;
  protected entrieService: EntriesService;
  protected tfCompaniesService: TfCompaniesService;

  constructor(protected override _itemService: ItemService<Item>) {
    super(_itemService);

    this.keysService = ServiceLocator.injector.get(KeysService);
    this.entrieService = ServiceLocator.injector.get(EntriesService);
    this.tfCompaniesService = ServiceLocator.injector.get(TfCompaniesService);
  }

  protected abstract override createForm(): FormGroup;

  override ngOnInit() {
    super.ngOnInit();
    this.keysService.loadItems();
    //        this.entrieService.loadItems();

    this.keys = this.keysService.items.pipe(
      tap((ks) => {
        this.keysArray = this.filterKeysArray(ks);
      }),
      map((keys) => {
        const x = keys.filter((k) => !k.keyholder);
        return x;
      })
    );

    this.entrieService.items
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((es) => {
        setTimeout(() => (this.entries = es));
        if (!es || 0 == es.length) {
          setTimeout(() => {
            this.entrieService.loadItems();
          });
        }
      });

    this.tfCompaniesService.items
      .pipe(takeUntil(this.ngUnsubscribe))
      .pipe(
        map((es) => {
          let tfcId = tokenHasTfCompanyId();
          if (0 == tfcId) return es;

          let newES = es.filter((tfc) => {
            return tfc.id == tfcId;
          });

          return newES;
        })
      )
      .subscribe((es) => {
        setTimeout(() => (this.tfCompanies = es), 0);
        if (!es || 0 == es.length) {
          setTimeout(() => {
            this.tfCompaniesService.loadItems();
          }, 0);
        } else {
          if (
            this.form &&
            this.form.controls['tfCompany'] &&
            this.selectedItem?.tfCompany?.id
          ) {
            setTimeout(() =>
              (this.form.controls['tfCompany'] as FormGroup).controls[
                'id'
              ].setValue(this.selectedItem?.tfCompany?.id)
            );
          }
        }
      });
  }

  protected setKey(x: any) {
    let serial = x['keySerial'];
    let key: Key | undefined = undefined;
    if (serial) {
      if (this.keySerial != serial) {
        key = this.findKeyBySerial(serial); //this.keysArray.find( k => k.serialnr === serial );
        if (key) {
          let khhk = new KeyholderHasKey();
          khhk.key = key;
          khhk.start = new Date();
          x.keys = [khhk];
        } else {
          x.keys = [];
          this.form.controls['keySerial'].setErrors({
            error: 'Invalid key serial',
          });
        }
      }
    } else {
      x.keys = [];
    }

    delete x['keySerial'];
    return x;
  }

  protected abstract filterKeysArray(keys: Array<Key>): Array<Key>;
  protected abstract findKeyBySerial(serial: string): Key | undefined;

  protected setAccessRule(x: any, prop: string, entryName: string) {
    if (x[prop]) {
      if (!(this as any)[prop]) {
        x = this.addAccessRule(x, entryName);
      }
    } else {
      if ((this as any)[prop]) {
        x = this.removeAccessRule(x, entryName);
      }
    }
    delete x[prop];
    return x;
  }
  protected addAccessRule(x: any, entryName: string) {
    let entry = this.entries.find((e) => e.name === entryName);
    if (entry) {
      let ar = new AccessRule();
      ar.name = x.licensePlate + ' - ' + entry.name + ' - (null)';
      ar.disabled = false;
      ar.entry = entry;
      x.accessRules = [ar, ...(x.accessRules ? x.accessRules : [])];
    }
    return x;
  }

  protected removeAccessRule(x: any, entryName: string) {
    x.accessRules = x.accessRules.filter(
      (ar: any) => ar.entry.name !== entryName
    );
    return x;
  }

  public get pickup(): boolean {
    let l = this.selectedItem?.accessRules
      ? this.selectedItem.accessRules.find((ar) => ar.entry?.name == 'PICKUP')
      : false;
    return !!l;
  }

  public get dropoff(): boolean {
    let l = this.selectedItem?.accessRules
      ? this.selectedItem.accessRules.find((ar) => ar.entry?.name == 'DROPOFF')
      : false;
    return !!l;
  }

  public get p12p13(): boolean {
    let l = this.selectedItem?.accessRules
      ? this.selectedItem.accessRules.find((ar) => ar.entry?.name == 'P12P13')
      : false;
    return !!l;
  }

  public get keySerial(): string {
    let l =
      this.selectedItem?.keys &&
      this.selectedItem.keys[0] &&
      this.selectedItem.keys[0].key
        ? this.selectedItem.keys[0].key.serialnr
        : '';
    return l || '';
  }

  public get companyName(): string {
    let l = this.selectedItem?.tfCompany
      ? this.selectedItem.tfCompany.name
      : '';
    return l || '';
  }

  protected availableKeys(): Observable<Key[]> {
    let keysObs = this.keys.pipe(
      map((keys) => {
        let ks = keys.slice();
        if (this.selectedItem && this.selectedItem.keys) {
          ks = ks.concat(
            this.selectedItem.keys
              .filter((kh) => !!kh)
              .filter((kh) => !kh.finish)
              .filter((kh) => kh.key)
              .map((kh) => kh.key!) || []
          );
        }
        return ks;
      })
    );
    return keysObs;
  }

  protected filterKeys(serial: string): Observable<Array<Key>> {
    return this.availableKeys().pipe(
      map((keys) => {
        return keys.filter((key) =>
          new RegExp(`^${serial}`, 'gi').test(key.serialnr || '')
        );
      })
    );
  }

  protected filterKeysExact(serial: string): Observable<Array<Key>> {
    return this.availableKeys().pipe(
      map((keys) => {
        return keys.filter((key) =>
          new RegExp(`^${serial}$`, 'g').test(key.serialnr || '')
        );
      })
    );
  }

  public get blacklisting(): any {
    let l = this.selectedItem?.['validity'];
    let x =
      !l || l.valid
        ? {
            blacklisted: false,
            blacklistingStart: new Date(),
            blacklistingEnd: new Date(),
          }
        : {
            blacklisted: true,
            blacklistingStart: l.validityStart,
            blacklistingEnd: l.validityEnd,
          };

    return x;
  }

  protected setValidity(x: any) {
    if (!x.blacklisting.blacklisted) {
      delete x['validity'];
      delete x['blacklisting'];
      return x;
    }
    if (!x.validity) {
      x.validity = new Validity();
    }
    x.validity.valid = false;
    x.validity.validityStart = x.blacklisting.blacklistingStart;
    x.validity.validityEnd = x.blacklisting.blacklistingEnd;
    delete x.validity['id'];
    delete x['blacklisting'];
    return x;
  }
}
