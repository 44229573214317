import {
  combineLatest as observableCombineLatest,
  EMPTY,
  Observable,
} from 'rxjs';

import { startWith, map } from 'rxjs/operators';
import { Injectable, InjectionToken, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { ServiceLocator } from '../services/service.locator';
import { dateReviver } from '../utils/utils';

import { Store, Action } from '@ngrx/store';
import { Filter, STORE_ACTIONS, StoreAction } from '../store/tayarac-store';

import { WebSocketService, wsBaseURL } from './web-socket-service.service';
//import { AuthHttp } from './jwt.service';
import { JsonResponse, Node, Validity } from '../model';
import { AuthenticationService } from './authentication.service';

export let baseURL = new InjectionToken<string>('baseURL');
export let TayaracCompany = new InjectionToken<string>('TayaracCompany');

export abstract class BaseItemService<Item> {
  //    protected authHttp: AuthHttp;
  protected httpClient: HttpClient;
  protected store: Store<any>;
  protected _baseUrl: string;
  protected _wsBaseUrl: string;
  protected wsService: WebSocketService;

  private _filteredItems?: Observable<Array<Item>>;
  private _errorMsg?: Observable<string[]>;

  constructor() {
    //        this.authHttp = ServiceLocator.injector.get( AuthHttp );
    this.httpClient = ServiceLocator.injector.get(HttpClient);
    this.store = ServiceLocator.injector.get(Store);
    this._baseUrl = ServiceLocator.injector.get(baseURL);
    this._wsBaseUrl = ServiceLocator.injector.get(wsBaseURL);

    let authSvc = ServiceLocator.injector.get(AuthenticationService);
    this.wsService = new WebSocketService(authSvc);
    this.wsService.connect(this._wsBaseUrl);

    this.init();
  }

  public abstract loadItems(): void;
  public abstract selectItem(item: Item): void;
  protected abstract createItem(item: Item): Observable<Item>;
  protected abstract updateItem(item: Item): Observable<Item>;
  public abstract deleteItem(item: Item): void;
  public abstract setFilter(filters: any): void;

  protected abstract get baseUrl(): string;

  public abstract get items(): Observable<Array<Item>>;
  public abstract get selectedItem(): Observable<Item>;
  public abstract get filter(): Observable<Array<Filter>>;

  protected init() {}

  public get filteredItems(): Observable<Array<Item>> {
    if (!this._filteredItems) {
      this._filteredItems = observableCombineLatest([
        this.items,
        this.filter,
      ]).pipe(
        map(([items, filters]) => {
          return this.filterItems(items, filters);
        }),
        map((items) => this.sanitizeItems(items))
      );
    }

    return this._filteredItems;
  }

  public get errorMsg(): Observable<string[]> {
    if (!this._errorMsg) {
      this._errorMsg = this.store.select('errorMsg').pipe(
        startWith([]),
        map((o) => {
          return o.map((x: any) => {
            if ('error' in x) {
              if (typeof x.error == 'string') {
                return x.error;
              } else if ('errorMsg' in x.error) {
                return x.error.errorMsg;
              }
            } else if ('message' in x) return x.message;
            else return x;
          });
        }),
        map((o) => o as string[])
      );
    }

    return this._errorMsg;
  }

  public saveItem(key: Item): Observable<Item> {
    this.clearErrors();
    return this.getIdentity(key) ? this.updateItem(key) : this.createItem(key);
  }

  public abstract getIdentity(item: Item): number | string | undefined;

  public clearFilter() {
    this.setFilter([]);
  }

  public setErrors(errors: string[]): void {
    this.store.dispatch({ type: STORE_ACTIONS.SET_ERRORS, payload: errors });
  }

  public clearErrors() {
    this.store.dispatch({ type: STORE_ACTIONS.CLEAR_ERRORS });
  }

  public search(filter: Filter[]): Observable<Item[]> {
    let params: HttpParams = new HttpParams()
      .set('start', '-1')
      .set('max', '-1');

    return this.httpClient
      .post<JsonResponse<Item[]>>(this.baseUrl + '/search', filter, {
        params: params,
      })
      .pipe(
        //JSON.stringify( filter ), { 'search': params })
        map((jr) => {
          console.log('Data: ', jr);
          if (jr.status != 'SUCCESS') throw { errorMsg: jr.errorMsg };
          return jr.data as Item[];
        })
      );
  }

  public searchCount(filter: Filter[]): Observable<{ count: number }> {
    let params: HttpParams = new HttpParams()
      .set('start', '-1')
      .set('max', '-1');

    return this.httpClient
      .post<JsonResponse<Object>>(this.baseUrl + '/search-count', filter, {
        params: params,
      })
      .pipe(
        map((jr) => {
          console.log('Data: ', jr);
          if (jr.status != 'SUCCESS') throw { errorMsg: jr.errorMsg };
          return jr.data as { count: number };
        })
      );
  }

  protected _getItems(): Observable<Item[]> {
    let params: HttpParams = new HttpParams()
      .set('start', '-1')
      .set('max', '-1');

    return this.httpClient
      .get<JsonResponse<Item[]>>(this.baseUrl, { params: params })
      .pipe(
        map((jr) => {
          console.log('Items data: ', jr);
          if (jr.status != 'SUCCESS') throw { errorMsg: jr.errorMsg };
          return jr.data as Item[];
        })
      );
  }

  protected _getItem(id: number | string): Observable<Item> {
    return this.httpClient
      .get<JsonResponse<Item>>(this.baseUrl + '/' + id, {})
      .pipe(
        map((jr) => {
          console.log('Item data: ', jr);
          if (jr.status != 'SUCCESS') throw { errorMsg: jr.errorMsg };
          return jr.data as Item;
        })
      );
  }

  protected _addItem(item: Item): Observable<Item> {
    return this.httpClient
      .post<JsonResponse<Item>>(this.baseUrl, item, {})
      .pipe(
        map((jr) => {
          console.log('Added data: ', jr);
          if (jr.status != 'SUCCESS') throw { errorMsg: jr.errorMsg };
          return jr.data as Item;
        })
      );
  }

  protected _updateItem(item: Item): Observable<Item> {
    return this.httpClient
      .put<JsonResponse<Item>>(
        this.baseUrl + '/' + this.getIdentity(item),
        item,
        {}
      )
      .pipe(
        map((jr) => {
          console.log('Updated data: ', jr);
          if (jr.status != 'SUCCESS') throw { errorMsg: jr.errorMsg };
          return jr.data as Item;
        })
      );
  }

  protected _deleteItem(id: number | string | undefined): Observable<any> {
    if (id) {
      return this.httpClient
        .delete<JsonResponse<Object>>(this.baseUrl + '/' + id, {})
        .pipe(
          map((jr) => {
            console.log('Deleted data: ', jr);
            if (jr.status != 'SUCCESS') throw { errorMsg: jr.errorMsg };
            return jr.data;
          })
        );
    }

    return EMPTY;
  }

  protected filterItems(items: Item[], filters: Filter[]): Item[] {
    let resk = filters
      .filter((f) => f.value)
      .reduce(function (its, f): Item[] {
        return its
          .map((ks) => {
            let path = f.field.split('.');
            let v: any = ks;
            for (let s of path) {
              v = v[s];
              if (!v) return null;
            }
            return { ks, v };
          })
          .filter((ks) => !!ks)
          .filter(
            (ks) => ks!.v.toLowerCase().indexOf(f.value.toLowerCase()) != -1
          )
          .map((ks) => ks!.ks);
      }, items);

    return resk;
  }

  // Checks if all items have Validity, etc
  protected sanitizeItems(items: Item[]): Item[] {
    //        for ( let item of items ) {
    //            if ( !item.validity )
    //                item.validity = new Validity();
    //        }
    return items;
  }
}
