import {interval as observableInterval, Observable, Subject, BehaviorSubject, EMPTY, of, defer} from 'rxjs';

import {filter, takeUntil, delay, repeat, tap, timeout, map, share, distinctUntilChanged, retry, repeatWhen, retryWhen} from 'rxjs/operators';
import {Component, OnInit, OnDestroy, ViewEncapsulation} from '@angular/core';
import {ServiceLocator} from '../services/service.locator';
import {Router} from '@angular/router';
import {Store} from '@ngrx/store';
import {STORE_ACTIONS} from '../store/tayarac-store';

import {WebSocketService, wsBaseURL, wsTimeout} from '../services/web-socket-service.service';
import {dateReviver} from '../utils/utils';
import {tokenHasRole} from '../services/jwt.service';

import {
  Airport, Car, CarsPerArea, AreasType, Features, SpecialCarRequest, BoardArea
} from './model.boards';
import {AuthenticationService} from '../services';

export class MultiFeatures {
  TAXI = false;
  BREAK = false;
  MINIVAN = false;
  TWALTER = false;
  UNITAX = false;
  AUTOTAX = false;
}

type MultiFeaturesKeys = keyof MultiFeatures;

export let CAR_TYPES: MultiFeaturesKeys[] = [
  'BREAK',
  'MINIVAN',
  'TWALTER',
  'UNITAX',
  'AUTOTAX',
];


@Component({
  selector: 'app-brigadier',
  templateUrl: './brigadier.component.html',
  styleUrls: ['./brigadier.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class BrigadierComponent implements OnInit, OnDestroy {

  public ap: Observable<Airport> = EMPTY;
  public totalCars: Observable<number> = EMPTY;
  public areas: Observable<AreasType> = EMPTY;

  public allFeatures: Observable<Features> = EMPTY;
  public features: Observable<CarsPerArea[]> = EMPTY;

  public carTypes: MultiFeaturesKeys[] = CAR_TYPES;

  protected requests: BehaviorSubject<SpecialCarRequest[]> = new BehaviorSubject<SpecialCarRequest[]>([]);

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  protected wsService: WebSocketService;
  protected wsBaseUrl: string;
  protected wsTimeout: number = 5000;

  protected store: Store<any>;
  private _brigadierOnline: Observable<boolean> = EMPTY;

  private _brigadier = 0;
  private _podium = true;

  private router: Router;

  public multiFeature: MultiFeatures = new MultiFeatures();
  public multiSelect = false;

  public express = false;
  public recovery = false;

  constructor() {

    this.router = ServiceLocator.injector.get(Router);
    this.wsBaseUrl = ServiceLocator.injector.get(wsBaseURL);
    this.wsTimeout = ServiceLocator.injector.get(wsTimeout);
    this.store = ServiceLocator.injector.get(Store);

    let authSvc = ServiceLocator.injector.get(AuthenticationService);
    this.wsService = new WebSocketService(authSvc); //
  }

  ngOnInit() {
    if (this.router.url !== '/podium') {
      this._podium = false;
    }

    let ws = this.wsService.connect(this.wsBaseUrl, e => {
      this.store.dispatch({type: STORE_ACTIONS.BRIGADIER_ONLINE, payload: true});
      if (tokenHasRole('Brigadier') && !this._podium) {
        this.wsService.send({action: 'WHICH_BRIGADIER', payload: {}});
      }
    }).pipe(
      share(),
      map(d => {
        let c = JSON.parse(d.data, dateReviver);
        return c;
      }),
      // Add ping check
      timeout(this.wsTimeout),
      filter(c => {
        // Filter empty or pings
        return !!c && !('ping' in c)
      }),
      tap({
        next: d => {
        }, error: e => {
          this.store.dispatch({type: STORE_ACTIONS.BRIGADIER_ONLINE, payload: false});
        }, complete: () => {
          this.store.dispatch({type: STORE_ACTIONS.BRIGADIER_ONLINE, payload: false});
        }
      }),
      retry({
        delay: _ => {
          return of(0).pipe(delay(5000));
        }
      }),
      repeat({
        delay: count => {
          return of(count).pipe(delay(5000));
        }
      }),
      share(),);

    this.ap = ws.pipe(filter(c => {
      return !!c.airport;
    }), map(c => {
      return c.airport;
    }),);

    ws.pipe(filter(c => {
      return !this._podium && !!c.brigadier;
    }), takeUntil(this.ngUnsubscribe),).subscribe(c => {
      this._brigadier = c.brigadier;
    });

    this.areas = this.ap.pipe(map(a => {
      return a.areas;
    }), map(areas => areas.filter(area => {
      return area.name !== 'OFFSITE' && area.name !== 'T0P12P13';
    })), map(areas => {
      let x = new AreasType();
      areas.forEach(area => (x as any)[area.name] = area);
      return x;
    }),);

    this.totalCars = this.areas.pipe(map(areas => {
      let x = Object.keys(areas).map(key => (areas as any)[key].occupancy).reduce((i, c) => i + c, 0);
      return x;
    }));

    this.allFeatures = this.areas.pipe(map(areas => {
      let f: Features = new Features();
      Object.keys(areas).forEach(key => {
        let area: BoardArea = (areas as any)[key];
        area.cars.forEach((car: Car) => {
          car.carFeatures.filter((cf: string) => cf !== 'PRM').forEach((feat: string) => (f as any)[feat][area.name].push(car));
          if (car.carFeatures.indexOf('BREAK') === -1 && car.carFeatures.indexOf('MINIVAN') === -1 &&
            (car.carFeatures.indexOf('TWALTER') !== -1 || car.carFeatures.indexOf('UNITAX') !== -1 || car.carFeatures.indexOf('AUTOTAX') !== -1)) {
            (f as any)['TAXI'][area.name].push(car);
          }
        });
      });
      return f;
    }));

    this.features = this.allFeatures.pipe(
      map(f => {
        let res: CarsPerArea[] = [];
        this.carTypes.forEach(ctype => res.push(f[ctype]));
        return res;
      }));

    // Requests
    this.ap.pipe(filter(a => !!a.requests), map(a => a.requests), distinctUntilChanged(),
      map(reqs => {
        // Order by time
        return reqs.sort((a, b) => (a.requestCreated?.getTime() || 0) < (b.requestCreated?.getTime() || 0) ? 1 : -1);
      }),
      takeUntil(this.ngUnsubscribe),).subscribe(this.requests);

    this.ap.pipe(map(ap => ap.expressMode), distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),).subscribe(express => this.express = express);

    this.ap.pipe(map(ap => ap.recoveryMode), distinctUntilChanged(),
      takeUntil(this.ngUnsubscribe),).subscribe(recovery => this.recovery = recovery);

    // Refresh the page once every half an hour
    observableInterval(30 * 60 * 1000).pipe(takeUntil(this.ngUnsubscribe)).subscribe(_d => {
      if (window && window.location)
        window.location.reload();
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public get brigadierOnline(): Observable<boolean> {
    if (!this._brigadierOnline) {
      this._brigadierOnline = this.store.select('brigadierOnline');
    }

    return this._brigadierOnline;
  }

  public requestCar(feature: string) {
    this.wsService.send({action: 'CALL_FEATURE', payload: {brigadier: this.brigadier, features: [feature], count: 1}});
  }

  public releaseCar(feature: string, car: Car) {
    this.wsService.send({action: 'UNCALL_FEATURE', payload: {brigadier: this.brigadier, features: [feature], carRFID: car.rfID, count: 1}});
  }

  public get brigadier() {
    return this._brigadier;
  }

  public showMultiSelect(event: any) {
    this.cleanMultiFeatures();
    this.multiSelect = !this.multiSelect;
  }

  public confirmMultiSelect(event: any) {
    if (!this.multiSelect) {
      return;
    }

    let features = this.selectedFeatures;

    if (features.length > 0) {
      this.wsService.send({action: 'CALL_FEATURE', payload: {brigadier: this._brigadier, features: features, count: 1}});
      this.multiSelect = false;
      this.cleanMultiFeatures();
    }
  }

  public uncallRequest(event: any, req: SpecialCarRequest) {
    if (!req)
      return;

    this.wsService.send({action: 'UNCALL_FEATURE', payload: {requestId: req.requestId, brigadier: req.brigadier, features: req.featureRequested, carRFID: null, count: 1}});
  }

  public get selectedFeatures(): MultiFeaturesKeys[] {
    let features = CAR_TYPES.reduce((req, f) => {
      return (this.multiFeature[f]) ? [...req, f] : req;
    }, [] as MultiFeaturesKeys[]);

    return features;
  }

  public cancelMultiSelect(event: any) {
    this.multiSelect = false;
    this.cleanMultiFeatures();
  }

  private cleanMultiFeatures() {
    CAR_TYPES.forEach(feat => this.multiFeature[feat] = false);
  }

  public toggleFeature(event: any, feature: keyof MultiFeatures) {
    if (['BREAK', 'MINIVAN'].indexOf(feature) !== -1) {
      let tmp = this.multiFeature[feature];
      this.multiFeature.BREAK = false;
      this.multiFeature.MINIVAN = false;
      this.multiFeature[feature] = !tmp;
    }
    if (['TWALTER', 'UNITAX', 'AUTOTAX'].indexOf(feature) !== -1) {
      let tmp = this.multiFeature[feature];
      this.multiFeature.TWALTER = false;
      this.multiFeature.UNITAX = false;
      this.multiFeature.AUTOTAX = false;
      this.multiFeature[feature] = !tmp;
    }
  }

  public hasFeatures(f: Features | null, type: keyof Features, area: keyof CarsPerArea): number {
    if (!f)
      return 0;

    let typePerArea: Car[] = !!type && !!f && !!f[type] ? f[type][area] : (new CarsPerArea())[area];
    let compPerArea: Car[] = f['TWALTER'][area];
    let res = typePerArea.filter(x => -1 < compPerArea.indexOf(x)).length > 0 ? 0x1 : 0;
    let noCompCars = typePerArea.filter(x => -1 === compPerArea.indexOf(x));

    compPerArea = f['UNITAX'][area];
    res |= typePerArea.filter(x => -1 < compPerArea.indexOf(x)).length > 0 ? 0x10 : 0;
    noCompCars = noCompCars.filter(x => -1 === compPerArea.indexOf(x));

    compPerArea = f['AUTOTAX'][area];
    res |= typePerArea.filter(x => -1 < compPerArea.indexOf(x)).length > 0 ? 0x100 : 0;
    noCompCars = noCompCars.filter(x => -1 === compPerArea.indexOf(x));

    res |= noCompCars.length > 0 ? 0x1000 : 0;

    return res;
  }

  public getAllCars(f: Features | null, type: keyof Features, areas: (keyof CarsPerArea)[]): Car[] {
    if (!f || !type || !f[type])
      return [];

    let typePerArea = areas.reduce((tpa, a) => tpa.concat(f[type][a]), [] as Car[]);
    return typePerArea;
  }

  public toggleExpress() {
    if (this.express) {
      alert("You cannot deactivate the Express mode with this button. This will happen automatically");
      return;
    }

    let msg = 'Do you really want to launch the express procedure?';
    if (confirm(msg)) {
      this.wsService.send({action: 'TOGGLE_EXPRESS', payload: {}});
    }
  }
}
