import { Routes, RouterModule } from '@angular/router';

// Import our components
import { DashboardComponent } from './dashboard/dashboard.component';
import { OverviewComponent } from './dashboard/overview.component';
import { BrigadierComponent } from './dashboard/brigadier.component';
import { TestboardComponent } from './dashboard/testboard.component';
import { TransitionsComponent } from './transitions/transitions.component';
import { ApocComponent } from './apoc/apoc.component';
import { DiagnosticComponent } from './diagnostic/diagnostic.component';
import { LocksComponent } from './locks/locks.component';
import { KeysComponent } from './keys/keys.component';
import { PersonsComponent } from './persons/persons.component';
import { AdminsComponent } from './admins/admins.component';
import { TfCompaniesComponent } from './tfcompanies/tfcompanies.component';
import { VehiclesComponent } from './vehicles/vehicles.component';
import { AreasComponent } from './areas/areas.component';
import { LoginComponent } from './login/login.component';
import { SettingsComponent } from './settings/settings.component';
import { MobilePodiumComponent } from './mobile-podium/mobile-podium.component';
import { MobileTransitionsComponent } from './mobile-transitions/mobile-transitions.component';
import { ForgottenPasswordComponent } from './login/forgotten-password.component';


import { ResetPasswordComponent } from './login/reset-password.component';
import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { AuthGuard } from './services';

const routes: Routes = [
    // Add the redirect
    // { path: 'login/forgotten', component: ForgottenPasswordComponent },
    // { path: 'login/reset/:token', component: ResetPasswordComponent },
    { path: 'login', component: LoginComponent, canActivate: [MsalGuard] },
    { path: '', redirectTo: '/dashboard', pathMatch: 'full' },
    // Add our routes
    { path: 'dashboard', component: DashboardComponent },
    { path: 'overview', component: OverviewComponent, canActivate: [AuthGuard] },
    { path: 'apoc', component: ApocComponent, canActivate: [AuthGuard] },
    { path: 'diagnostic', component: DiagnosticComponent, canActivate: [AuthGuard] },
    { path: 'podium', component: BrigadierComponent },
    { path: 'mpodium', component: MobilePodiumComponent },
    { path: 'brigadier', component: BrigadierComponent, canActivate: [AuthGuard] },
    { path: 'debug', component: TestboardComponent, canActivate: [AuthGuard] },
    { path: 'transitions', component: TransitionsComponent, canActivate: [AuthGuard] },
    { path: 'mtransitions', component: MobileTransitionsComponent, canActivate: [AuthGuard] },
    { path: 'keys', component: KeysComponent, canActivate: [AuthGuard] },
    { path: 'locks', component: LocksComponent, canActivate: [AuthGuard] },
    { path: 'persons', component: PersonsComponent, canActivate: [AuthGuard] },
    { path: 'companies', component: TfCompaniesComponent, canActivate: [AuthGuard] },
    { path: 'vehicles', component: VehiclesComponent, canActivate: [AuthGuard] },
    { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
    { path: 'admins', component: AdminsComponent, canActivate: [AuthGuard] },
    { path: 'areas', component: AreasComponent, canActivate: [AuthGuard] },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

