import { switchMap, first, map, find } from 'rxjs/operators';
import { Component, Directive, Inject, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Observable } from 'rxjs';

import { ServiceLocator } from '../services/service.locator';

import { BaseItemService } from '../services/base-item.service';

@Directive()
export class BaseEntitiesComponent<Item> implements OnInit {
  protected items: Observable<Array<Item>>;
  protected selectedItem: Observable<Item>;
  protected errorMsg: Observable<Array<string>>;

  protected route: ActivatedRoute;
  protected router: Router;

  public editing = false;

  public sortSettings?: any;
  public _paging: { first: number; rows: number } = { first: 0, rows: 10 };

  constructor(
    protected ctor: { new (): Item },
    protected itemService: BaseItemService<Item>
  ) {
    this.route = ServiceLocator.injector.get(ActivatedRoute);
    this.router = ServiceLocator.injector.get(Router);

    this.items = this.itemService.filteredItems;
    this.selectedItem = this.itemService.selectedItem;
    this.errorMsg = this.itemService.errorMsg;
  }

  public get paging(): { first: number; rows: number } {
    return this._paging;
  }
  public set paging(value: { first: number; rows: number }) {
    this._paging = value;
  }

  ngOnInit() {
    this.itemService.loadItems();

    this.route.params
      .pipe(
        map((params: Params) => params['id']),
        find((id, idx) => !!id)
      )
      .subscribe((id) => {
        if (id) {
          this.items
            .pipe(
              first((keys) => keys.length > 0),
              switchMap((keys) => keys),
              find((k, idx) => this.itemService.getIdentity(k) === id)
            )
            .subscribe((key) => {
              if (key) {
                this.itemSelected(key);
              } else {
                this.router.navigateByUrl('/');
              }
            });
        } else {
          this.router.navigateByUrl('/');
        }
      });
  }

  public itemSelected(item: Item) {
    this.itemService.selectItem(item);
    this.editing = true;
  }

  public newItem() {
    this.itemService.selectItem(new this.ctor());
    this.editing = true;
  }

  public editCancel() {
    this.editing = false;
    // this.itemService.selectItem( null );
  }

  public saveItem(item: any) {
    this.itemService.saveItem(item).subscribe({
      next: (i) => {
        this.editCancel();
      },
      error: (err) => {
        console.error('Error saving item: ', err);
        this.itemService.setErrors([err]);
      }
  });
  }

  public deleteItem(item: Item) {
    this.itemService.deleteItem(item);
  }

  public setFilter(filters: any) {
    this.itemService.setFilter(filters);
  }

  public saveSortSettings(event: any) {
    this.sortSettings = event;
  }

  public setFocus(el: any) {
    if (el !== undefined) {
      setTimeout(() => {
        el.focus();
      }, 0);
    }
  }
}
