import { catchError, tap, mergeMap, map } from 'rxjs/operators';

import { throwError as observableThrowError, Observable } from 'rxjs';
import {
  HttpClient,
  HttpClientModule,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse,
  HttpHeaders,
  HttpEvent,
  HttpEventType,
  HttpInterceptor,
  HttpHandler,
  HTTP_INTERCEPTORS,
} from '@angular/common/http';
import { Router } from '@angular/router';

import {
  Injectable,
  Provider,
  NgModule,
  Optional,
  SkipSelf,
  ModuleWithProviders,
} from '@angular/core';
import { clone } from '../utils/utils';

export interface IAuthConfig {
  globalHeaders: Array<Object>;
  headerName: string;
  headerPrefix: string;
  noJwtError: boolean;
  noClientCheck: boolean;
  noTokenScheme?: boolean;
  tokenGetter: () => string | Observable<string>;
  loginUrl: string;
  currentUserName: string;
  jwtHeaderName: string;
}

export interface IAuthConfigOptional {
  headerName?: string;
  headerPrefix?: string;
  loginUrl?: string;
  tokenGetter?: () => string | Observable<string>;
  noJwtError?: boolean;
  noClientCheck?: boolean;
  globalHeaders?: Array<Object>;
  noTokenScheme?: boolean;
  currentUserName?: string;
  jwtHeaderName?: string;
}

export class AuthConfigConsts {
  public static CURRENT_USER_NAME = 'currentUser';
  public static JWT_HEADER_NAME = 'tayarac-jwt';
  public static DEFAULT_HEADER_NAME = 'Authorization';
  public static HEADER_PREFIX_BEARER = 'Bearer ';
  public static DEFAULT_LOGIN_URL = '/login';
}

const AuthConfigDefaults: IAuthConfig = {
  headerName: AuthConfigConsts.DEFAULT_HEADER_NAME,
  headerPrefix: '',
  loginUrl: AuthConfigConsts.DEFAULT_LOGIN_URL,
  tokenGetter: getToken, // () => localStorage.getItem( AuthConfigDefaults.tokenName ) as string,
  noJwtError: false,
  noClientCheck: false,
  globalHeaders: [],
  noTokenScheme: false,
  currentUserName: AuthConfigConsts.CURRENT_USER_NAME,
  jwtHeaderName: AuthConfigConsts.JWT_HEADER_NAME,
};

type RequestOptionsArgs = any;
type RequestOptions = any;

enum RequestMethod {
  Get = 'GET',
  Post = 'POST',
  Patch = 'PATCH',
  Put = 'PUT',
  Head = 'HEAD',
  Delete = 'DELETE',
  Options = 'OPTIONS',
}

/**
 * Sets up the authentication configuration.
 */

export class AuthConfig {
  private _config: IAuthConfig;

  constructor(config?: IAuthConfigOptional) {
    config = config || {};
    this._config = objectAssign({}, AuthConfigDefaults, config);
    if (this._config.headerPrefix) {
      this._config.headerPrefix += ' ';
    } else if (this._config.noTokenScheme) {
      this._config.headerPrefix = '';
    } else {
      this._config.headerPrefix = AuthConfigConsts.HEADER_PREFIX_BEARER;
    }

    if (!config.tokenGetter) {
      this._config.tokenGetter = getToken; // () => localStorage.getItem( config.tokenName ) as string;
    }
  }

  public getConfig(): IAuthConfig {
    return this._config;
  }
}
/*
export class AuthHttpError extends Error {
}
*/

/**
 * Allows for explicit authenticated HTTP requests.
 */
/*
@Injectable()
export class AuthHttp {

    private config: IAuthConfig;
    public tokenStream: Observable<string>;

    constructor( options: AuthConfig, private http: HttpClient, private router: Router, private defOpts?: RequestOptions ) {
        this.config = options.getConfig();

        this.tokenStream = new Observable<string>( ( obs: any ) => {
            obs.next( this.config.tokenGetter() );
        } );
    }

    private mergeOptions( providedOpts: RequestOptionsArgs, defaultOpts?: RequestOptions ) {
        let newOptions = defaultOpts || {};
        if ( this.config.globalHeaders ) {
            this.setGlobalHeaders( this.config.globalHeaders, providedOpts );
        }

        newOptions = Object.assign( {}, newOptions, providedOpts );

        return newOptions;
    }

    private requestHelper<T, R>( method: string, url: string, body: T | null, requestArgs: RequestOptionsArgs, additionalOptions?: RequestOptionsArgs ): Observable<R> {
        let options = requestArgs;
        if ( additionalOptions ) {
            options = Object.assign( {}, options, additionalOptions );
        }
        return this.request( new HttpRequest<T>( method, url, body, this.mergeOptions( options, this.defOpts ) ) )
            .map( ( res: R ) => {
                res = clone( res );
                return res;
            } );
    }

    private requestWithToken<T, R>( req: HttpRequest<T>, token: string ): Observable<HttpResponse<R>> {
        if ( !this.config.noClientCheck && !tokenNotExpired( token ) ) {
            if ( !this.config.noJwtError ) {
                this.router.navigate( [this.config.loginUrl] );
                return Observable.throw( new AuthHttpError( 'No JWT present or has expired' ) );
            }
        } else {
            //req.headers.set( this.config.headerName, this.config.headerPrefix + token );
            let authHeader = {};
            (authHeader as any)[this.config.headerName] = this.config.headerPrefix + token;
            req = req.clone( { setHeaders: authHeader } );
        }

        return this.http.request<R>( req )
            .filter( ( ev: HttpEvent<R> ) => {
                return ev.type === HttpEventType.Response;
            } )
            .do( ( res: HttpResponse<R> ) => {
                if ( res.headers.has( this.config.jwtHeaderName ) ) {
                    let user = JSON.parse( localStorage.getItem( this.config.currentUserName ) ) || {};
                    user.token = res.headers.get( this.config.jwtHeaderName );
                    localStorage.setItem( this.config.currentUserName, JSON.stringify( user ) );
                }
            } )
            .catch( ( res: HttpErrorResponse ) => {
                if ( 401 === res.status || 403 === res.status ) {
                    this.router.navigate( [this.config.loginUrl] );
                } else if ( res.headers.has( this.config.jwtHeaderName ) ) {
                    let user = JSON.parse( localStorage.getItem( this.config.currentUserName ) ) || {};
                    user.token = res.headers.get( this.config.jwtHeaderName );
                    localStorage.setItem( this.config.currentUserName, JSON.stringify( user ) );
                }
                return Observable.throw( res );
            } );
    }

    public setGlobalHeaders( headers: Array<Object>, request: HttpRequest<any> | RequestOptionsArgs ) {
        if ( !request.headers ) {
            request.headers = new HttpHeaders();
        }
        headers.forEach( ( header: Object ) => {
            let key: string = Object.keys( header )[0];
            let headerValue: string = ( header as any )[key];
            ( request.headers as Headers ).set( key, headerValue );
        } );
    }

    private request<T, R>( url: string | HttpRequest<T>, options?: RequestOptionsArgs ): Observable<R> {
        if ( typeof url === 'string' ) {
            return this.get<HttpResponse<R>>( url, options ).map( resp => resp.body ); // Recursion: transform url from String to HttpRequest
        }
        // else if ( ! url instanceof HttpRequest ) {
        //   throw new Error('First argument must be a url string or HttpRequest instance.');
        // }

        // from this point url is always an instance of HttpRequest;
        let req: HttpRequest<T> = url as HttpRequest<T>;
        let token: string | Observable<string> = this.config.tokenGetter();
        if ( token instanceof Observable ) {
            return token.mergeMap( ( jwtToken: string ) => this.requestWithToken<T, R>( req, jwtToken ) ).map( resp => resp.body );
        } else {
            return this.requestWithToken<T, R>( req, token ).map( (resp:any) => resp.body );
        }
    }

    public get<R>( url: string, options?: RequestOptionsArgs ): Observable<R> {
        return this.requestHelper<any, R>( RequestMethod.Get, url, null, options );// .map( resp => resp.body );
    }

    public post<T, R>( url: string, body: T, options?: RequestOptionsArgs ): Observable<R> {
        return this.requestHelper<T, R>( RequestMethod.Post, url, body, options );// .map( resp => resp.body );
    }

    public put<T, R>( url: string, body: T, options?: RequestOptionsArgs ): Observable<R> {
        return this.requestHelper<T, R>( RequestMethod.Put, url, body, options );// .map( resp => resp.body );
    }

    public delete<R>( url: string, options?: RequestOptionsArgs ): Observable<R> {
        return this.requestHelper<any, R>( RequestMethod.Delete, url, null, options );// .map( resp => resp.body );
    }

    public patch<T, R>( url: string, body: T, options?: RequestOptionsArgs ): Observable<R> {
        return this.requestHelper<T, R>( RequestMethod.Patch, url, body, options );// .map( resp => resp.body );
    }

    public head<R>( url: string, options?: RequestOptionsArgs ): Observable<R> {
        return this.requestHelper<any, R>( RequestMethod.Head, url, null, options );// .map( resp => resp.body );
    }

    public options<R>( url: string, options?: RequestOptionsArgs ): Observable<R> {
        return this.requestHelper<any, R>( RequestMethod.Options, url, null, options );// .map( resp => resp.body );
    }

}
*/

/**
 * Helper class to decode and find JWT expiration.
 */

export class JwtHelper {
  public urlBase64Decode(str: string): string {
    let output = str.replace(/-/g, '+').replace(/_/g, '/');
    switch (output.length % 4) {
      case 0: {
        break;
      }
      case 2: {
        output += '==';
        break;
      }
      case 3: {
        output += '=';
        break;
      }
      default: {
        throw 'Illegal base64url string!';
      }
    }
    return this.b64DecodeUnicode(output);
  }

  // credits for decoder goes to https://github.com/atk
  private b64decode(str: string): string {
    let chars =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
    let output = '';

    str = String(str).replace(/=+$/, '');

    if (str.length % 4 === 1) {
      throw new Error(
        '"atob" failed: The string to be decoded is not correctly encoded.'
      );
    }

    for (
      // initialize result and counters
      let bc = 0, bs: any, buffer: any, idx = 0;
      // get next character
      (buffer = str.charAt(idx++));
      // character found in table? initialize bit storage and add its ascii value;
      ~buffer &&
      ((bs = bc % 4 ? bs * 64 + buffer : buffer),
      // and if not first of each 4 characters,
      // convert the first 8 bits to one ascii character
      bc++ % 4)
        ? (output += String.fromCharCode(255 & (bs >> ((-2 * bc) & 6))))
        : 0
    ) {
      // try to find character in table (0-63, not found => -1)
      buffer = chars.indexOf(buffer);
    }
    return output;
  }

  // https://developer.mozilla.org/en/docs/Web/API/WindowBase64/Base64_encoding_and_decoding#The_Unicode_Problem
  private b64DecodeUnicode(str: any) {
    return decodeURIComponent(
      Array.prototype.map
        .call(this.b64decode(str), (c: any) => {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  public decodeToken(token: string): any {
    let parts = token.split('.');

    if (parts.length !== 3) {
      throw new Error('JWT must have 3 parts');
    }

    let decoded = this.urlBase64Decode(parts[1]);
    if (!decoded) {
      throw new Error('Cannot decode the token');
    }

    return JSON.parse(decoded);
  }

  public getTokenExpirationDate(token: string): Date | null {
    let decoded: any;
    decoded = this.decodeToken(token);

    if (!decoded.hasOwnProperty('exp')) {
      return null;
    }

    let date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);

    return date;
  }

  public isTokenExpired(token: string, offsetSeconds?: number): boolean {
    let date = this.getTokenExpirationDate(token);
    offsetSeconds = offsetSeconds || 0;

    if (date == null) {
      return false;
    }

    // Token expired?
    return !(date.valueOf() > new Date().valueOf() + offsetSeconds * 1000);
  }

  public getTokenRoles(token: string): string[] {
    let decoded: any;
    decoded = this.decodeToken(token);

    if (!decoded.hasOwnProperty('com.tayarac.authenticate.roles')) {
      return [];
    }

    return decoded['com.tayarac.authenticate.roles'];
  }

  public getTokenTfCompanyId(token: string): number {
    let decoded: any;
    decoded = this.decodeToken(token);

    if (!decoded.hasOwnProperty('com.tayarac.authenticate.tfcompanyid')) {
      return 0;
    }

    return decoded['com.tayarac.authenticate.tfcompanyid'];
  }
}

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  private config: IAuthConfig;
  private cachedRequests: Array<HttpRequest<any>> = [];

  constructor(options: AuthConfig, private router: Router) {
    this.config = options.getConfig();
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let token: string | Observable<string> = this.config.tokenGetter();
    if (token instanceof Observable) {
      return token.pipe(
        mergeMap((jwtToken: string) =>
          this.requestWithToken(request, next, jwtToken)
        )
      );
    } else {
      return this.requestWithToken(request, next, token);
    }
  }

  private requestWithToken(
    request: HttpRequest<any>,
    next: HttpHandler,
    token: string
  ): Observable<HttpEvent<any>> {
    if (!!token) {
      let authHeader = Object({});
      authHeader[this.config.headerName] = this.config.headerPrefix + token;
      request = request.clone({ setHeaders: authHeader });
    }

    request = this.setGlobalHeaders(this.config.globalHeaders, request);

    return next.handle(request).pipe(
      tap((ev: HttpEvent<any>) => {
        if (ev.type === HttpEventType.Response) {
          let res: HttpResponse<any> = ev as HttpResponse<any>;
          if (res.headers.has(this.config.jwtHeaderName)) {
            let user = JSON.parse(
              localStorage.getItem(this.config.currentUserName) || '{}'
            );
            user.token = res.headers.get(this.config.jwtHeaderName);
            localStorage.setItem(
              this.config.currentUserName,
              JSON.stringify(user)
            );
          }
        }
      }),
      catchError((res: HttpErrorResponse) => {
        if (401 === res.status || 403 === res.status) {
          this.collectFailedRequest(request);
          this.router.navigate([this.config.loginUrl]);
        } else if (res.headers.has(this.config.jwtHeaderName)) {
          let user = JSON.parse(
            localStorage.getItem(this.config.currentUserName) || '{}'
          );
          user.token = res.headers.get(this.config.jwtHeaderName);
          localStorage.setItem(
            this.config.currentUserName,
            JSON.stringify(user)
          );
        }
        return observableThrowError(res);
      })
    );
  }

  public setGlobalHeaders(
    headers: Array<Object>,
    request: HttpRequest<any> | RequestOptionsArgs
  ) {
    headers.forEach((header: Object) => {
      let key: string = Object.keys(header)[0];
      let headerValue: string = (header as any)[key];
      let o = Object({});
      o[key] = headerValue;
      request = request.clone({ setHeaders: o });
    });

    return request;
  }

  private collectFailedRequest(request: any): void {
    //this.cachedRequests.push( request );
  }

  private retryFailedRequests(): void {
    // retry the requests. this method can
    // be called after the token is refreshed
  }
}

/**
 * Checks for presence of token and that token hasn't expired.
 * For use with the @CanActivate router decorator and NgIf
 */
export function tokenNotExpired(jwt?: string): boolean {
  const token: string = jwt || getToken();

  const jwtHelper = new JwtHelper();

  return token != null && !jwtHelper.isTokenExpired(token);
}

export function tokenHasRole(role: string, jwt?: string): boolean {
  return -1 !== tokenRoles(jwt).indexOf(role);
}

export function tokenHasAnyRole(roles: string[], jwt?: string): boolean {
  const result = tokenRoles(jwt).some((role) => {
    const c = roles.indexOf(role);
    return -1 < c;
  });
  return result;
}

export function tokenRoles(jwt?: string): string[] {
  const token: string = jwt || getToken();
  const jwtHelper = new JwtHelper();

  return (token != null && jwtHelper.getTokenRoles(token)) || [];
}

export function tokenHasTfCompanyId(jwt?: string): number {
  const token: string = jwt || getToken();
  const jwtHelper = new JwtHelper();

  return token ? jwtHelper.getTokenTfCompanyId(token) : 0;
}

export function getToken() {
  let user = JSON.parse(
    localStorage.getItem(AuthConfigConsts.CURRENT_USER_NAME) || '{}'
  );
  return user.hasOwnProperty('token') ? user.token : null;
}

/*
export function authHttpServiceFactory( http: HttpClient, router: Router ) {
    return new AuthHttp( new AuthConfig( {
        loginUrl: '/login',
        tokenGetter: getToken,
        globalHeaders: [{ 'Content-Type': 'application/json' }],
    } ), http, router, {} );
}

export function provideAuth( config?: IAuthConfigOptional ): Provider[] {
    return [
        {
            provide: AuthHttp,
            deps: [HttpClient, Router],
            useFactory: ( http: HttpClient, router: Router ) => {
                return new AuthHttp( new AuthConfig( config ), http, router, {} );
            }
        }
    ];
}
*/

let hasOwnProperty = Object.prototype.hasOwnProperty;
let propIsEnumerable = Object.prototype.propertyIsEnumerable;

function toObject(val: any) {
  if (val === null || val === undefined) {
    throw new TypeError(
      'Object.assign cannot be called with null or undefined'
    );
  }

  return Object(val);
}

function objectAssign(target: any, ...source: any[]) {
  let from: any;
  let to = toObject(target);
  let symbols: any;

  for (let s = 1; s < arguments.length; s++) {
    from = Object(arguments[s]);

    for (let key in from) {
      if (hasOwnProperty.call(from, key)) {
        to[key] = from[key];
      }
    }

    if ((<any>Object).getOwnPropertySymbols) {
      symbols = (<any>Object).getOwnPropertySymbols(from);
      for (let i = 0; i < symbols.length; i++) {
        if (propIsEnumerable.call(from, symbols[i])) {
          to[symbols[i]] = from[symbols[i]];
        }
      }
    }
  }
  return to;
}

export function authHttpInterceptorFactory(router: Router) {
  return new TokenInterceptor(
    new AuthConfig({
      loginUrl: '/login',
      tokenGetter: getToken,
      globalHeaders: [{ 'Content-Type': 'application/json' }],
    }),
    router
  );
}

export const AUTH_PROVIDERS: Provider[] = [
  //    {
  //        provide: AuthHttp,
  //        deps: [HttpClient, Router],
  //        useFactory: authHttpServiceFactory
  //    },
  {
    provide: HTTP_INTERCEPTORS,
    deps: [Router],
    useFactory: authHttpInterceptorFactory,
    multi: true,
  },
];

/**
 * Module for angular2-jwt
 * @experimental
 */
@NgModule({
  imports: [HttpClientModule],
  providers: [JwtHelper], //[AuthHttp, JwtHelper]
})
export class AuthModule {
  static forRoot(config: AuthConfig): ModuleWithProviders<AuthModule> {
    return {
      ngModule: AuthModule,
      providers: [
        { provide: AuthConfig, useValue: config },
        {
          provide: HTTP_INTERCEPTORS,
          deps: [Router],
          useFactory: authHttpInterceptorFactory,
          multi: true,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: AuthModule) {
    if (parentModule) {
      throw new Error(
        'AuthModule is already loaded. Import it in the AppModule only'
      );
    }
  }
}
