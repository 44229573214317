import {
  scan,
  map,
  timeout,
  filter,
  tap,
  retryWhen,
  repeatWhen,
  share,
  delay,
  startWith,
} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ServiceLocator } from '../services/service.locator';

import { EMPTY, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { STORE_ACTIONS } from '../store/tayarac-store';

import {
  WebSocketService,
  wsBaseURL,
  wsTimeout,
} from '../services/web-socket-service.service';
import { dateReviver } from '../utils/utils';

import { Airport } from '../dashboard/model.boards';
import { AuthenticationService } from '../services';
import { ChartConfiguration } from 'chart.js';

@Component({
  selector: 'app-apoc',
  templateUrl: './apoc.component.html',
  styleUrls: ['./apoc.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ApocComponent implements OnInit, OnDestroy {
  public ap: Observable<Airport> = EMPTY;

  public statsCount: Observable<any> = EMPTY;
  public statsCountOptions: ChartConfiguration<'bar'>['options'];

  public statsThroughput: Observable<any> = EMPTY;
  public statsThroughputOptions: ChartConfiguration<'bar'>['options'];
  public statsPickups: Observable<any> = EMPTY;
  public statsPickupsOptions: ChartConfiguration<'bar'>['options'];
  public alerts: Observable<any> = EMPTY;
  public aantal: Observable<any> = EMPTY;

  public ctrls: Observable<Array<number>> = EMPTY;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  protected wsService: WebSocketService;
  protected wsBaseUrl: string;
  protected wsTimeout: number = 5000;

  protected store: Store<any>;

  public data: any;

  private areaColor: { [key: string]: string } = {
    T2: 'blue',
    T2T1: 'black',
    T1: 'yellow',
    T1T0: 'green',
    T0: 'red',
  };

  private areaName: { [key: string]: string } = {
    T2: 'PT',
    T2T1: 'Transfer Pt-Res',
    T1: 'Reservoir',
    T1T0: 'Transfer Res-Ac',
    T0: 'Arrival curb',
  };

  constructor(private authSvc: AuthenticationService) {
    this.wsService = new WebSocketService(authSvc); //
    this.wsBaseUrl = ServiceLocator.injector.get(wsBaseURL);
    this.wsTimeout = ServiceLocator.injector.get(wsTimeout);
    this.store = ServiceLocator.injector.get(Store);

    this.statsCountOptions = {
      responsive: true,
      maintainAspectRatio: true,
      animation: false,
      bar: {
        datasets: {
          barPercentage: 0.5,
          categoryPercentage: 0.6,
        }
      },
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: 'Aantal voertuigen Pickup',
          position: 'top',
        }
      },
    };

    this.statsThroughputOptions = {
      responsive: true,
      maintainAspectRatio: true,
      animation: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: 'Doorlooptijd (minuten)',
          position: 'top',
        },
      },
    };

    this.statsPickupsOptions = {
      responsive: true,
      maintainAspectRatio: true,
      animation: false,
      plugins: {
        legend: {
          position: 'bottom',
        },
        title: {
          display: true,
          text: 'Aantal voertuigen periode',
          position: 'top',
        },
      },
    };
  }

  ngOnInit() {
    let ws = this.wsService
      .connect(this.wsBaseUrl, (e) => {
        this.store.dispatch({ type: STORE_ACTIONS.APOC_ONLINE, payload: true });
      })
      .pipe(
        map((d) => {
          let c = JSON.parse(d.data, dateReviver);
          return c;
        }),
        // Add ping check
        timeout(this.wsTimeout),
        filter((c) => {
          // Filter empty or pings
          return !!c && !('ping' in c);
        }),
        tap(
          (d) => {},
          (e) => {
            console.log('Error from ws: ', e);
            this.store.dispatch({
              type: STORE_ACTIONS.APOC_ONLINE,
              payload: false,
            });
          },
          () => {
            this.store.dispatch({
              type: STORE_ACTIONS.APOC_ONLINE,
              payload: false,
            });
          }
        ),
        retryWhen((failures) => {
          return failures.pipe(delay(5000));
        }),
        repeatWhen((failures) => {
          return failures.pipe(delay(5000));
        }),
        share()
      );

    this.ap = ws.pipe(
      filter((c) => {
        return !!c && !!c.airport;
      }),
      map((c) => {
        return c.airport;
      })
    );

    let statsObs = ws.pipe(
      filter((c) => {
        return !!c && !!c.stats;
      }),
      map((c) => {
        return c.stats;
      }),
      share()
    );

    this.statsCount = statsObs.pipe(
      map((stats) => {
        let s = stats.carCount.data;
        let data = {
          labels: s.labels,
          datasets: [] as any,
        };

        ['T0', 'T1T0', 'T1', 'T2T1', 'T2'].forEach((key: string) => {
          data.datasets.push({
            label: this.areaName[key],
            backgroundColor: this.areaColor[key],
            data: s.data[key],
            stack: 'bac',
          });
        });

        return data;
      })
    );

    this.statsThroughput = statsObs.pipe(
      map((stats) => {
        let s = stats.carThroughput.data;
        let data = {
          labels: s.labels,
          datasets: [] as any,
        };

        ['T0', 'T1T0', 'T1', 'T2T1', 'T2'].forEach((key: string) => {
          data.datasets.push({
            label: this.areaName[key],
            backgroundColor: this.areaColor[key],
            data: s.data[key],
            stack: 'bac',
          });
        });

        return data;
      })
    );

    this.statsPickups = statsObs.pipe(
      map((stats) => {
        let s = stats.carsLeavingT0.data;
        let data = {
          labels: s.labels,
          datasets: [
            {
              label: '',
              backgroundColor: 'red',
              data: s.data,
              stack: 'bac',
            },
          ],
        };

        return data;
      })
    );

    this.ctrls = statsObs.pipe(
      map((stats) => {
        let s = stats.carsForecast.data;
        return s.data;
      }),
      startWith([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    );

    let obsWsAlerts = ws.pipe(
      filter((c) => !!c.alert),
      map((c) => {
        return c.alert;
      })
    );

    this.alerts = obsWsAlerts.pipe(
      scan((ar, al) => [al, ...ar], [] as any[]),
      map((ar) => ar.slice(0, 300))
    );
    //this.alerts.takeUntil( this.ngUnsubscribe ).subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
