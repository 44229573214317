
import {tap, retryWhen, takeUntil, delay, filter, timeout, map, share, repeatWhen} from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ServiceLocator } from '../services/service.locator';

// import * as moment from 'moment';

import { EMPTY, Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { STORE_ACTIONS } from '../store/tayarac-store';

import { WebSocketService, wsBaseURL, wsTimeout } from '../services/web-socket-service.service';
import { dateReviver } from '../utils/utils';

import { Airport } from '../dashboard/model.boards'
import { AuthenticationService } from '../services';

@Component( {
    selector: 'app-diagnostic',
    templateUrl: './diagnostic.component.html',
    styleUrls: ['./diagnostic.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class DiagnosticComponent implements OnInit, OnDestroy {

    public status: any;
    public statusMsgs: any[] = [];
    public ap: Observable<Airport> = EMPTY;

    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    protected wsService: WebSocketService;
    protected wsBaseUrl: string;
    protected wsTimeout: number = 5000;

    protected store: Store<any>;

    constructor(private authSvc: AuthenticationService) {
        this.wsService = new WebSocketService(authSvc); //
        this.wsBaseUrl = ServiceLocator.injector.get( wsBaseURL );
        this.wsTimeout = ServiceLocator.injector.get( wsTimeout );
        this.store = ServiceLocator.injector.get( Store );
    }

    ngOnInit() {
        let ws = this.wsService.connect( this.wsBaseUrl, e => {
            this.store.dispatch( { type: STORE_ACTIONS.DIAGNOSTIC_ONLINE, payload: true } );
        } ).pipe(map( d => {
            let c = JSON.parse( d.data, dateReviver );
            return c;
        } ),
            // Add ping check
            timeout( this.wsTimeout ),
            filter( c => {
                // Filter empty or pings
                return !!c && !( 'ping' in c )
            } ),
            tap( d => { }, e => {
                console.log( 'Error from ws: ', e );
                this.store.dispatch( { type: STORE_ACTIONS.DIAGNOSTIC_ONLINE, payload: false } );
            }, () => {
                this.store.dispatch( { type: STORE_ACTIONS.DIAGNOSTIC_ONLINE, payload: false } );
            } ),retryWhen( failures => {
                return failures.pipe(delay( 5000 ));
            } ),repeatWhen( failures => {
                return failures.pipe(delay( 5000 ));
            } ),share(),);

        let _self = this;

        this.ap = ws.pipe(
          filter( c => {
            return !!c && !!c.airport;
          } )
          ,map( c => c.airport)
        );

        this.status = this.ap.pipe(filter( ap => {
            return !!ap.status;
        } ),map( ap => {
            return ap.status;
        } ),takeUntil( this.ngUnsubscribe ),).subscribe( s => {
            setTimeout( () => {
                _self.status = s;
                _self.statusMsgs = _self.setupStatusMsgs();
            }, 0 );
        }, e => {

        }, () => {

        } );

    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    public get statusPtIN() {
        if ( !this.status.statusPtIN )
            return true;

        let x = this.status.statusPtIN;
        return x.co;// && x.tl;
    }

    public get statusPtOUT() {
        if ( !this.status.statusPtOUT )
            return true;

        let x = this.status.statusPtOUT;
        return x.co && x.di; // x.tl && x.co && x.di;
    }

    public get statusResIN() {
        if ( !this.status.statusResIN )
            return true;

        let x = this.status.statusResIN;
        return x.co; // x.tl && x.co;
    }

    public get statusResOUT() {
        if ( !this.status.statusResOUT )
            return true;

        let x = this.status.statusResOUT;
        return x.co && x.di; // x.tl && x.co && x.di;
    }

    public get statusAcINL() {
        if ( !this.status.statusAcINL )
            return true;

        let x = this.status.statusAcINL;
        return x.co && x.ccot; // x.tl && x.co && x.ccot;
    }

    public get statusAcINR() {
        if ( !this.status.statusAcINR )
            return true;

        let x = this.status.statusAcINR;
        return x.co && x.ccot; // x.tl && x.co && x.ccot;
    }

    public get statusAcOUT() {
        if ( !this.status.statusAcOUT )
            return true;

        let x = this.status.statusAcOUT;
        return x.co; // x.tl && x.co;
    }

    public get statusP12P13() {
        if ( !this.status.statusP12P13 )
            return true;

        let x = this.status.statusP12P13;
        return x.co; // x.tl && x.co;
    }

    public setupStatusMsgs(): any[] {

        let res: any[] = [];

        if ( !this.statusPtIN ) {
            let x = this.status.statusPtIN;
            res = [...res, { 'area': 'Pt IN', 'msgs': [{ 'status': x.co, 'msg': 'CO' }] }];
        }
        if ( !this.statusPtOUT ) {
            let x = this.status.statusPtOUT;
            res = [...res, { 'area': 'Pt OUT', 'msgs': [{ 'status': x.co, 'msg': 'CO' }, { 'status': x.di, 'msg': 'DI' }] }];
        }
        if ( !this.statusResIN ) {
            let x = this.status.statusResIN;
            res = [...res, { 'area': 'Res IN', 'msgs': [{ 'status': x.co, 'msg': 'CO' }] }];
        }
        if ( !this.statusResOUT ) {
            let x = this.status.statusResOUT;
            res = [...res, { 'area': 'Res OUT', 'msgs': [{ 'status': x.co, 'msg': 'CO' }, { 'status': x.di, 'msg': 'DI' }] }];
        }

        if ( !this.statusAcINL ) {
            let x = this.status.statusAcINL;
            res = [...res, { 'area': 'Ac IN L', 'msgs': [{ 'status': x.co, 'msg': 'CO' }, { 'status': x.ccot, 'msg': 'CCOT' }] }];
        }
        if ( !this.statusAcINR ) {
            let x = this.status.statusAcINR;
            res = [...res, { 'area': 'Ac IN R', 'msgs': [{ 'status': x.co, 'msg': 'CO' }, { 'status': x.ccot, 'msg': 'CCOT' }] }];
        }

        if ( !this.statusAcOUT ) {
            let x = this.status.statusAcOUT;
            res = [...res, { 'area': 'Ac OUT', 'msgs': [{ 'status': x.co, 'msg': 'CO' }] }];
        }

        if ( !this.statusP12P13 ) {
            let x = this.status.statusP12P13;
            res = [...res, { 'area': 'P12/P13', 'msgs': [{ 'status': x.co, 'msg': 'CO' }] }];
        }

        return res;
    }
}
