
import {map} from 'rxjs/operators';
import { Component, Input, OnInit } from '@angular/core';

import { EntitiesComponent } from '../entity-component/entities.component';

import { Key } from '../model';
import { KeysService } from '../services/keys.service';

export { KeysListComponent } from './keys-list.component';
export { KeyFormComponent } from './key-form.component';

@Component( {
    selector: 'app-keys',
    templateUrl: './keys.component.html',
    styleUrls: ['./keys.component.scss'],
})
export class KeysComponent extends EntitiesComponent<Key> {

    constructor( protected keyService: KeysService ) {
        super( Key, keyService);
    }

    override ngOnInit() {
        super.ngOnInit()

        this.sortSettings = {
            multisortmeta: [{ field: 'name', order: 1 }]
        }

        this.items = this.items.pipe(map( keys => {
            keys.forEach( key => {
                key.keyRFID = !!key.tokens && 0 < key.tokens.length ? key.tokens[0].identity : '';
            });
            return keys;
        }));
    }
}
