<form *ngIf="selectedItem" class="formly" #thisForm="ngForm" role="form" novalidate [formGroup]="form" (ngSubmit)="save(selectedItem)">
	<p-panel> <p-header>
	<h2 *ngIf="selectedItem?.id">Editing '{{originalName}}'</h2>
	<h2 *ngIf="!selectedItem?.id">Create New {{entityName}}</h2>
	</p-header>

	<div class="t-grid">
		<!-- Item specifics start here -->
	    <div class="t-col-6 form-group">
	        <node-unique-name [item]="selectedItem" [service]="itemService" field="serialnr" placeholder="Serial Nr" [input]="$any(form.controls.serialnr)"></node-unique-name>
	    </div>
	    <div class="t-col-6 form-group">
	        <node-unique-name [item]="selectedItem" [service]="itemService" [input]="$any(form.controls.name)"></node-unique-name>
	    </div>
	    <div class="t-col-6 form-group">
	         <mat-form-field class="full-width">
	            <input matInput placeholder="Description" type="text" id="description" name="description" formControlName="description"/>
	         </mat-form-field>
	    </div>

		<ul class="t-col-12 form-group" *ngIf="errorMsgs?.length" class="alert alert-danger">
			<li *ngFor="let err of errorMsgs">{{err}}</li>
		</ul>
		<!-- Item specifics end here -->
	</div>

	<p-footer>
	<button mat-raised-button color="primary" type="submit" [disabled]="!thisForm.form.valid">Submit</button>
	<button mat-raised-button (click)="cancel(selectedItem)" type="button">Cancel</button>
	</p-footer> </p-panel>
</form>

<div *ngIf="!selectedItem">{{entityName}} does not exists</div>
