import {  Directive, OnChanges } from '@angular/core';

import { Keyholder } from '../model';
import { EntityListComponent } from '../entity-component/entity-list.component';

@Directive()
export abstract class KeyholdersListComponent<Item extends Keyholder> extends EntityListComponent<Item> implements OnChanges {

    ngOnChanges() {
    }

    protected getAccessRights( kh: Item ): string {
        let pickup = kh.accessRules && kh.accessRules.length > 0 ? kh.accessRules.find( ar => ar.entry?.name === 'PICKUP' ) : false;
        let dropoff = kh.accessRules && kh.accessRules.length > 0 ? kh.accessRules.find( ar => ar.entry?.name === 'DROPOFF' ) : false;
        let p12p13 = kh.accessRules && kh.accessRules.length > 0 ? kh.accessRules.find( ar => ar.entry?.name === 'P12P13' ) : false;

        let ar: string[] = [];
        if ( pickup )
            ar.push( "PICKUP" );
        if ( dropoff )
            ar.push( "DROPOFF" );
        if ( p12p13 )
            ar.push( "P12P13" );

        return ar.join( ", " );
    }
}
