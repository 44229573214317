<form *ngIf="selectedItem" [formGroup]="form" (ngSubmit)="save(selectedItem)">

			<p-panel>
				<p-header>
					<h2 *ngIf="selectedItem.id">Editing '{{originalName}}'</h2>
					<h2 *ngIf="!selectedItem.id">Create New {{entityName}}</h2>
				</p-header>

				<div class="t-grid t-flex-row t-flex-wrap t-align-content-center t-justify-content-start">
					<!-- Item specifics start here -->
            <p-tabView class="t-col-9">
              <p-tabPanel header="Personal info" leftIcon="fa fa-id-badge">
                  <div class="t-grid t-flex-row">
                      <div class="t-col-6">
                      <!-- dividerColor="{{ !form.controls.firstname.valid ? 'warn' : 'primary' }}" -->
                          <mat-form-field class="full-width" >
                              <mat-label>First name</mat-label>
                              <input matInput placeholder="First name" class="full-width" type="text" id="firstname" required formControlName="firstname" name="firstname" />
                              <mat-hint align="start" [class.alert-danger]="!form.controls.firstname.valid" *ngIf="!form.controls.firstname.valid">First name must not be empty</mat-hint>
                          </mat-form-field>
                      </div>
                      <div class="t-col-6">
                      <!-- dividerColor="{{ !form.controls.lastname.valid ? 'warn' : 'primary' }}" -->
                          <mat-form-field class="full-width" >
                              <mat-label>Last name</mat-label>
                              <input matInput placeholder="Last name" class="full-width" type="text" id="lastname" required formControlName="lastname" name="lastname" />
                              <mat-hint align="start" [class.alert-danger]="!form.controls.lastname.valid" *ngIf="!form.controls.lastname.valid">Last name must not be empty</mat-hint>
                          </mat-form-field>
                      </div>

                      <div class="t-col-3" *ngIf="false">
                          <mat-form-field class="full-width">
                              <mat-label>Password</mat-label>
                              <input matInput placeholder="Password" class="full-width" type="password" id="password1" formControlName="password1" name="password1" />
                          </mat-form-field>
                      </div>
                      <div class="t-col-3" *ngIf="false">
                          <mat-form-field class="full-width">
                              <mat-label>Confirm password</mat-label>
                              <input matInput placeholder="Confirm password" class="full-width" type="password" id="password2" formControlName="password2" name="password2" />
                          </mat-form-field>
                      </div>

                      <div class="t-col-6">
                          <mat-form-field class="full-width">
                              <mat-label>Phone</mat-label>
                              <input matInput placeholder="Phone" class="full-width" type="text" formControlName="phone" name="phone" />
                          </mat-form-field>
                      </div>
                      <div class="t-col-6">
                          <mat-form-field class="full-width">
                              <mat-label>E-mail</mat-label>
                              <input matInput placeholder="E-mail" class="full-width" required type="text" formControlName="email" name="email" />
                              <mat-hint align="start" [class.alert-danger]="!form.controls.email.valid" *ngIf="!form.controls.email.valid">E-mail must not be empty</mat-hint>
                          </mat-form-field>
                      </div>
                      <div class="t-col-6"  *ngIf="showCompany">
                          <mat-select class="full-width" placeholder="Company" formControlName="tfCompanyId" required>
                            <mat-option *ngFor="let company of tfCompanies" [value]="company.id">{{ company.name }}</mat-option>
                          </mat-select>
                      </div>
                  </div>
                </p-tabPanel>
              </p-tabView>
			        <div class="t-col-3">
			            <p>Roles <span style="color:red">*</span>:</p>
			            <p-listbox [options]="(roles$ | async) || []" required formControlName="roles" [multiple]="true" [checkbox]="true" [filter]="true"
                  optionLable="label" optionValue="value" ></p-listbox>
			        </div>

					<!-- Item specifics end here -->
				</div>

				<div class="clearfix"></div>
				<ul class="t-col-12" *ngIf="errorMsgs?.length" class="alert alert-danger">
					<li *ngFor="let err of errorMsgs">{{err}}</li>
				</ul>

				<p-footer>
					<button mat-raised-button color="primary" type="submit" [disabled]="!isValid">Submit</button>
					<button mat-raised-button (click)="cancel(selectedItem)" type="button">Cancel</button>
				</p-footer>
	        </p-panel>
</form>

<div class="t-col-12" *ngIf="debug">
    <pre>{{ debug | json }}</pre>
</div>

<div *ngIf="!selectedItem">{{entityName}} does not exists</div>

