
import {distinctUntilChanged} from 'rxjs/operators';
import { Component, ViewEncapsulation, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Area, Entry, Lock } from '../model';

@Component( {
    selector: 'node-entry',
    templateUrl: './node-entry.component.html',
    styleUrls: ['./node-entry.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class NodeEntryComponent implements OnChanges {

    @Input()
    public entryForm!: FormGroup;

    public _locks: Lock[] = [];

    private selectedLock?: Lock;

    @Output()
    public lockSelected: EventEmitter<Lock> = new EventEmitter<Lock>();
    @Output()
    public lockDeselected: EventEmitter<Lock> = new EventEmitter<Lock>();

    constructor() {
    }

    ngOnChanges() {
        if ( !!this.entryForm ) {
            this.entryForm.controls['lock'].valueChanges.pipe(distinctUntilChanged())
                .subscribe( d => {
                    console.debug( "Lock selected: ", d );
                    if ( null != this.selectedLock ) {
                        this.lockDeselected.emit( this.selectedLock );
                    }
                    this.selectedLock = d;
                    if ( null != this.selectedLock ) {
                        this.lockSelected.emit( this.selectedLock );
                    }
                } )
        }
    }

    @Input()
    public set locks( locks: Lock[] ) {

        if ( !!this.lockCtrl && !!this.lockCtrl.value) {
            this._locks = [this.lockCtrl.value, ...locks];
        } else {
            this._locks = [...locks];
        }
    }

    public get locks(): Lock[] {
        return this._locks;
    }

    public get nameCtrl() {
        return this.entryForm.controls['name'];
    }

    public get lockCtrl() {
        return this.entryForm.controls['lock'];
    }
}
