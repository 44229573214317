<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd>
   <p-footer>
        <button type="button" pButton icon="fa-check" label="Yes" (click)="cd.accept()"></button>
        <button type="button" pButton icon="fa-close" label="Cancel" (click)="cd.reject()"></button>
    </p-footer>
</p-confirmDialog>

<search-form #searchForm (search)="setFilter([
    {'field':'licensePlate', 'value':licensePlate.value},
    {'field':'keySerial', 'value':keySerial.value}
    ])" (reset)="licensePlate.value=''; setFilter([]); searchForm.hide()">
    <mat-form-field class="t-col-6">
        <input matInput #licensePlate placeholder="Filter by license plate" [autofocus]="true" class="full-width" (keyup.enter)="searchForm.search.emit()" />
    </mat-form-field>
    <mat-form-field class="t-col-6">
        <input matInput #keySerial placeholder="Filter by booster ID" class="full-width" (keyup.enter)="searchForm.search.emit()" />
    </mat-form-field>
</search-form>

<vehicles-list [items]="(items | async) || []" *ngIf="!editing" (selected)="itemSelected($event)"
(added)="newItem()" (search)="searchForm.show(); setFocus(licensePlate)" (deleted)="deleteItem($event)" (download)="downloadData()"
[(sortSettings)]="sortSettings" [(paging)]="paging"
></vehicles-list>
<vehicle-form [item]="(selectedItem | async) || {}" *ngIf="!!editing" [errorMsgs]="(errorMsg | async) || []" (canceled)="editCancel()" (saved)="saveItem($event)"></vehicle-form>
