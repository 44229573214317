<div class="container-fluid">
    <div>
 		<p-table [value]="items" [multiSortMeta]="sortSettings?.multisortmeta" [loading]="loading"
 		  selectionMode="single" (onRowSelect)="select($event.data)" [resizableColumns]="true"
		  sortMode="multiple" [rows]="rows"  [first]="first" (onSort)="onSort($event)" (onPage)="onPage($event)"
		  [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" [reorderableColumns]="true">
		        <ng-template pTemplate="caption">
		            <div class="pull-left"><h4>Keys</h4></div>

                <div class="pull-right">
                    <button mat-icon-button color="primary" (click)="add()" type="button">
                       <mat-icon fontSet="fa" fontIcon="fa-plus" class="mat-24"></mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="search.emit()" type="button">
                       <mat-icon fontSet="fa" fontIcon="fa-search" class="mat-24"></mat-icon>
                    </button>
                </div>
                <div class="clearfix"></div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Name</th>
                    <th>SerialNr</th>
                    <th>Description</th>
                    <th>RFID</th>
                    <th>Is a car booster</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-key>
                <tr [pSelectableRow]="rowData">
                    <td>{{ key.name }}</td>
                    <td>{{ key.serialnr }}</td>
                    <td>{{ key.description }}</td>
                    <td>{{ key.keyRFID }}</td>
                    <td><i [ngClass]="{'fa fa-car':key.properties?.carBooster}"></i></td>
                    <td>
                        <button mat-icon-button color="accent" (click)="remove($event, key)">
                          <mat-icon fontSet="fa" fontIcon="fa-trash-can"></mat-icon>
                        </button>
                    </td>
                </tr>
            </ng-template>

		</p-table>
	</div>
</div>
