import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import {FormGroup, FormBuilder, Validators } from '@angular/forms';

import { AuthenticationService, AuthGuard } from '../services';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { map, filter, tap, takeUntil } from 'rxjs/operators';
import {  Subject } from 'rxjs';

export function MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors['mustMatch']) {
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
}

@Component( {
    selector: 'app-reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['./reset-password.component.scss']
} )

export class ResetPasswordComponent implements OnInit, OnDestroy {
    ngUnsubscribe = new Subject<any>( );
    error? :string = '';
    requestMsg?: string = undefined;

    submitted = false;

    token: string = '';

    form: FormGroup;

    loading = false;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private authenticationService: AuthenticationService,
        private fb: FormBuilder) {

        this.form = this.createForm();
    }

    ngOnInit() {
        const compId$ = this.route.params.pipe(
            map( params => {
                return params['token'];
            } ),
            filter( t => !!t ),
            tap( t => {
                this.token = t;
            } ),
            takeUntil( this.ngUnsubscribe )

        ).subscribe();
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next(true);
    }

    get f() { return this.form.controls; }

    createForm(): FormGroup {

        let grp = this.fb.group( {
            password: ['', [Validators.required, Validators.minLength(6)]],
            cpassword: ['', [Validators.required]]
        }, {
            validator: MustMatch('password', 'cpassword')
        });

        return grp;
    }

    resetPassword() {

        this.submitted = true;

        // stop here if form is invalid
        if (this.form.invalid) {
            return;
        }

        this.authenticationService.resetPassword( this.token, this.form.value.password )
        .subscribe({
          next: (result: any) => {
            this.requestMsg = !!result ? result.data : null;
            this.error = undefined;
        },
        error: (e: any) => {
            this.requestMsg = undefined;
            this.error = "Error sending the request"
        },
        complete: ()=>{}
      });

    }
}
