import { AuthenticationService } from './../services/authentication.service';
import { EMPTY, merge as observableMerge, Observable, Subject } from 'rxjs';

import {
  scan,
  map,
  timeout,
  filter,
  tap,
  retryWhen,
  repeatWhen,
  delay,
  startWith,
} from 'rxjs/operators';
import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
} from '@angular/core';
import { ServiceLocator } from '../services/service.locator';
import { Store } from '@ngrx/store';
import { STORE_ACTIONS } from '../store/tayarac-store';

import {
  WebSocketService,
  wsBaseURL,
  wsTimeout,
} from '../services/web-socket-service.service';
import { dateReviver } from '../utils/utils';

import { Airport, BoardArea } from '../dashboard/model.boards';

@Component({
  selector: 'overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OverviewComponent implements OnInit, OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  protected wsService: WebSocketService;
  protected wsBaseUrl: string;
  protected wsTimeout: number = 5000;

  public ap: Observable<Airport> = EMPTY;
  public areas: Observable<BoardArea[]> = EMPTY;
  public offsite: Observable<BoardArea> = EMPTY;
  public sensits: Observable<boolean[]> = EMPTY;
  protected store: Store<any>;

  public alerts: Observable<any> = EMPTY;

  public dashboardOnline: Observable<boolean> =  EMPTY;

  protected ttEscape = false;

  constructor(private authSvc: AuthenticationService) {
    this.wsService = new WebSocketService(authSvc); //
    this.wsBaseUrl = ServiceLocator.injector.get(wsBaseURL);
    this.wsTimeout = ServiceLocator.injector.get(wsTimeout);
    this.store = ServiceLocator.injector.get(Store);
  }

  ngOnInit() {
    let ws = this.wsService
      .connect(this.wsBaseUrl, (e) => {
        this.store.dispatch({
          type: STORE_ACTIONS.DASHBOARD_ONLINE,
          payload: true,
        });
      })
      .pipe(
        map((d) => {
          let c = JSON.parse(d.data, dateReviver);
          return c;
        }),
        // Add ping check
        timeout(this.wsTimeout),
        filter((c) => {
          // Filter empty or pings
          return !!c && !('ping' in c);
        }),
        tap(
          (d) => {},
          (e) => {
            console.log('Error from ws: ', e);
            this.store.dispatch({
              type: STORE_ACTIONS.DASHBOARD_ONLINE,
              payload: false,
            });
          },
          () => {
            this.store.dispatch({
              type: STORE_ACTIONS.DASHBOARD_ONLINE,
              payload: false,
            });
          }
        ),
        retryWhen((failures) => {
          return failures.pipe(delay(5000));
        }),
        repeatWhen((failures) => {
          return failures.pipe(delay(5000));
        })
      );

    this.ap = ws.pipe(
      filter((c) => !!c.airport),
      map((c) => c.airport),
      tap((ap) => {
        console.log('Airport: ', ap);
      })
    );

    this.sensits = this.ap.pipe(
      filter((ap) => !!ap.sensits),
      map((ap) => ap.sensits.freeSensors),
      startWith([])
    );

    this.areas = this.ap.pipe(
      map((a) => a.areas),
      map((areas) => areas.filter((area) => area.name !== 'OFFSITE')),
      map((areas) => {
        areas.forEach((area) => {
          area.cars = area.cars.sort((a, b) => {
            return a.licensePlate.localeCompare(b.licensePlate);
          });
          return area;
        });
        return areas;
      })
    );
    this.offsite = this.ap.pipe(
      map((a) => a.areas),
      map((areas) => areas.find((area) => area.name === 'OFFSITE')),
      startWith(new BoardArea('OFFSITE')),
      filter(area => !!area),
      map( area => area!),
      map((area: BoardArea) => {
        area.cars = area.cars.sort((a, b) => {
          return a.licensePlate.localeCompare(b.licensePlate);
        });
        return area;
      })
    );

    let obsWsAlerts = ws.pipe(
      filter((c) => !!c.alert),
      map((c) => {
        return c.alert;
      })
    );

    let obsDBAlerts = this.dashboardOnline.pipe(
      map((b) => {
        return b
          ? {
              type: 'INFO',
              timestamp: new Date(),
              message: 'Dashboard is online',
            }
          : {
              type: 'WARN',
              timestamp: new Date(),
              message: 'Dashboard is offline',
            };
      })
    );

    this.alerts = observableMerge(obsWsAlerts, obsDBAlerts).pipe(
      scan((ar, al) => [al, ...ar].slice(0, 300), [] as any[])
    );

    this.dashboardOnline = this.store.select('dashboardOnline');
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public call7seater(event: any, feature: any) {
    this.wsService.send({
      action: 'CALL_TAXI',
      payload: { features: feature, count: 1 },
    });
  }
}
