
import { BaseEntityFormComponent } from './base-entity-form.component';
import { ItemService } from '../services/item.service';

import { NamedNode } from '../model';
import { Directive } from '@angular/core';

@Directive()
export abstract class EntityFormComponent<Item extends NamedNode> extends BaseEntityFormComponent<Item> {

    constructor( protected override _itemService: ItemService<Item> ) {
        super(_itemService);
    }

    public getProperty( name: string ): any {
        return ( !!this.selectedItem && !!this.selectedItem.properties && -1 != Object.keys( this.selectedItem.properties ).indexOf( name ) )
            ? this.selectedItem.properties[name] : undefined;
    }

    protected override getOriginalName(): string {
        return this.item?.name || '';
    }
}
