import { NamedNode } from './node';
import { Key } from './key';
import { Validity } from './validity';
import { AccessRule } from './access_rule';
import { TfCompany} from './tfcompany';

export class KeyholderHasKey {
    id?: number;
    key?: Key;
    validity?: Validity;
    start?: Date;
    finish?: Date;
}

export class Keyholder extends NamedNode {
    keys?: KeyholderHasKey[];
    tfCompany?: TfCompany;
    accessRules?: AccessRule[];

    keySerial?: string;
    blacklisted?: boolean;
    companyName?: string;
}
