import { Component, OnInit, OnChanges, OnDestroy, Input, Output, EventEmitter, Directive } from '@angular/core';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { FormBuilder, FormGroup } from '@angular/forms';

import { NamedNode } from '../model';
import { ServiceLocator } from '../services/service.locator';
import { BaseItemService } from '../services/base-item.service';

import { clone } from '../utils/utils';

@Directive()
export abstract class BaseEntityFormComponent<Item> implements OnInit, OnChanges, OnDestroy {

    protected ngUnsubscribe: Subject<void> = new Subject<void>();

    public selectedItem?: Item;
    public itemService: BaseItemService<Item>;

    public debug: any;

    @Output()
    private canceled = new EventEmitter<Item>();
    @Output()
    private saved = new EventEmitter<Item>();

    @Input()
    public errorMsgs: string[];

    public originalName?: string;
    protected entityName: string;

    protected fb: FormBuilder;
    protected form: FormGroup;

    constructor( protected _itemService: BaseItemService<Item> ) {
        this.entityName = this.getEntityName().charAt( 0 ).toUpperCase() + this.getEntityName().slice( 1 );
        this.fb = ServiceLocator.injector.get( FormBuilder );
        this.form = this.createForm();
        this.itemService = _itemService;
        this.errorMsgs = [];
    }

    ngOnInit() {
    }

    ngOnDestroy() {
        this.ngUnsubscribe.next();
        this.ngUnsubscribe.complete();
    }

    ngOnChanges() {
        this.form.patchValue( this.selectedItem || {});
    }

    /**
     * Assigns an item to the form.
     *
     * To avoid modification of the original item it is copied.
     * Thus cancel operation does nothing, save emits the copy.
     */
    @Input( "item" )
    public set item( value: Item | undefined | null ) {
        this.selectedItem = clone( value ) || undefined;
        this.originalName = this.getOriginalName();
    }
    public get item(): Item | undefined | null {
        return this.selectedItem;
    }

    public save( item: Item ) {
        this.saved.emit( item );
    }

    public cancel( item: Item ) {
        this.canceled.emit( item );
    }

    protected getOriginalName(): string {
        return this.getEntityName();
    }

    protected abstract getEntityName(): string;

    protected abstract createForm(): FormGroup;
}
