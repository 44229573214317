import { Injectable } from '@angular/core';
import { Config, STYLE, THEME } from 'ngx-easy-table';


@Injectable()
export class ConfigService {
    public static config: Config = {
        searchEnabled: true,
        headerEnabled: true,
        orderEnabled: true,
        orderEventOnly: false,
        paginationEnabled: true,
        clickEvent: true,
        selectRow: false,
        selectCol: false,
        selectCell: false,
        rows: 5,
        additionalActions: false,
        serverPagination: false,
        isLoading: false,
        detailsTemplate: false,
        groupRows: false,
        paginationRangeEnabled: true,
        collapseAllRows: false,
        checkboxes: false,
        resizeColumn: true,
        fixedColumnWidth: false,
        horizontalScroll: false,
        logger: false,
        showDetailsArrow: false,
        showContextMenu: false,
        persistState: false,
        paginationMaxSize: 5,
        tableLayout: {
            style: STYLE.TINY, // or STYLE.BIG or STYLE.TINY
            theme: THEME.LIGHT, // or THEME.DARK
            borderless: true,
            hover: true,
            striped: true,
        }
    };

    public static configSelection: Config = {
            searchEnabled: true,
            headerEnabled: true,
            orderEnabled: true,
            orderEventOnly: false,
            paginationEnabled: true,
            clickEvent: true,
            selectRow: true,
            selectCol: false,
            selectCell: false,
            rows: 5,
            additionalActions: false,
            serverPagination: false,
            isLoading: false,
            detailsTemplate: false,
            groupRows: false,
            paginationRangeEnabled: true,
            collapseAllRows: false,
            checkboxes: true,
            resizeColumn: true,
            fixedColumnWidth: false,
            horizontalScroll: false,
            logger: false,
            showDetailsArrow: false,
            showContextMenu: false,
            persistState: false,
            paginationMaxSize: 5,
            tableLayout: {
                style: STYLE.TINY, // or STYLE.BIG or STYLE.TINY
                theme: THEME.LIGHT, // or THEME.DARK
                borderless: true,
                hover: true,
                striped: true,
            }
        };
}
