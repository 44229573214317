export class Company {
    id: number;
    name?: string;

    public constructor( id: number, name?: string ) {
        this.id = id;
        this.name = name;
    }
}

export class Role {
    id: number;
    name?: string;

    public constructor( id: number, name?: string ) {
        this.id = id;
        this.name = name;
    }
}

export class CompanyRole {
    company?: Company;
    role?: Role;

    public constructor( company?: Company, role?: Role ) {
        this.company = company;
        this.role = role;
    }
}

export class Admin {
    id?: number;
    disabled?: boolean;
    disabledDate?: string;

    displayName?: string;
    name?: string;
    email?: string;
    firstname?: string;
    lastname?: string;
    phone?: string;
    tfCompanyId?: number;

    usercompanyrole?: CompanyRole[];
}
