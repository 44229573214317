<div class="display-area t-grid t-flex-column t-justify-content-around t-align-content-stretch">
	<div style="flex: 1 1 50%;" class="display-area-row1">
		<mat-icon [svgIcon]="type" class="mat-icon {{type}} car-icon"></mat-icon>
	</div>
	<div style="flex: 1 1 50%;" class="display-area-row2 t-grid t-flex-nowrap t-flex-row t-justify-content-center">
	    <div class="t-flex-auto" [ngClass]="getClasses('X')" *ngIf="hasX">X</div>
		  <div class="t-flex-auto" [ngClass]="getClasses('A')">A</div>
      <div class="t-flex-auto" [ngClass]="getClasses('U')">U</div>
      <div class="t-flex-auto" [ngClass]="getClasses('W')">W</div>
	</div>
</div>
