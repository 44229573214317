import { Component, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';

import { EntitiesComponent } from '../entity-component/entities.component';

import { Area } from '../model';
import { AreasService } from '../services/areas.service';

export { AreasListComponent } from './areas-list.component';
export { AreaFormComponent } from './area-form.component';

@Component( {
    selector: 'app-areas',
    templateUrl: './areas.component.html',
    styleUrls: ['./areas.component.scss'],
})
export class AreasComponent extends EntitiesComponent<Area> {

    constructor( protected override route: ActivatedRoute, protected override router: Router, protected areaService: AreasService ) {
        super( Area, areaService);
    }
}
