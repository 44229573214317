import { Component, ViewEncapsulation } from '@angular/core';
import { BrigadierComponent } from '../dashboard/brigadier.component';
import { Router } from '@angular/router';
import { AuthenticationService } from '../services';

@Component( {
    selector: 'app-mobile-podium',
    templateUrl: './mobile-podium.component.html',
    styleUrls: ['./mobile-podium.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class MobilePodiumComponent extends BrigadierComponent {

    constructor() {
        super()
    }
}
