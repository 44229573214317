import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component( {
    selector: 'node-blacklisting',
    styles: ['label {display: block;max-width: 100%;font-weight: 500;color: rgba(0,0,0,0.9);}'],
    template: `<div class="t-col-12"  [formGroup]="form">
                <div class="t-col-12">
                    <mat-checkbox [disabled]="disabled" id="blacklisted" name="blacklisted" formControlName="blacklisted">Blacklisted</mat-checkbox>
                </div>
                <div class="t-col-12 t-col-nopad" *ngIf="form.controls.blacklisted.value">
                    <div class="t-col-12 t-col-nopad"  >
                        <mat-form-field>
                             <input matInput [disabled]="disabled" [matDatepicker]="pickerStart" placeholder="Blacklisting start" readonly formControlName="blacklistingStart"
                                 required="form.controls.blacklisted.value">
                             <mat-datepicker-toggle mdSuffix [disabled]="disabled" [for]="pickerStart"></mat-datepicker-toggle>
                        </mat-form-field>
                        <mat-datepicker #pickerStart [startAt]="blStart" (selectedChanged)="setBlStart($any($event))"></mat-datepicker>
                    </div>
                    <div class="t-col-12 t-col-nopad" *ngIf="form.controls.blacklisted.value">
                        <mat-form-field>
                             <input matInput [disabled]="disabled" [matDatepicker]="pickerEnd" placeholder="Blacklisting end" readonly formControlName="blacklistingEnd"
                                 required="form.controls.blacklisted.value">
                             <mat-datepicker-toggle mdSuffix [disabled]="disabled" [for]="pickerEnd"></mat-datepicker-toggle>
                        </mat-form-field>
                        <mat-datepicker #pickerEnd [startAt]="blEnd" (selectedChanged)="setBlEnd($any($event))"></mat-datepicker>
                    </div>
                 </div>
               </div>`
})
export class NodeBlacklistingComponent {

    public blStart = new Date();
    public blEnd = new Date();

    public _form!: FormGroup;

    @Input()
    public disabled: boolean = false;

    get isValid() { return this.form.valid; }

    public setBlStart( d: Date ) {
        d.setHours( 12, 0, 0, 0 );
        this.form.controls['blacklistingStart'].setValue( d, { onlySelf: true });
        this.blStart = this.form.controls['blacklistingStart'].value;
    }

    public setBlEnd( d: Date ) {
        d.setHours( 12, 0, 0, 0 );
        this.form.controls['blacklistingEnd'].setValue( d, { onlySelf: true });
        this.blEnd = this.form.controls['blacklistingEnd'].value;
    }

    get form(): FormGroup {
        return this._form;
    }

    @Input()
    set form( value: FormGroup) {
        this._form = value;

        if ( !!value.controls['blacklistingStart'])
            this.blStart = value.controls['blacklistingStart'].value;
        if ( !!value.controls['blacklistingEnd'])
            this.blEnd = value.controls['blacklistingEnd'].value;
    }

}
