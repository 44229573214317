<p-confirmDialog header="Confirmation" icon="fa fa-question-circle" width="425" #cd>
   <p-footer>
        <button type="button" pButton icon="fa-check" label="Yes" (click)="cd.accept()"></button>
        <button type="button" pButton icon="fa-close" label="Cancel" (click)="cd.reject()"></button>
    </p-footer>
</p-confirmDialog>

<search-form #searchForm (search)="setFilter([
    {'field':'name', 'value':nameQuery.value},
    {'field':'serialnr', 'value':snQuery.value},
    {'field':'description', 'value':desQuery.value}
    ])" (reset)="nameQuery.value=''; snQuery.value='';desQuery.value=''; setFilter([]); searchForm.hide()">
    <mat-form-field class="full-width">
        <input matInput #nameQuery placeholder="Filter by name" [autofocus]="true" class="full-width"/>
    </mat-form-field>
    <mat-form-field class="full-width">
        <input matInput #snQuery placeholder="Filter by serial number" class="full-width"/>
    </mat-form-field>
    <mat-form-field class="full-width">
        <input matInput #desQuery placeholder="Filter by description" class="full-width"/>
    </mat-form-field>
</search-form>

<locks-list [items]="(items | async) || []" *ngIf="!editing" (selected)="itemSelected($event)" (added)="newItem()" (search)="searchForm.show()" (deleted)="deleteItem($event)"
[(sortSettings)]="sortSettings" [(paging)]="paging"></locks-list>
<lock-form [item]="(selectedItem | async) || undefined" *ngIf="!!editing" [errorMsgs]="(errorMsg | async) || []" (canceled)="editCancel()" (saved)="saveItem($event)"></lock-form>
