import { BaseItemService } from './base-item.service';
import { Node } from '../model';

export { baseURL, TayaracCompany } from './base-item.service';

export abstract class ItemService<Item extends Node> extends BaseItemService<Item> {
    constructor() {
        super();
    }

    public getIdentity( item: Item): number | string | undefined {
        return item.id;
    }
}
