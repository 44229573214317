<span *ngIf="!(ap | async)">Loading...</span>

<div class="full-page">
  <div
    class="full-page-content t-grid t-flex-column t-flex-nowrap t-gap-4 t-align-content-center"
  >
    <div class="t-flex-auto t-col-12">
      <div class="area-content-wrapper">
        <div class="area-content chart-area">
          <ts-chart
            type="bar"
            width="1000px"
            height="120px"
            [data]="statsCount | async"
            [options]="statsCountOptions"
            [legend]="false"
          ></ts-chart>
        </div>
        <div class="area areal">
          <div class="area-content"></div>
        </div>
        <div class="area arear">
          <div class="area-content"></div>
        </div>
      </div>
    </div>
    <div class="t-flex-auto t-col-12">
      <div class="area-content-wrapper">
        <div class="area-content chart-area">
          <ts-chart
            type="bar"
            width="1000px"
            height="120px"
            [data]="statsThroughput | async"
            [options]="statsThroughputOptions"
            [legend]="true"
          ></ts-chart>
        </div>
        <div class="area areal">
          <div class="area-content"></div>
        </div>
        <div class="area arear">
          <div class="area-content"></div>
        </div>
      </div>
    </div>
    <div class="t-flex-auto t-col-12">
      <div class="area-content-wrapper">
        <div class="area-content chart-area stats3">
          <ts-chart
            type="bar"
            width="1000px"
            height="120px"
            [data]="statsPickups | async"
            [options]="statsPickupsOptions"
            [legend]="false"
          ></ts-chart>
        </div>
        <div class="area areal">
          <div class="area-content"></div>
        </div>
        <div class="area arear">
          <div class="area-content"></div>
        </div>
      </div>
    </div>
    <div class="t-flex-auto t-col-12" *ngIf="false">
      <div class="area-content-wrapper">
        <div class="area-content chart-area alerts">
          <ul>
            <li
              *ngFor="let a of ctrls | async"
              [ngClass]="{ red: a < 2, blue: a > 4 }"
            >
              {{ a }}
            </li>
          </ul>
        </div>
        <div class="area areal">
          <div class="area-content"></div>
        </div>
        <div class="area arear">
          <div class="area-content"></div>
        </div>
      </div>
    </div>
  </div>
</div>
