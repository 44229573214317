import {
  takeUntil,
  tap,
  distinctUntilChanged,
  map,
  filter,
  retryWhen,
  repeatWhen,
  share,
  delay,
} from 'rxjs/operators';
import { Component, OnInit, OnChanges, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  WebSocketService,
  wsBaseURL,
} from '../services/web-socket-service.service';
import { dateReviver } from '../utils/utils';
import { ServiceLocator } from '../services/service.locator';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from '../services';

class SettingsModel {
  minCarsT1 = 15;
  maxCarsT1 = 10;
  carsWantedT1 = 20;
  carsWantedT0 = 60;
  maxStayT1 = 60;
  maxStayT2 = 120;
  maxStayT0 = 60;
  maxStayT2T1 = 10;
  maxCalledT1T0 = 4;
  maxCarsT1T0 = 4;
  maxStayP12P13 = 1.5;
  lastXcarsLeavingT0 = 5;
  autoInUseStart = new Date();
  autoInUseStop = new Date();
  postExpressAllowedT0 = 180; // 180 sec
  postExpressAllowedT1 = 600; // 600 sec
  minCarsEndsExpress = 20;
  t1WaitAfterExpress = 180;
  fedpolEmail = '';
  bacfinEmail = '';

  public static fromWS(value: SettingsModel): SettingsModel {
    let s = Object.assign(new SettingsModel(), value);
    s.maxStayT0 /= 60;
    s.maxStayT1 /= 60;
    s.maxStayT2 /= 60;
    s.maxStayT2T1 /= 60;
    s.maxStayP12P13 /= 60;

    let now: Date = new Date();

    let start: any = value.autoInUseStart;
    s.autoInUseStart = start.getHours
      ? start
      : new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDay(),
          start.hour,
          start.minute,
          start.second
        );
    let stop: any = value.autoInUseStop;
    s.autoInUseStop = stop.getHours
      ? stop
      : new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDay(),
          stop.hour,
          stop.minute,
          stop.second
        );

    return s;
  }
  public static toWS(value: SettingsModel): SettingsModel {
    let s = Object.assign(new SettingsModel(), value);
    s.maxStayT0 *= 60;
    s.maxStayT1 *= 60;
    s.maxStayT2 *= 60;
    s.maxStayT2T1 *= 60;
    s.maxStayP12P13 *= 60;
    return s;
  }

  public equals(r: SettingsModel): boolean {
    try {
      let res =
        this.minCarsT1 === r.minCarsT1 &&
        this.maxCarsT1 === r.maxCarsT1 &&
        this.carsWantedT1 === r.carsWantedT1 &&
        this.carsWantedT0 === r.carsWantedT0 &&
        this.maxStayT1 === r.maxStayT1 &&
        this.maxStayT2 === r.maxStayT2 &&
        this.maxStayT0 === r.maxStayT0 &&
        this.maxStayT2T1 === r.maxStayT2T1 &&
        this.maxCalledT1T0 === r.maxCalledT1T0 &&
        this.maxCarsT1T0 === r.maxCarsT1T0 &&
        this.maxStayP12P13 === r.maxStayP12P13 &&
        this.lastXcarsLeavingT0 === r.lastXcarsLeavingT0 &&
        this.autoInUseStart.getTime() === r.autoInUseStart.getTime() &&
        this.autoInUseStop.getTime() === r.autoInUseStop.getTime() &&
        this.postExpressAllowedT0 === r.postExpressAllowedT0 &&
        this.postExpressAllowedT1 === r.postExpressAllowedT1 &&
        this.minCarsEndsExpress === r.minCarsEndsExpress &&
        this.t1WaitAfterExpress === r.t1WaitAfterExpress &&
        this.fedpolEmail === r.fedpolEmail &&
        this.bacfinEmail === r.bacfinEmail;

      return res;
    } catch (e) {}
    return false;
  }
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit, OnChanges, OnDestroy {
  protected form: FormGroup;
  protected settings: SettingsModel = new SettingsModel();

  protected wsService: WebSocketService;
  protected wsBaseUrl: string;

  protected ngUnsubscribe: Subject<void> = new Subject<void>();

  protected errorMsgs: string[] = [];

  protected debug?: string;

  constructor(protected fb: FormBuilder, private authSvc: AuthenticationService) {
    this.wsBaseUrl = ServiceLocator.injector.get(wsBaseURL);
    this.wsService = new WebSocketService(authSvc);
    this.form = this.createForm();
  }

  ngOnInit() {
    this.updateSettings(this.settings);

    let wsSettings = this.wsService
      .connect(this.wsBaseUrl, (_) => {})
      .pipe(
        map((d) => {
          let c = JSON.parse(d.data, dateReviver);
          return c;
        }),
        filter((c) => !!c.airport),
        map((c) => c.airport.settings),
        retryWhen((failures) => {
          return failures.pipe(delay(5000));
        }),
        repeatWhen((failures) => {
          return failures.pipe(delay(5000));
        }),
        share()
      );

    wsSettings
      .pipe(
        map((s) => SettingsModel.fromWS(s)),
        distinctUntilChanged((a, b) => a.equals(b)),
        tap((s) => this.updateSettings(s)),
        takeUntil(this.ngUnsubscribe)
      )
      .subscribe();
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngOnChanges() {
    this.updateSettings(this.settings);
  }

  protected updateSettings(s: SettingsModel) {
    Object.assign(this.settings, s);
    setTimeout(() => this.form.patchValue(this.settings), 0);
  }

  protected createForm(): FormGroup {
    return this.fb.group({
      minCarsT1: [0, [Validators.required]],
      maxCarsT1: [0, [Validators.required]],
      carsWantedT1: [0, [Validators.required]],
      carsWantedT0: [0, [Validators.required]],

      maxStayT1: [0, [Validators.required]],
      maxStayT2: [0, [Validators.required]],
      maxStayT2T1: [0, [Validators.required]],
      maxStayT0: [0, [Validators.required]],

      maxCalledT1T0: [0, [Validators.required]],
      maxCarsT1T0: [0, [Validators.required]],
      maxStayP12P13: [0, [Validators.required]],
      lastXcarsLeavingT0: [0, [Validators.required]],
      autoInUseStart: [0, [Validators.required]],
      autoInUseStop: [0, [Validators.required]],
      postExpressAllowedT0: [0, [Validators.required]],
      postExpressAllowedT1: [0, [Validators.required]],
      minCarsEndsExpress: [0, [Validators.required]],
      t1WaitAfterExpress: [0, [Validators.required]],
      bacfinEmail: ['', [Validators.email]],
      fedpolEmail: ['', [Validators.email]],
    });
  }

  public save() {
    console.log('Form value: ', this.form.value);
    Object.assign(this.settings, this.form.value);
    this.wsService.send({
      action: 'SET_SETTINGS',
      payload: SettingsModel.toWS(this.settings),
    });
  }

  public cancel() {
    this.updateSettings(this.settings);
  }
}
