import { Pipe, PipeTransform } from '@angular/core';

class NameValue {
    constructor( public f: string, public v: string){}
}

@Pipe( {
    name: 'dataFilter'
})
export class DataFilterPipe implements PipeTransform {


    transform( items: any[], field: string | NameValue[], value: string ): any[] {
        if ( !items ) return [];
        if ( typeof field === 'string') {
            return items.filter( it => it[field].indexOf( value ) !== -1 );
        } else {
            return items.filter( (it) => {
                for (let nv of field) {
                    if ( it[nv.f].indexOf( nv.v ) === -1 )
                        return false;
                }
                return true;
            });
        }
    }
}
