<form *ngIf="selectedItem" (ngSubmit)="save(selectedItem)" [formGroup]="form">

	<div fxLayout="row" fxLayoutAlign="center">
	    <div fxFlex="0 1 auto">
		    <p-panel>
		        <p-header>
						<h2 *ngIf="selectedItem.id">Editing '{{originalName}}'</h2>
						<h2 *ngIf="!selectedItem.id">Create New {{entityName}}</h2>
		        </p-header>

		        <div class="t-grid">
		            <!-- Item specifics start here -->
					<div class="t-col-3">
					    <node-unique-name [item]="selectedItem" [service]="itemService" field="serialnr" placeholder="Serial Nr" [input]="$any(form.controls.serialn)"></node-unique-name>
					</div>
					<div class="t-col-3">
					    <node-unique-name [item]="selectedItem" [service]="itemService" [input]="$any(form.controls.name)"></node-unique-name>
					</div>
                    <div class="t-col-3">
                         <mat-form-field class="full-width" >
                            <input matInput type="text" formControlName="keyRFID" placeholder="RFID tag" type="text" id="keyRFID" name="keyRFID" [required]="true">
                            <mat-hint align="start" [class.alert-danger]="!form.controls.keyRFID.valid" *ngIf="!form.controls.keyRFID.valid">{{form.controls.keyRFID.errors?.error}}</mat-hint>
                         </mat-form-field>
                    </div>
                    <div class="t-col-3">
                         <div class="full-width">
                            <mat-checkbox id="carBooster" name="carBooster" formControlName="carBooster">Is a car booster</mat-checkbox>
                         </div>
                    </div>

					<div class="t-col-6">
					     <mat-form-field class="full-width">
						    <input matInput placeholder="Description" type="text" id="description" name="description" formControlName="description"/>
						 </mat-form-field>
					</div>


					<!-- <node-validity class="t-col-5" [(validity)]="selectedItem.validity"></node-validity> -->
			        <div class="t-col-6"></div>

					<ul class="t-col-12" *ngIf="errorMsgs?.length" class="alert alert-danger">
					  <li *ngFor="let err of errorMsgs">{{err}}</li>
					</ul>
		            <!-- Item specifics end here -->
		        </div>

			    <p-footer>
			        <button mat-raised-button color="primary" type="submit" [disabled]="!isValid">Submit</button>
			        <button mat-raised-button (click)="cancel(selectedItem)" type="button">Cancel</button>
			    </p-footer>

		    </p-panel>
	    </div>
	</div>
</form>

<div class="t-col-12" *ngIf="debug">
    <pre>{{ debug | json }}</pre>
</div>

<div *ngIf="!selectedItem">{{entityName}} does not exists</div>

