import { Component, OnInit } from '@angular/core';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

@Component( {
    selector: 'dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
} )
export class DashboardComponent implements OnInit {

    public deviceInfo?: DeviceInfo = undefined;

    constructor(private deviceService: DeviceDetectorService) {
    }

    ngOnInit() {
        this.deviceInfo = this.deviceService.getDeviceInfo();
    }
}
