<div class="container-fluid">
       <p-table [value]="items" [multiSortMeta]="sortSettings?.multisortmeta" [loading]="loading"
         selectionMode="single" (onRowSelect)="select($event.data)" [resizableColumns]="false"
         sortMode="multiple" [rows]="rows"  [first]="first" (onSort)="onSort($event)" (onPage)="onPage($event)"
         [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" [reorderableColumns]="true">
           <ng-template pTemplate="caption">
               <div class="pull-left"><h4>Administrators</h4></div>

               <div class="pull-right">
                   <button mat-icon-button color="primary" (click)="add()" type="button">
                      <mat-icon fontSet="fa" fontIcon="fa-plus" class="mat-24"></mat-icon>
                   </button>
                   <button mat-icon-button color="primary" (click)="search.emit()" type="button">
                      <mat-icon fontSet="fa" fontIcon="fa-search" class="mat-24"></mat-icon>
                   </button>
               </div>
               <div class="clearfix"></div>
           </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="firstname" pResizableColumn>First name <p-sortIcon field="firstname"></p-sortIcon></th>
                    <th pSortableColumn="lastname" pResizableColumn>Last name <p-sortIcon field="lastname"></p-sortIcon></th>
                    <th pSortableColumn="email" pResizableColumn>Email <p-sortIcon field="email"></p-sortIcon></th>
                    <th pSortableColumn="phone" pResizableColumn>Phone <p-sortIcon field="phone"></p-sortIcon></th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-admin>
                <tr [pSelectableRow]="rowData">
                    <td>{{ admin.firstname }}</td>
                    <td>{{ admin.lastname }}</td>
                    <td>{{ admin.email }}</td>
                    <td>{{ admin.phone }}</td>
                    <td>
                      <button mat-icon-button color="accent" (click)="remove($event, admin)">
                        <mat-icon fontSet="fa" fontIcon="fa-trash-can"></mat-icon>
                      </button>
                    </td>
                </tr>
            </ng-template>

       </p-table>
</div>
