import { Validity } from './validity';

export class Node {
    id?: number;
    description?: string;
    disabled?: boolean;
    disabledDate?: string;
    reasonToDisable?: string;
    validity?: Validity;
    extensions?: any[];
    properties?: any;
}

export class NamedNode extends Node {
    name?: string;
}
