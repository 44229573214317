import { tap, map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';

import { Store, Action, ActionReducer } from '@ngrx/store';
import { Filter, StoreAction } from '../store/tayarac-store';
import { clone } from '../utils/utils';

import { ItemService } from './item.service';
import { Key, Validity, JsonResponse } from '../model';

export class KEYS_ACTIONS {
  static SET_KEYS = 'SET_KEYS';
  static CREATE_KEY = 'CREATE_KEY';
  static UPDATE_KEY = 'UPDATE_KEY';
  static DELETE_KEY = 'DELETE_KEY';
  static SELECT_KEY = 'SELECT_KEY';
  static SET_KEYS_FILTER = 'SET_KEYS_FILTER';
  static CLEAR_KEYS_FILTER = 'CLEAR_KEYS_FILTER';
}

// The "keys" reducer performs actions on our list of keys
export const keys = keysFn;
export function keysFn(state: Key[] = [], action: StoreAction) {
  switch (action.type) {
    case KEYS_ACTIONS.SET_KEYS:
      return action.payload;
    case KEYS_ACTIONS.CREATE_KEY:
      let x = state.filter((item) => item.id === action.payload.id).length;
      return x ? state : [...state, action.payload];
    case KEYS_ACTIONS.UPDATE_KEY:
      return state.map((item) => {
        return item.id === action.payload.id ? clone(action.payload) : item;
      });
    case KEYS_ACTIONS.DELETE_KEY:
      return state.filter((item) => {
        return item.id !== action.payload.id;
      });
    default:
      return state;
  }
}

// The "selectedKey" reducer handles the currently selected key
export const selectedKey = selectedKeyFn;
export function selectedKeyFn(state: Key | null = null, action: StoreAction) {
  switch (action.type) {
    case KEYS_ACTIONS.SELECT_KEY:
      return action.payload;
    default:
      return state;
  }
}

export const keysFilter = keysFilterFn;
export function keysFilterFn(state: Filter[] = [], action: StoreAction) {
  switch (action.type) {
    case KEYS_ACTIONS.SET_KEYS_FILTER:
      return action.payload;
    case KEYS_ACTIONS.CLEAR_KEYS_FILTER:
      return [];
    default:
      return state;
  }
}

@Injectable()
export class KeysService extends ItemService<Key> {
  private _keys = this.store?.select('keys');
  private _selectedKey = this.store?.select('selectedKey');
  private _filter = this.store?.select('keysFilter');

  constructor() {
    super();
  }

  get items(): Observable<Array<Key>> {
    if (!this._keys) this._keys = this.store?.select('keys');
    return this._keys;
  }

  get selectedItem(): Observable<Key> {
    if (!this._selectedKey)
      this._selectedKey = this.store?.select('selectedKey');
    return this._selectedKey;
  }

  get filter(): Observable<Array<Filter>> {
    if (!this._filter) this._filter = this.store?.select('keysFilter');
    return this._filter;
  }

  get baseUrl(): string {
    return this._baseUrl + '/api/keys';
  }

  public loadItems(): void {
    this.clearErrors();

    super
      ._getItems()
      .pipe(
        map((ks) => this.sanitizeItems(ks)),
        map((ks) => ({ type: KEYS_ACTIONS.SET_KEYS, payload: ks }))
      )
      .subscribe((action) => this.store.dispatch(action));
  }

  public deleteItem(key: Key): void {
    this.clearErrors();
    super
      ._deleteItem(key.id)
      .pipe(map(() => ({ type: KEYS_ACTIONS.DELETE_KEY, payload: key })))
      .subscribe((action) => this.store.dispatch(action));
  }

  public selectItem(key: Key): void {
    this.clearErrors();
    this.store.dispatch({ type: KEYS_ACTIONS.SELECT_KEY, payload: key });
  }

  public setFilter(filters: any) {
    this.store.dispatch({
      type: KEYS_ACTIONS.SET_KEYS_FILTER,
      payload: filters,
    });
  }

  protected createItem(key: Key): Observable<Key> {
    return super
      ._addItem(key)
      .pipe(
        tap((k) =>
          this.store.dispatch({ type: KEYS_ACTIONS.CREATE_KEY, payload: k })
        )
      );
  }

  protected updateItem(key: Key): Observable<Key> {
    return super
      ._updateItem(key)
      .pipe(
        tap((k) =>
          this.store.dispatch({ type: KEYS_ACTIONS.UPDATE_KEY, payload: k })
        )
      );
  }
}
