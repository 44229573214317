

import {  Directive } from '@angular/core';
import { NamedNode } from '../model';
import { ItemService } from '../services/item.service';
import { BaseEntitiesComponent } from './base-entities.component';

@Directive()
export class EntitiesComponent<Item extends NamedNode> extends BaseEntitiesComponent<Item> {

    constructor( protected override ctor: { new (): Item }, protected override itemService: ItemService<Item> ) {
        super( ctor, itemService);
    }

}
