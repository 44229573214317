import { Component, Input, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';

type vType = { 'X': number, 'A': number, 'U': number, 'W': number };

@Component( {
    selector: 'display-area',
    templateUrl: './disp-area.component.html',
    styleUrls: ['./disp-area.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class DisplayAreaComponent implements OnInit, OnDestroy {

    @Input()
    public type: string = 'TAXI';

    @Input()
    public present: number = 0;

    @Input()
    public inApproach: number = 0;

    private typeConv: vType = { 'X': 0x1000, 'A': 0x100, 'U': 0x10, 'W': 0x1 };

    constructor( ) {
    }

    ngOnInit() { };
    ngOnDestroy() { };

    public get hasX() {
        return this.type === 'BREAK' || this.type === 'MINIVAN';
    };


    public isPresent( type: keyof vType ) : boolean {
        if( !this.typeConv.hasOwnProperty(type))
            return false;

        return 0 !== (this.present & this.typeConv[type]);
    }

    public isApproaching( type: keyof vType ) : boolean {
        if( !this.typeConv.hasOwnProperty(type))
            return false;

        return 0 !== (this.inApproach & this.typeConv[type]);
    }

    public getClasses(type: keyof vType) {
        let a = ['car-letter'];
        if( this.isPresent(type)) {
            a.push('car-present');
        } else if(this.isApproaching(type)) {
            a.push('car-approaching');
        }

        return a;
    }

}
