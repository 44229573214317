import { tap, map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Store, Action } from '@ngrx/store';
import { Filter, StoreAction } from '../store/tayarac-store';
import { clone } from '../utils/utils';

import { ItemService } from './item.service';
import { Vehicle, Validity, JsonResponse } from '../model';

export class VEHICLES_ACTIONS {
  static SET_VEHICLES = 'SET_VEHICLES';
  static CREATE_VEHICLE = 'CREATE_VEHICLE';
  static UPDATE_VEHICLE = 'UPDATE_VEHICLE';
  static DELETE_VEHICLE = 'DELETE_VEHICLE';
  static SELECT_VEHICLE = 'SELECT_VEHICLE';
  static SET_VEHICLES_FILTER = 'SET_VEHICLES_FILTER';
  static CLEAR_VEHICLES_FILTER = 'CLEAR_VEHICLES_FILTER';
}

// The "vehicles" reducer performs actions on our list of vehicles
export const vehicles = vehiclesFn;
export function vehiclesFn(state: Vehicle[] = [], action: StoreAction) {
  switch (action.type) {
    case VEHICLES_ACTIONS.SET_VEHICLES:
      return action.payload;
    case VEHICLES_ACTIONS.CREATE_VEHICLE:
      let x = state.filter((item) => item.id === action.payload.id).length;
      return x ? state : [...state, action.payload];
    case VEHICLES_ACTIONS.UPDATE_VEHICLE:
      return state.map((item) => {
        return item.id === action.payload.id ? clone(action.payload) : item;
      });
    case VEHICLES_ACTIONS.DELETE_VEHICLE:
      return state.filter((item) => {
        return item.id !== action.payload.id;
      });
    default:
      return state;
  }
}

// The "selectedVehicle" reducer handles the currently selected vehicle
export const selectedVehicle = selectedVehicleFn;
export function selectedVehicleFn(state: Vehicle|null = null, action: StoreAction) {
  switch (action.type) {
    case VEHICLES_ACTIONS.SELECT_VEHICLE:
      return action.payload;
    default:
      return state;
  }
}

export const vehiclesFilter = vehiclesFilterFn;
export function vehiclesFilterFn(state: Filter[] = [], action: StoreAction) {
  switch (action.type) {
    case VEHICLES_ACTIONS.SET_VEHICLES_FILTER:
      return action.payload;
    case VEHICLES_ACTIONS.CLEAR_VEHICLES_FILTER:
      return [];
    default:
      return state;
  }
}

@Injectable()
export class VehiclesService extends ItemService<Vehicle> {
  private _vehicles = this.store?.select('vehicles');
  private _selectedVehicle = this.store?.select('selectedVehicle');
  private _filter = this.store?.select('vehiclesFilter');

  constructor() {
    super();
  }

  get items(): Observable<Array<Vehicle>> {
    if (!this._vehicles) this._vehicles = this.store?.select('vehicles');
    return this._vehicles;
  }

  get selectedItem(): Observable<Vehicle> {
    if (!this._selectedVehicle)
      this._selectedVehicle = this.store?.select('selectedVehicle');
    return this._selectedVehicle;
  }

  get filter(): Observable<Array<Filter>> {
    if (!this._filter) this._filter = this.store?.select('vehiclesFilter');
    return this._filter;
  }

  get baseUrl(): string {
    return this._baseUrl + '/api/vehicles';
  }

  public loadItems(): void {
    this.clearErrors();

    super
      ._getItems()
      .pipe(
        map((vs) => this.sanitizeItems(vs)),
        map(
          (vs) =>
            new StoreAction({
              type: VEHICLES_ACTIONS.SET_VEHICLES,
              payload: vs,
            })
        )
      )
      .subscribe((action) => this.store.dispatch(action));
  }

  public deleteItem(vehicle: Vehicle): void {
    this.clearErrors();
    super
      ._deleteItem(vehicle.id)
      .pipe(
        map(
          () =>
            new StoreAction({
              type: VEHICLES_ACTIONS.DELETE_VEHICLE,
              payload: vehicle,
            })
        )
      )
      .subscribe((action) => this.store.dispatch(action));
  }

  public selectItem(vehicle: Vehicle): void {
    this.clearErrors();
    this.store.dispatch(
      new StoreAction({
        type: VEHICLES_ACTIONS.SELECT_VEHICLE,
        payload: vehicle,
      })
    );
  }

  public setFilter(filters: any) {
    this.store.dispatch(
      new StoreAction({
        type: VEHICLES_ACTIONS.SET_VEHICLES_FILTER,
        payload: filters,
      })
    );
  }

  public getFullReport(): Observable<string> {
    //        let data: string = "field1,field2,field3\nfield4,field5,field6\n";
    //        return Observable.of(data);

    let params: HttpParams = new HttpParams();

    return this.httpClient
      .get<JsonResponse<string>>(this.baseUrl + '/full-report', {
        params: params,
      })
      .pipe(
        map((jr) => {
          console.log('Items data: ', jr);
          if (jr.status != 'SUCCESS') throw { errorMsg: jr.errorMsg };
          return jr.data as string;
        })
      );
  }

  protected createItem(vehicle: Vehicle): Observable<Vehicle> {
    return super
      ._addItem(vehicle)
      .pipe(
        tap((v) =>
          this.store.dispatch(
            new StoreAction({
              type: VEHICLES_ACTIONS.CREATE_VEHICLE,
              payload: v,
            })
          )
        )
      );
  }

  protected updateItem(vehicle: Vehicle): Observable<Vehicle> {
    return super
      ._updateItem(vehicle)
      .pipe(
        tap((v) =>
          this.store.dispatch(
            new StoreAction({
              type: VEHICLES_ACTIONS.UPDATE_VEHICLE,
              payload: v,
            })
          )
        )
      );
  }
}
