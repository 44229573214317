<div fxLayout="row" fxLayoutAlign="center">
    <div fxFlex="0 1 auto">
		<p-panel>
		    <p-header>
		        <h2>Login</h2>
		    </p-header>
		    <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate  *ngIf="false">
		        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }" *ngIf="false">
		            <label for="username">Username</label>
		            <input type="text" class="form-control" name="username" [(ngModel)]="model.username" #username="ngModel" required />
		            <div *ngIf="f.submitted && !username.valid" class="help-block">Username is required</div>
		        </div>
		        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }" *ngIf="false">
		            <label for="password">Password</label>
		            <input type="password" class="form-control" name="password" [(ngModel)]="model.password" #password="ngModel" required />
		            <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
		        </div>
		        <div class="form-group">
		            <button [disabled]="loading" class="btn btn-primary">Login</button>
		            <div class="forgotten-password" *ngIf="false"><a [routerLink]="['/login/forgotten']">Forgot your password</a></div>
		            <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
		        </div>
		        <div *ngIf="error" class="alert alert-danger">{{error}}</div>
		    </form>
		</p-panel>
    </div>
</div>
