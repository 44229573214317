
import {map} from 'rxjs/operators';
import { Component, Input } from '@angular/core';

import { KeyholdersComponent } from '../entity-component/keyholders.component';

import { Person, Address } from '../model';
import { PersonsService } from '../services/persons.service';

export { PersonsListComponent } from './persons-list.component';
export { PersonFormComponent } from './person-form.component';

@Component( {
    selector: 'app-persons',
    templateUrl: './persons.component.html',
    styleUrls: ['./persons.component.scss'],
} )
export class PersonsComponent extends KeyholdersComponent<Person> {

    constructor( protected personService: PersonsService ) {
        super( Person, personService );
    }

    override ngOnInit() {
        super.ngOnInit()

        this.sortSettings = {
            multisortmeta: [{ field: 'lastName', order: 1 }]
        }

        this.items = this.items.pipe(map( keyholders => {
            keyholders.forEach( kh => {
                //                kh['keyRFID'] = !!kh.keys && 0 < kh.keys.length && !!kh.keys[0].key && !!kh.keys[0].key.tokens && 0 < kh.keys[0].key.tokens.length ? kh.keys[0].key.tokens[0].identity : "";
                (kh as any)['keyRFID'] = !!kh.keys && 0 < kh.keys.length && !!kh.keys[0].key ? kh.keys[0].key.identity : "";
            } );
            return keyholders;
        } ));
    }

    public downloadData() {
        this.personService.getFullReport().subscribe({
          next: data => this.downloadFile( data ),//console.log(data),
          error: err => console.log( "Error downloading the file." ),
          complete: () => console.info( "OK" )
        })
    }

    downloadFile( data: string ) {
        //        var blob = new Blob( [data], { type: 'text/csv' } );
        //        var url = window.URL.createObjectURL( blob );
        //        window.open( url );

        const blob: Blob = new Blob( [data], { type: 'text/csv' } );
        const fileName: string = 'drivers.csv';

        const nav = (window.navigator as any);
        if ( nav.msSaveOrOpenBlob ) {
            nav.msSaveOrOpenBlob( blob, fileName);
        } else {
            const objectUrl: string = URL.createObjectURL( blob );
            const a: HTMLAnchorElement = document.createElement( 'a' ) as HTMLAnchorElement;

            a.href = objectUrl;
            a.download = fileName;
            document.body.appendChild( a );
            a.click();

            document.body.removeChild( a );
            URL.revokeObjectURL( objectUrl );
        }
    }
}

