import { Component, ViewEncapsulation } from '@angular/core';

import { TfCompany } from '../model';
import { EntityListComponent } from '../entity-component/entity-list.component';

@Component( {
    selector: 'tfcompanies-list',
    templateUrl: './tfcompanies-list.component.html',
    styleUrls: ['./tfcompanies-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class TfCompaniesListComponent extends EntityListComponent<TfCompany> {

    protected getEditItemPath(): string {
        return '/edit-tfcompany';
    }
}
