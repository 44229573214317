
import {filter, tap,  map } from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';

import { Store, Action } from '@ngrx/store';
import { Filter, StoreAction } from '../store/tayarac-store';
import { clone } from '../utils/utils';

import { BaseItemService } from './base-item.service';
import { Admin, Role, JsonResponse } from '../model';

import { dateReviver } from '../utils/utils';

export class ADMINS_ACTIONS {
    static SET_ADMINS = 'SET_ADMINS';
    static CREATE_ADMIN = 'CREATE_ADMIN';
    static UPDATE_ADMIN = 'UPDATE_ADMIN';
    static DELETE_ADMIN = 'DELETE_ADMIN';
    static SELECT_ADMIN = 'SELECT_ADMIN';
    static SET_ADMINS_FILTER = 'SET_ADMINS_FILTER';
    static CLEAR_ADMINS_FILTER = 'CLEAR_ADMINS_FILTER';
}

// The 'admins' reducer performs actions on our list of admins
export const admins = adminsFn;
export function adminsFn( state: Admin[] = [], action: StoreAction ) {
    switch ( action.type ) {
        case ADMINS_ACTIONS.SET_ADMINS:
            return action.payload;
        case ADMINS_ACTIONS.CREATE_ADMIN:
            let x = state.filter( item => item.id === action.payload.id ).length;
            return x ? state : [...state, action.payload];
        case ADMINS_ACTIONS.UPDATE_ADMIN:
            return state.map( item => {
                return item.id === action.payload.id ? clone( action.payload ) : item;
            } );
        case ADMINS_ACTIONS.DELETE_ADMIN:
            return state.filter( item => {
                return item.id !== action.payload.id;
            } );
        default:
            return state;
    }
};

// The 'selectedAdmin' reducer handles the currently selected admin
export const selectedAdmin = selectedAdminFn;
export function selectedAdminFn( state: Admin|null = null, action: StoreAction ) {
    switch ( action.type ) {
        case ADMINS_ACTIONS.SELECT_ADMIN:
            return action.payload;
        default:
            return state;
    }
};

export const adminsFilter = adminsFilterFn;
export function adminsFilterFn( state: Filter[] = [], action: StoreAction ) {
    switch ( action.type ) {
        case ADMINS_ACTIONS.SET_ADMINS_FILTER:
            return action.payload;
        case ADMINS_ACTIONS.CLEAR_ADMINS_FILTER:
            return [];
        default:
            return state;
    }
}

@Injectable()
export class AdminsService extends BaseItemService<Admin> {

    private _admins: Observable<Array<Admin>> = this.store?.select( 'admins' );
    private _selectedAdmin: Observable<Admin> = this.store?.select( 'selectedAdmin' );
    private _filter: Observable<Array<Filter>> = this.store?.select( 'adminsFilter' );

    constructor() {
        super();
    }

    public getIdentity( item: Admin ): number | string | undefined {
        return item.id;
    }

    get items(): Observable<Array<Admin>> {
        if ( !this._admins ) {
            this._admins = this.store?.select( 'admins' );
        }
        return this._admins;
    };

    get selectedItem(): Observable<Admin> {
        if ( !this._selectedAdmin ) {
            this._selectedAdmin = this.store?.select( 'selectedAdmin' );
        }
        return this._selectedAdmin;
    };

    get filter(): Observable<Array<Filter>> {
        if ( !this._filter ) {
            this._filter = this.store?.select( 'adminsFilter' );
        }
        return this._filter;
    };

    get baseUrl(): string { return this._baseUrl + '/api/admins'; };


    protected override init() {
        super.init();

        this.wsService.asObservable()?.pipe(
            map( d => {
                return JSON.parse( d.data, dateReviver );
            } ),filter( d => {
                return ( !!d.admin ) && ( !!d.action );
            } ),)
            .subscribe( d => {
                console.log( 'Admin: ', d.admin );
                switch ( d.action ) {
                    case 'CREATED':
                        this.store.dispatch( { type: ADMINS_ACTIONS.CREATE_ADMIN, payload: d.admin } )
                        break;
                    case 'UPDATED':
                        this.store.dispatch( { type: ADMINS_ACTIONS.UPDATE_ADMIN, payload: d.admin } )
                        break;
                    case 'DELETED':
                        this.store.dispatch( { type: ADMINS_ACTIONS.DELETE_ADMIN, payload: d.admin } )
                        break;
                }
            } );
    }

    public loadItems(): void {
        this.clearErrors();

        super._getItems().pipe(
            map( as => this.sanitizeItems( as ) ),
            map( as => ( { type: ADMINS_ACTIONS.SET_ADMINS, payload: as } ) ),)
            .subscribe( action => this.store.dispatch( action ) );
    }

    public deleteItem( admin: Admin ): void {
        this.clearErrors();
        super._deleteItem( admin.id ).pipe(
            map( () => ( { type: ADMINS_ACTIONS.DELETE_ADMIN, payload: admin } ) ))
            .subscribe( action => this.store.dispatch( action ) );
    }

    public selectItem( admin: Admin ): void {
        this.clearErrors();
        this.store.dispatch( { type: ADMINS_ACTIONS.SELECT_ADMIN, payload: admin } );
    }

    public setFilter( filters: any ) {
        this.store.dispatch( { type: ADMINS_ACTIONS.SET_ADMINS_FILTER, payload: filters } );
    }

    protected createItem( admin: Admin ): Observable<Admin> {
        return super._addItem( admin ).pipe(
            tap( a => this.store.dispatch( { type: ADMINS_ACTIONS.CREATE_ADMIN, payload: a } ) ));
    }

    protected updateItem( admin: Admin ): Observable<Admin> {
        return super._updateItem( admin ).pipe(
            tap( a => this.store.dispatch( { type: ADMINS_ACTIONS.UPDATE_ADMIN, payload: a } ) ));
    }

    public getRoles(): Observable<Role[]> {
        let params: HttpParams = new HttpParams().set( 'start', '-1' ).set( 'max', '-1' );

        return this.httpClient.get<JsonResponse<Role[]>>( this.baseUrl + '/roles', { params: params } ).pipe(
            //            .map(( response: HttpResponse<JsonResponse> ) => {
            //                return response.body; //JSON.parse( response.text(), dateReviver );
            //            })
            map( jr => {
                console.log( 'Items data: ', jr )
                if ( jr.status != 'SUCCESS' )
                    throw { errorMsg: jr.errorMsg };
                return jr.data as Role[];
            } ),
            map( roles => {
                roles.forEach( role => delete (role as any)['permissions'] );
                return roles;
            } ),);
    }

}
