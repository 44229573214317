import { Component, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, FormArray, Validators } from '@angular/forms';

import { Area, Entry, Lock } from '../model';
import { EntityFormComponent } from '../entity-component/entity-form.component';
import { AreasService, LocksService } from '../services';

@Component( {
    selector: 'area-form',
    templateUrl: './area-form.component.html',
    styleUrls: ['./area-form.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AreaFormComponent extends EntityFormComponent<Area> {

    protected locks: Lock[] = [];

    constructor( itemService: AreasService, locksService: LocksService ) {
        super( itemService );
        locksService.search( [{ 'field': 'entry', 'operation': 'isNull' }] ).subscribe( locks => {
            this.locks = locks;
        });

    };


    protected getEntityName(): string {
        return 'Area';
    };

    protected createForm(): FormGroup {
        return this.fb.group( {
            name: ['', [Validators.required]],
            description: '',
            capacity: [0, [Validators.required]],
            entries: this.fb.array([this.initEntry()])
        });
    }

    override ngOnChanges() {
        let _self = this;
        let entriesCtrl: FormGroup[] = [];

        this.selectedItem?.entries?.forEach(function (ent) {
            entriesCtrl.push(_self.initEntry())
        });
        this.form.setControl('entries',  this.fb.array(entriesCtrl));

        this.form.patchValue( this.selectedItem || {} );
    }

    get isValid() { return this.form.valid; }

    public override save( item: Area ) {
        console.log( "Form value: ", this.form.value );
        let x = Object.assign( new Area(), item, this.form.value );


        console.log( "Object value: ", x );
        //this.debug = x;
        super.save( x );
    }

    protected initEntry() {
        // initialize our address
        return this.fb.group({
            name: ['', Validators.required],
            direction: ['', Validators.required],
            lock: [''],
            id: null
        });
    }

    public addEntry() {
     // add address to the list
        let control = <FormArray>this.form.controls['entries'];
        control.push(this.initEntry())
        this.form.setControl('entries', control);
    }

    public removeEntry( i: number) {
        // remove address from the list
        let control = <FormArray>this.form.controls['entries'];
        control.removeAt(i);
        this.form.setControl('entries', control);
    }

    public lockSelected( lock: Lock ) {
        if ( null == lock || null == this.locks )
            return;

        this.locks = this.locks.filter( l => l.id != lock.id );
    }

    public lockDeselected( lock: Lock ) {
        if ( null == lock || null == this.locks )
            return;

        this.locks = [lock, ...this.locks];
    }
}
