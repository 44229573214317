<div class="container-fluid">
    <div>
        <p-table [value]="items" [multiSortMeta]="sortSettings?.multisortmeta" [loading]="loading"
          selectionMode="single" (onRowSelect)="select($event.data)" [resizableColumns]="true"
          sortMode="multiple" [rows]="rows"  [first]="first" (onSort)="onSort($event)" (onPage)="onPage($event)"
          [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[5,10,20]" [reorderableColumns]="true">
            <ng-template pTemplate="caption">
                <div class="pull-left"><h4>Companies</h4></div>

                <div class="pull-right">
                    <button mat-icon-button color="primary" (click)="add()" type="button">
                       <mat-icon fontSet="fa" fontIcon="fa-plus" class="mat-24"></mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="search.emit()" type="button">
                       <mat-icon fontSet="fa" fontIcon="fa-search" class="mat-24"></mat-icon>
                    </button>
                </div>
                <div class="clearfix"></div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Number</th>
                    <th>Name</th>
                    <th>Roepnaam</th>
                    <th>Email</th>
                    <th>BL</th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-rowData let-company>
                <tr [pSelectableRow]="rowData">
                    <td>{{ company.number }}</td>
                    <td>{{ company.name }}</td>
                    <td>{{ company.description }}</td>
                    <td>{{ company.properties?.email }}</td>
                    <td><i class="fa" [ngClass]="{'fa-hand':company.blacklisted, 'fa-location-arrow':!company.blacklisted}" ></i></td>
                    <td>
                      <button mat-icon-button color="accent" (click)="remove($event, company)">
                        <mat-icon fontSet="fa" fontIcon="fa-trash-can"></mat-icon>
                      </button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

    </div>
</div>
