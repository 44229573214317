<form [formGroup]="form" (ngSubmit)="save()">
  <p-panel>
    <p-header>
      <h2>TaxiFlow settings</h2>
    </p-header>

    <div class="t-grid t-flex-row t-flex-wrap">
      <!-- Item specifics start here -->
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.minCarsT1.valid ? 'warn' : 'primary' ">
          <mat-label>Min # of cars in T1 to set T2 'in use'</mat-label>
          <input matInput placeholder="" class="full-width"
            type="number" id="minCarsT1" formControlName="minCarsT1" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.minCarsT1.valid"
            *ngIf="!form.controls.minCarsT1.valid">This filed
            cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.maxCarsT1.valid ? 'warn' : 'primary' ">
          <mat-label>Max # of cars in T1 to set T2 'out of use'</mat-label>
          <input matInput placeholder="" class="full-width"
            type="number" id="maxCarsT1" formControlName="maxCarsT1" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.maxCarsT1.valid"
            *ngIf="!form.controls.maxCarsT1.valid">This filed
            cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.carsWantedT1.valid ? 'warn' : 'primary' ">
          <mat-label>Cars wanted in T1</mat-label>
          <input matInput placeholder="" class="full-width" type="number"
            id="carsWantedT1" formControlName="carsWantedT1" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.carsWantedT1.valid"
            *ngIf="!form.controls.carsWantedT1.valid">This
            filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.carsWantedT0.valid ? 'warn' : 'primary' ">
          <mat-label>Cars wanted in T0</mat-label>
          <input matInput placeholder="" class="full-width" type="number"
            id="carsWantedT0" formControlName="carsWantedT0" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.carsWantedT0.valid"
            *ngIf="!form.controls.carsWantedT0.valid">This
            filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>

      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.maxStayT2.valid ? 'warn' : 'primary' ">
          <mat-label>Max stay in T2 (min)</mat-label>
          <input matInput placeholder="" class="full-width" type="number"
            id="maxStayT2" formControlName="maxStayT2" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.maxStayT2.valid"
            *ngIf="!form.controls.maxStayT2.valid">This filed
            cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.maxStayT1.valid ? 'warn' : 'primary' ">
          <mat-label>Max stay in T1 (min)</mat-label>
          <input matInput placeholder="" class="full-width" type="number"
            id="maxStayT1" formControlName="maxStayT1" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.maxStayT1.valid"
            *ngIf="!form.controls.maxStayT1.valid">This filed
            cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.maxStayT0.valid ? 'warn' : 'primary' ">
          <mat-label>Max stay in T0 (min)</mat-label>
          <input matInput placeholder="" class="full-width" type="number"
            id="maxStayT0" formControlName="maxStayT0" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.maxStayT0.valid"
            *ngIf="!form.controls.maxStayT0.valid">This filed
            cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.maxStayT2T1.valid ? 'warn' : 'primary' ">
          <mat-label>Max stay between T2 and T1 (min)</mat-label>
          <input matInput placeholder="" class="full-width"
            type="number" id="maxStayT2T1" formControlName="maxStayT2T1" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.maxStayT2T1.valid"
            *ngIf="!form.controls.maxStayT2T1.valid">This
            filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>

      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.maxCalledT1T0.valid ? 'warn' : 'primary' ">
          <mat-label>Max # of cars called from T1 to T0</mat-label>
          <input matInput placeholder="" class="full-width"
            type="number" id="maxCalledT1T0" formControlName="maxCalledT1T0" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.maxCalledT1T0.valid"
            *ngIf="!form.controls.maxCalledT1T0.valid">This
            filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.maxCarsT1T0.valid ? 'warn' : 'primary' ">
          <mat-label>Max # of cars in transition T1 to T0</mat-label>
          <input matInput placeholder="" class="full-width"
            type="number" id="maxCarsT1T0" formControlName="maxCarsT1T0" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.maxCarsT1T0.valid"
            *ngIf="!form.controls.maxCarsT1T0.valid">This
            filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.maxStayP12P13.valid ? 'warn' : 'primary' ">
          <mat-label>Max stay in transition to P12/P13 (min)</mat-label>
          <input matInput placeholder="" class="full-width"
            type="number" id="maxStayP12P13" formControlName="maxStayP12P13" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.maxStayP12P13.valid"
            *ngIf="!form.controls.maxStayP12P13.valid">This
            filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.lastXcarsLeavingT0.valid ? 'warn' : 'primary' ">
          <mat-label>Number of cars leaving P0 to recalculate</mat-label>
          <input matInput placeholder="" class="full-width"
            type="number" id="lastXcarsLeavingT0" formControlName="lastXcarsLeavingT0" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.lastXcarsLeavingT0.valid"
            *ngIf="!form.controls.lastXcarsLeavingT0.valid">This
            filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>

      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.minCarsEndsExpress.valid ? 'warn' : 'primary' ">
          <mat-label>Min # of cars to end EXPRESS mode</mat-label>
          <input matInput placeholder='' class="full-width"
            type="number" id="minCarsEndsExpress" formControlName="minCarsEndsExpress" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.minCarsEndsExpress.valid"
            *ngIf="!form.controls.minCarsEndsExpress.valid">This
            filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.postExpressAllowedT0.valid ? 'warn' : 'primary' ">
          <mat-label>'Gratis' period for entering P0 after Express (sec)</mat-label>
          <input
            matInput placeholder=""
            class="full-width" type="number" id="postExpressAllowedT0"
            formControlName="postExpressAllowedT0" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.postExpressAllowedT0.valid"
            *ngIf="!form.controls.postExpressAllowedT0.valid">This
            filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.postExpressAllowedT1.valid ? 'warn' : 'primary' ">
          <mat-label>'Gratis' period for entering/exiting Reservoir after Express (sec)</mat-label>
          <input
            matInput
            placeholder=""
            class="full-width" type="number" id="postExpressAllowedT1"
            formControlName="postExpressAllowedT1" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.postExpressAllowedT1.valid"
            *ngIf="!form.controls.postExpressAllowedT1.valid">This filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.t1WaitAfterExpress.valid ? 'warn' : 'primary' ">
          <mat-label>Period while Exit Reservoir will be closed after EXPRESS (sec)</mat-label>
          <input matInput placeholder=""
            class="full-width" type="number" id="t1WaitAfterExpress"
            formControlName="t1WaitAfterExpress" required />
          <mat-hint align="start" [class.alert-danger]="!form.controls.t1WaitAfterExpress.valid"
            *ngIf="!form.controls.t1WaitAfterExpress.valid">This
            filed cannot be empty</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <label for="autoInUseStart" class="label">"Pt InUse" night mode - start time</label>
        <p-calendar id="autoInUseStart" formControlName="autoInUseStart" [required]="true" [timeOnly]="true"></p-calendar>
          <!-- [utc]="true" -->
      </div>
      <div class="t-col-3 t-flex-auto">
          <label for="autoInUseStop" class="label">"Pt InUse" night mode - stop time</label>
          <p-calendar id="autoInUseStop" formControlName="autoInUseStop" [required]="true" [timeOnly]="true"></p-calendar>
          <!-- [utc]="true" -->
      </div>

      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width" [color]="!form.controls.bacfinEmail.valid ? 'warn' : 'primary' ">
          <mat-label>E-mail address for BAC finances</mat-label>
          <input matInput placeholder='' class="full-width"
            type="email" id="bacfinEmail" formControlName="bacfinEmail" />
          <mat-hint align="start" [class.alert-danger]="!form.controls.bacfinEmail.valid"
            *ngIf="!form.controls.bacfinEmail.valid">This field must be a valid e-mail address</mat-hint>
        </mat-form-field>
      </div>
      <div class="t-col-3 t-flex-auto">
        <mat-form-field class="full-width"
          [color]="!form.controls.fedpolEmail.valid ? 'warn' : 'primary' ">
          <mat-label>E-mail address for FedPol</mat-label>
          <input matInput placeholder='' class="full-width" type="email"
            id="fedpolEmail" formControlName="fedpolEmail" />
          <mat-hint align="start" [class.alert-danger]="!form.controls.fedpolEmail.valid"
            *ngIf="!form.controls.fedpolEmail.valid">This field must be a valid e-mail address</mat-hint>
        </mat-form-field>
      </div>
      <!-- Item specifics end here -->
    </div>

    <ul *ngIf="errorMsgs?.length" class="alert alert-danger">
      <li *ngFor="let err of errorMsgs">{{err}}</li>
    </ul>

    <p-footer>
      <button mat-raised-button color="primary" type="submit" [disabled]="!form.valid">Submit</button>
      <button mat-raised-button (click)="cancel()" type="button">Reset</button>
    </p-footer>
  </p-panel>
</form>

<div class="t-col-12" *ngIf="debug">
	<pre>{{ debug | json }}</pre>
</div>
