
import { mergeMap, startWith, map, takeUntil, filter, share } from 'rxjs/operators';
import { Component, ViewEncapsulation, OnInit, OnChanges, Inject } from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { EMPTY, Observable } from 'rxjs';

import { Vehicle, Key } from '../model';
import { KeyholderFormComponent } from '../entity-component/keyholder-form.component';
import { VehiclesService, tokenHasAnyRole } from '../services';
import { ThemePalette } from '@angular/material/core';


@Component( {
    selector: 'vehicle-form',
    templateUrl: './vehicle-form.component.html',
    styleUrls: ['./vehicle-form.component.scss'],
    encapsulation: ViewEncapsulation.None
} )
export class VehicleFormComponent extends KeyholderFormComponent<Vehicle> implements OnInit, OnChanges {

    protected filteredKeys: Observable<Array<Key>> = EMPTY;

    constructor( protected override _itemService: VehiclesService ) {
        super( _itemService );
    }

    protected createForm(): FormGroup {
        return this.fb.group( {
            licensePlate: ['', [Validators.required]],
            tfCompany: this.fb.group( {
                id: [{ value: '', disabled: this.isFieldReadonly( 'tfCompany.id' ) }, [Validators.required]]
            } ),
            blacklisting: this.fb.group( {
                blacklisted: true,
                blacklistingStart: new Date(),
                blacklistingEnd: new Date()
            } ),
            pickup: { value: false }, //, disabled: this.isFieldReadonly( 'pickup' ) },
            dropoff: { value: false }, //, disabled: this.isFieldReadonly( 'dropoff' ) },
            p12p13: { value: false }, //, disabled: this.isFieldReadonly( 'p12p13' ) },
            carFeature: { value: false }, //, disabled: this.isFieldReadonly( 'carFeature' ) },
            carVoucher: { value: false }, //, disabled: this.isFieldReadonly( 'carVoucher' ) },
            //            prmEquipment: false,
            //            vehicleBreak: false,
            //            minivan: false,
            //            twalter: false,
            //            unitax: false,
            //            autotax: false,
            keySerial: { value: '' },
            needsDriverID: false,
            plaatsingsDatum: new Date(),
            chassisnr: '',
            numberOfSeats: [{ value: '' }, [Validators.min( 1 )]],
            merk: '',
            model: '',
            color: ''
        } );
    }

    override ngOnInit() {
        super.ngOnInit();

        let serialObs = this.form.controls['keySerial'].valueChanges.pipe(
            startWith( null ),
            map( key => {
                return key && typeof key === 'object' ? key.serialnr : key;
            } ), share(), );

        this.filteredKeys = serialObs.pipe( mergeMap( serial => serial ? this.filterKeys( serial ) : this.availableKeys() ) );

        let keyErrObs = serialObs.pipe( filter( serial => !!serial && serial.length > 0 ),
            mergeMap( serial => this.filterKeysExact( serial ) ),
            takeUntil( this.ngUnsubscribe ), )
            .subscribe( keys => {
                if ( !keys || 0 === keys.length ) {
                    this.form.controls['keySerial'].setErrors( { 'error': 'Invalid key serial' } );
                }
            }, e => { }, () => { } );

    }

    override ngOnChanges() {
        this.form.patchValue( this.selectedItem || {});
        this.form.controls['pickup'].setValue( this.pickup );
        this.form.controls['dropoff'].setValue( this.dropoff );
        this.form.controls['p12p13'].setValue( this.p12p13 );
        this.form.controls['keySerial'].setValue( this.keySerial );
        this.form.controls['blacklisting'].setValue( this.blacklisting );
        this.form.controls['needsDriverID'].setValue( this.needsDriverID );

        ['vehicleBreak', 'prmEquipment', 'minivan'].forEach( feat => {
            if ( this.selectedItem && (this.selectedItem as any)[feat] ) {
                this.form.controls['carFeature'].setValue( feat );
            }
        } );
        ['twalter', 'unitax', 'autotax'].forEach( feat => {
          if ( this.selectedItem && (this.selectedItem as any)[feat] ) {
                this.form.controls['carVoucher'].setValue( feat );
            }
        } );

        this.form.controls['plaatsingsDatum'].setValue( this.plaatsingsDatum );
        this.form.controls['chassisnr'].setValue( this.chassisnr );
        this.form.controls['merk'].setValue( this.merk );
        this.form.controls['model'].setValue( this.model );
        this.form.controls['color'].setValue( this.color );
    }

    public get needsDriverID(): boolean {
        let needsDriverID = this.getProperty( 'needsDriverID' );
        return needsDriverID ? needsDriverID : false;
    }

    public override save( item: Vehicle ) {
        console.log( 'Form value: ', this.form.value );
        let x = Object.assign( new Vehicle(), this.selectedItem, this.form.value );

        x.name = x.licensePlate;
        x = this.setAccessRule( x, 'pickup', 'PICKUP' );
        x = this.setAccessRule( x, 'dropoff', 'DROPOFF' );
        x = this.setAccessRule( x, 'p12p13', 'P12P13' );
        x = this.setKey( x );
        x = this.setValidity( x );
        x.vehicleBreak = x.carFeature === 'vehicleBreak',
            x.prmEquipment = x.carFeature === 'prmEquipment',
            x.minivan = x.carFeature === 'minivan',
            x.twalter = x.carVoucher === 'twalter',
            x.unitax = x.carVoucher === 'unitax',
            x.autotax = x.carVoucher === 'autotax'
        x.properties = {
            vehicleBreak: x.carFeature === 'vehicleBreak',
            prmEquipment: x.carFeature === 'prmEquipment',
            minivan: x.carFeature === 'minivan',
            twalter: x.carVoucher === 'twalter',
            unitax: x.carVoucher === 'unitax',
            autotax: x.carVoucher === 'autotax',
            needsDriverID: x.needsDriverID
        };

        delete x['needsDriverID'];

        x = this.setProperty( x, 'nationality' );
        x = this.setProperty( x, 'plaatsingsDatum' );
        x = this.setProperty( x, 'chassisnr' );
        x = this.setProperty( x, 'merk' );
        x = this.setProperty( x, 'model' );
        x = this.setProperty( x, 'color' );

        console.log( 'Object value: ', x );
        // this.debug = x;
        super.save( x );
    }

    protected getEntityName(): string {
        return 'Vehicle';
    }

    public selectType( event: any, feature: string ) {
        if ( this.form.controls['carFeature'].value === feature ) {
            setTimeout( () => {
                this.form.controls['carFeature'].setValue( '' );
            }, 10 );
        }
        if ( this.form.controls['carVoucher'].value === feature ) {
            setTimeout( () => {
                this.form.controls['carVoucher'].setValue( '' );
            }, 10 );
        }
    }

    public isFieldReadonly( field: string ): boolean {
        if ( field === 'licensePlate' )
            return !tokenHasAnyRole( ['Administrators'] );

        if ( field === 'blacklisting' )
            return false;

        return !tokenHasAnyRole( ['Administrators', 'Metrotax'] );
    }

    protected override availableKeys() {
        let keysObs = super.availableKeys().pipe( map( keys => {
            let ks = keys.slice();
            ks = ks.filter( k => !!k.properties && k.properties['carBooster']);
            return ks;
        } ) );

        return keysObs;
    }

    protected filterKeysArray( keys: Array<Key> ): Array<Key> {
        return !!keys ? keys.filter( k => !!k.properties && !!k.properties['carBooster'] ) : keys;
    }

    protected findKeyBySerial( serial: string ): Key | undefined {
        let key = this.keysArray.find( k => k.serialnr === serial && !!k.properties && !!k.properties['carBooster'] );
        return key;
    }

    public get category() {
        return this.getProperty( "category" );
    }

    public get plaatsingsDatum() {
        return this.getProperty( "plaatsingsDatum" );
    }

    public get chassisnr() {
        return this.getProperty( "chassisnr" );
    }

    public get merk() {
        return this.getProperty( "merk" );
    }

    public get model() {
        return this.getProperty( "model" );
    }

    public get color() {
        return this.getProperty( "color" );
    }

    protected setProperty( x: any, prop: string ) {
        x.properties = x.properties || {}
        x.properties[prop] = x[prop];
        delete x[prop];
        return x;
    }

    protected override getOriginalName(): string {
        return this.item?.licensePlate || '';
    }

    public onBoosterRemove() {
        this.form.controls['keySerial'].setValue( null );
        this.form.controls['needsDriverID'].setValue( false );
    }
}
