import { NamedNode } from './node';
import { Keyholder, KeyholderHasKey } from './keyholder';

export class Token extends NamedNode {
    identity?: string;
    blacklisted?: boolean;
    blacklistedDate?: string;
    initialized?: boolean;
    initializedDate?: string;

    keyholder?: Keyholder;
}

export class SyncStatus {
    id?: number;
    synced?: boolean;
    syncedDate?: Date;
}

export class Key extends Token {
    serialnr?: string;
    tokens?: Token[];
    blacklisting?: boolean;
    syncStatus?: SyncStatus;
    keyRFID?: string;
}

