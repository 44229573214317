import { BaseEntityListComponent } from './base-entity-list.component';
import { NamedNode } from '../model';
import { Directive } from '@angular/core';

@Directive()
export abstract class EntityListComponent<Item extends NamedNode> extends BaseEntityListComponent<Item> {

    constructor() {
        super();
    }

    protected getName( item: Item): string {
        return item.name || '';
    }
}
