import { Component, ViewEncapsulation } from '@angular/core';

import { Admin } from '../model';
import { BaseEntityListComponent } from '../entity-component/base-entity-list.component';

@Component({
  selector: 'admins-list',
  templateUrl: './admins-list.component.html',
  styleUrls: ['./admins-list.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AdminsListComponent extends BaseEntityListComponent<Admin> {

    protected getEditItemPath(): string {
        return '/edit-admin';
    }

    protected getName( item: Admin): string {
        return item.displayName || '';
    }
}
