
import {interval as observableInterval, Observable} from 'rxjs';
// Import the Component decorator
import {Component, OnInit} from '@angular/core';
import {AuthenticationService, AuthGuard} from './services/authentication.service';
import {Router} from '@angular/router';
import {tokenNotExpired} from './services/jwt.service';
import { MsalBroadcastService } from '@azure/msal-angular';

@Component({
  // We'll call our root component daily-deals
  selector: 'access-control',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  title = 'Access Control';
  authService: AuthenticationService;

  public isCollapsed = true;

  constructor(private authSvc: AuthenticationService, private authGuard: AuthGuard,  private router: Router, private broadcastService: MsalBroadcastService) {
    this.authService = authSvc;
  }

  public ngOnInit() {
    observableInterval(5000).subscribe(
      d => {
        if (!tokenNotExpired() && !this.authGuard.urlIsAllowed(this.router.url)) { // (!this.router.url.startsWith('/login') && this.router.url !== '/podium')) {
          // TODO: login redirection
          this.router.navigate(['/dashboard']);
        }
      },
    );

    // this.broadcastService.msalSubject$.subscribe( (success) => {
    //   // console.log('====> msalSubject$: this is a new user: ' + JSON.stringify(success));
    //   if( success.hasOwnProperty('eventType')  && success['eventType'] === 'msal:acquireTokenSuccess'){
    //     if( !tokenNotExpired()) {
    //       this.router.navigate(['/login']);
    //     }
    //   }
    // });


  }

  public showMenu(url: string) {
    return this.authGuard.urlIsAllowed(url);
  }

  public toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
}
