
import {tap, map} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';


import { Store, Action } from '@ngrx/store';
import { Filter, StoreAction } from '../store/tayarac-store';
import { clone } from '../utils/utils';

import { ItemService } from './item.service';
import { Person, Validity, JsonResponse } from '../model';

export class PERSONS_ACTIONS {
    static SET_PERSONS = 'SET_PERSONS';
    static CREATE_PERSON = 'CREATE_PERSON';
    static UPDATE_PERSON = 'UPDATE_PERSON';
    static DELETE_PERSON = 'DELETE_PERSON';
    static SELECT_PERSON = 'SELECT_PERSON';
    static SET_PERSONS_FILTER = 'SET_PERSONS_FILTER';
    static CLEAR_PERSONS_FILTER = 'CLEAR_PERSONS_FILTER';
}

// The "persons" reducer performs actions on our list of persons
export const persons = personsFn;
export function personsFn( state: Person[] = [], action: StoreAction ) {
    switch ( action.type ) {
        case PERSONS_ACTIONS.SET_PERSONS:
            return action.payload;
        case PERSONS_ACTIONS.CREATE_PERSON:
            let x = state.filter( item => item.id === action.payload.id ).length;
            return x ? state : [...state, action.payload];
        case PERSONS_ACTIONS.UPDATE_PERSON:
            return state.map( item => {
                return item.id === action.payload.id ? clone( action.payload ) : item;
            } );
        case PERSONS_ACTIONS.DELETE_PERSON:
            return state.filter( item => {
                return item.id !== action.payload.id;
            } );
        default:
            return state;
    }
};

// The "selectedPerson" reducer handles the currently selected person
export const selectedPerson = selectedPersonFn;
export function selectedPersonFn( state: Person | null = null, action: StoreAction ) {
    switch ( action.type ) {
        case PERSONS_ACTIONS.SELECT_PERSON:
            return action.payload;
        default:
            return state;
    }
};

export const personsFilter = personsFilterFn;
export function personsFilterFn( state: Filter[] = [], action: StoreAction ) {
    switch ( action.type ) {
        case PERSONS_ACTIONS.SET_PERSONS_FILTER:
            return action.payload;
        case PERSONS_ACTIONS.CLEAR_PERSONS_FILTER:
            return [];
        default:
            return state;
    }
}

@Injectable()
export class PersonsService extends ItemService<Person> {

    private _persons = this.store?.select( 'persons' );
    private _selectedPerson = this.store?.select( 'selectedPerson' );
    private _filter = this.store?.select( 'personsFilter' );

    constructor() {
        super();
    }

    get items(): Observable<Array<Person>> {
        if ( !this._persons )
            this._persons = this.store?.select( 'persons' );
        return this._persons;
    };

    get selectedItem(): Observable<Person> {
        if ( !this._selectedPerson )
            this._selectedPerson = this.store?.select( 'selectedPerson' );
        return this._selectedPerson;
    };

    get filter(): Observable<Array<Filter>> {
        if ( !this._filter )
            this._filter = this.store?.select( 'personsFilter' );
        return this._filter;
    };

    get baseUrl(): string { return this._baseUrl + '/api/persons'; };

    public loadItems(): void {
        this.clearErrors();

        super._getItems().pipe(
            map( pers => this.sanitizeItems( pers ) ),
            map( pers => ( { type: PERSONS_ACTIONS.SET_PERSONS, payload: pers } ) ),)
            .subscribe( action => this.store.dispatch( action ) );
    }

    public deleteItem( person: Person ): void {
        this.clearErrors();
        super._deleteItem( person.id ).pipe(
            map( () => ( { type: PERSONS_ACTIONS.DELETE_PERSON, payload: person } ) ))
            .subscribe( action => this.store.dispatch( action ) );
    }

    public selectItem( person: Person ): void {
        this.clearErrors();
        this.store.dispatch( { type: PERSONS_ACTIONS.SELECT_PERSON, payload: person } );
    }

    public setFilter( filters: any ) {
        this.store.dispatch( { type: PERSONS_ACTIONS.SET_PERSONS_FILTER, payload: filters } );
    }


    public getFullReport(): Observable<string> {
        //        let data: string = "field1,field2,field3\nfield4,field5,field6\n";
        //        return Observable.of(data);

        let params: HttpParams = new HttpParams();

        return this.httpClient.get<JsonResponse<string>>( this.baseUrl + '/full-report', { params: params } ).pipe(
            map( jr => {
                console.log( 'Items data: ', jr )
                if ( jr.status != 'SUCCESS' )
                    throw { errorMsg: jr.errorMsg };
                return jr.data as string;
            } ));

    }

    protected createItem( person: Person ): Observable<Person> {
        return super._addItem( person ).pipe(
            tap( p => this.store.dispatch( { type: PERSONS_ACTIONS.CREATE_PERSON, payload: p } ) ));
    }

    protected updateItem( person: Person ): Observable<Person> {
        return super._updateItem( person ).pipe(
            tap( p => this.store.dispatch( { type: PERSONS_ACTIONS.UPDATE_PERSON, payload: p } ) ));
    }
}
