import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { LocksService } from '../services';

import { Lock, Validity } from '../model';
import { EntityFormComponent } from '../entity-component/entity-form.component';
import { ServiceLocator } from '../services/service.locator';

@Component({
  selector: 'lock-form',
  templateUrl: './lock-form.component.html',
  styleUrls: ['./lock-form.component.scss'],
})
export class LockFormComponent extends EntityFormComponent<Lock> {
  constructor(_itemService: LocksService) {
    super(_itemService);
  }

  protected getEntityName(): string {
    return 'Lock';
  }

  protected createForm(): FormGroup {
    return this.fb.group({
      serialnr: ['', [Validators.required]],
      name: ['', [Validators.required]],
      description: '',
    });
  }

  override ngOnChanges() {
    this.form.patchValue(this.selectedItem || {});
  }

  get isValid() {
    return this.form.valid;
  }

  public override save(item: Lock) {
    console.log('Form value: ', this.form.value);
    let x = Object.assign(new Lock(), item, this.form.value);

    console.log('Object value: ', x);
    //this.debug = x;
    super.save(x);
  }
}
