<span *ngIf="!(ap | async)">Loading...</span>

<div fxLayout="column" fxLayoutAlign="center" class="full-page">
	<div fxFlex="1 0 auto" style="position: relative" class="full-page-content">
		<img src="assets/airport.jpg" width="100%" height="100%" />
		<div class="diag-shadow"></div>
		<div class="floating-diag-box" style="left: 12%; top: 58%;">
			<span class="diag-label fa fa-circle" [ngClass]="{'green':statusPtIN, 'red':!statusPtIN}">PT IN</span>
		</div>
		<div class="floating-diag-box" style="left: 12%; top: 51%;">
			<span class="diag-label fa fa-circle" [ngClass]="{'green':statusPtOUT, 'red':!statusPtOUT}">PT OUT</span>
		</div>
		<div class="floating-diag-box" style="left: 64%; top: 20%;">
			<span class="diag-label fa fa-circle" [ngClass]="{'green':statusAcOUT, 'red':!statusAcOUT}">Ac OUT</span>
		</div>
		<div class="floating-diag-box" style="left: 70%; top: 46%;">
			<span class="diag-label fa fa-circle" [ngClass]="{'green':statusP12P13, 'red':!statusP12P13}">P12/P13</span>
		</div>
		<div class="floating-diag-box" style="left: 75%; top: 63%;">
			<span class="diag-label fa fa-circle" [ngClass]="{'green':statusAcINL, 'red':!statusAcINL}">Ac IN L</span>
		</div>
		<div class="floating-diag-box" style="left: 85%; top: 63%;">
			<span class="diag-label fa fa-circle" [ngClass]="{'green':statusAcINR, 'red':!statusAcINR}">Ac IN R</span>
		</div>
		<div class="floating-diag-box" style="left: 70%; top: 83%;">
			<span class="diag-label fa fa-circle" [ngClass]="{'green':statusResIN, 'red':!statusResIN}">Res IN</span>
		</div>
		<div class="floating-diag-box" style="left: 81%; top: 75%;">
			<span class="diag-label fa fa-circle" [ngClass]="{'green':statusResOUT, 'red':!statusResOUT}">Res OUT</span>
		</div>
	</div>
	<div fxFlex="1 0 auto" class="diag-msg-area">
		<span class="status-messages" *ngFor="let area of statusMsgs">
		  {{area.area}}: 
		  <span *ngFor="let msg of area.msgs" class="status-message fa fa-circle" [ngClass]="{'green':msg.status, 'red':!msg.status}">{{msg.msg}}</span>
		</span>
	</div>
</div>
