import { Component, OnInit } from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';

import { AuthenticationService, AuthGuard } from '../services';
import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';

@Component( {
    selector: 'app-forgotten-password',
    templateUrl: './forgotten-password.component.html',
    styleUrls: ['./forgotten-password.component.scss']
} )

export class ForgottenPasswordComponent implements OnInit {
    model: any = {};
    loading = false;
    error?: string = '';
    requestMsg?: string;

    deviceInfo?: DeviceInfo;

    constructor(
        private router: Router,
        private serializer: UrlSerializer,
        private authenticationService: AuthenticationService ) { }

    ngOnInit() {
        // reset login status
    }


    requestNewPass() {

//        const tree = this.router.createUrlTree( ['login', 'reset'], {} );
//        var url = this.serializer.serialize( tree )
//        url = window.location.protocol + '//' + window.location.host + url;
//        console.log( url );

        this.authenticationService.requestNewPassword( this.model.email )
            .subscribe( result => {
                this.requestMsg = "Request is sent. Check your email";
                this.error = undefined;
            }, e => {
                this.requestMsg = undefined;
                this.error = "Error sending the request"
            } );

    }
}
