
import {map, filter, tap} from 'rxjs/operators';
import { Injectable, Inject } from '@angular/core';

import { Observable } from 'rxjs';


import { Store, Action } from '@ngrx/store';
import { Filter, StoreAction } from '../store/tayarac-store';
import { clone } from '../utils/utils';

import { baseURL, ItemService } from './item.service';
import { TfCompany, Validity, JsonResponse } from '../model';

import { dateReviver } from '../utils/utils';

export class TFCOMPANIES_ACTIONS {
    static SET_TFCOMPANIES = 'SET_TFCOMPANIES';
    static CREATE_TFCOMPANY = 'CREATE_TFCOMPANY';
    static UPDATE_TFCOMPANY = 'UPDATE_TFCOMPANY';
    static DELETE_TFCOMPANY = 'DELETE_TFCOMPANY';
    static SELECT_TFCOMPANY = 'SELECT_TFCOMPANY';
    static SET_TFCOMPANIES_FILTER = 'SET_TFCOMPANIES_FILTER';
    static CLEAR_TFCOMPANIES_FILTER = 'CLEAR_TFCOMPANIES_FILTER';
}

// The "tfCompanies" reducer performs actions on our list of tfCompanies
export const tfCompanies = tfCompaniesFn;
export function tfCompaniesFn( state: TfCompany[] = [], action: StoreAction ) {
    switch ( action.type ) {
        case TFCOMPANIES_ACTIONS.SET_TFCOMPANIES:
            return action.payload;
        case TFCOMPANIES_ACTIONS.CREATE_TFCOMPANY:
            let x = state.filter( item => item.id === action.payload.id ).length;
            return x ? state : [...state, action.payload];
        case TFCOMPANIES_ACTIONS.UPDATE_TFCOMPANY:
            return state.map( item => {
                let val = clone( action.payload );
                return item.id === action.payload.id ? val : item;
            });
        case TFCOMPANIES_ACTIONS.DELETE_TFCOMPANY:
            return state.filter( item => {
                return item.id !== action.payload.id;
            });
        default:
            return state;
    }
};

// The "selectedTfCompany" reducer handles the currently selected tfCompany
export const selectedTfCompany = selectedTfCompanyFn;
export function selectedTfCompanyFn( state: TfCompany|null = null, action: StoreAction ) {
    switch ( action.type ) {
        case TFCOMPANIES_ACTIONS.SELECT_TFCOMPANY:
            return action.payload;
        default:
            return state;
    }
};

export const tfCompaniesFilter = tfCompaniesFilterFn;
export function tfCompaniesFilterFn( state: Filter[] = [], action: StoreAction ) {
    switch ( action.type ) {
        case TFCOMPANIES_ACTIONS.SET_TFCOMPANIES_FILTER:
            return action.payload;
        case TFCOMPANIES_ACTIONS.CLEAR_TFCOMPANIES_FILTER:
            return [];
        default:
            return state;
    }
}

@Injectable()
export class TfCompaniesService extends ItemService<TfCompany> {

    private _tfCompanies?: Observable<Array<TfCompany>>;
    private _selectedTfCompany?: Observable<TfCompany>;
    private _filter?: Observable<Array<Filter>>;

    constructor() {
        super();
    }

    get items(): Observable<Array<TfCompany>> {
        if ( !this._tfCompanies )
            this._tfCompanies = this.store.select( 'tfCompanies' );
        return this._tfCompanies;
    };

    get selectedItem(): Observable<TfCompany> {
        if ( !this._selectedTfCompany )
            this._selectedTfCompany = this.store.select( 'selectedTfCompany' );
        return this._selectedTfCompany;
    };

    get filter(): Observable<Array<Filter>> {
        if ( !this._filter )
            this._filter = this.store.select( 'tfCompaniesFilter' );
        return this._filter;
    };

    get baseUrl(): string { return this._baseUrl + '/api/tfcompanies'; };

    protected override init() {
        super.init();

        this.wsService.asObservable()?.pipe(
            map( d => {
                return JSON.parse( d.data, dateReviver );
            }),filter( d => {
                return ( !!d.tfCompany ) && ( !!d.action );
            }),)
            .subscribe( d => {
                console.log( "TfCompany: ", d.tfCompany );
                switch ( d.action ) {
                    case 'CREATED':
                        this.store.dispatch( { type: TFCOMPANIES_ACTIONS.CREATE_TFCOMPANY, payload: d.tfCompany })
                        break;
                    case 'UPDATED':
                        this.store.dispatch( { type: TFCOMPANIES_ACTIONS.UPDATE_TFCOMPANY, payload: d.tfCompany })
                        break;
                    case 'DELETED':
                        this.store.dispatch( { type: TFCOMPANIES_ACTIONS.DELETE_TFCOMPANY, payload: d.tfCompany })
                        break;
                }
            });
    }

    public loadItems(): void {
        this.clearErrors();

        super._getItems().pipe(
            map( tfComps => this.sanitizeItems( tfComps ) ),
            map( tfComps => new StoreAction( { type: TFCOMPANIES_ACTIONS.SET_TFCOMPANIES, payload: tfComps }) ),)
            .subscribe( action => this.store.dispatch( action ) );
    }

    public deleteItem( tfCompany: TfCompany ): void {
        this.clearErrors();
        super._deleteItem( tfCompany.id ).pipe(
            map(() => ( { type: TFCOMPANIES_ACTIONS.DELETE_TFCOMPANY, payload: tfCompany }) ))
            .subscribe( action => this.store.dispatch( action ) );
    }

    public selectItem( tfCompany: TfCompany ): void {
        this.clearErrors();
        this.store.dispatch( { type: TFCOMPANIES_ACTIONS.SELECT_TFCOMPANY, payload: tfCompany });
    }

    public setFilter( filters: any ) {
        this.store.dispatch( { type: TFCOMPANIES_ACTIONS.SET_TFCOMPANIES_FILTER, payload: filters });
    }

    protected createItem( tfCompany: TfCompany ): Observable<TfCompany> {
        return super._addItem( tfCompany ).pipe(
            tap( tfComp => this.store.dispatch( { type: TFCOMPANIES_ACTIONS.CREATE_TFCOMPANY, payload: tfComp }) ));
    }

    protected updateItem( tfCompany: TfCompany ): Observable<TfCompany> {
        return super._updateItem( tfCompany ).pipe(
            tap( tfComp => this.store.dispatch( { type: TFCOMPANIES_ACTIONS.UPDATE_TFCOMPANY, payload: tfComp }) ));
    }
}
