import {
  Component,
  ViewEncapsulation,
  Output,
  EventEmitter,
} from '@angular/core';

import { Vehicle } from '../model';
import { KeyholdersListComponent } from '../entity-component/keyholders-list.component';
import { tokenHasAnyRole } from '../services/jwt.service';
import { Columns, Config } from 'ngx-easy-table';
import { ConfigService } from '../config';

@Component({
  selector: 'vehicles-list',
  templateUrl: './vehicles-list.component.html',
  styleUrls: ['./vehicles-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class VehiclesListComponent extends KeyholdersListComponent<Vehicle> {
  @Output()
  public download = new EventEmitter();

  public configuration: Config;
  public columnsDef: Columns[] = [];

  constructor() {
    super();
    this.configuration = {
      ...ConfigService.config,
    };
  }

  override ngOnInit() {
  }

  protected getEditItemPath(): string {
    return '/edit-vehicle';
  }

  public canAddVehicle(): boolean {
    return tokenHasAnyRole(['Administrators', 'Metrotax']);
  }
}
