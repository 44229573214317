export class Validity {
  id?: number;
  valid: boolean = false;
  validityStart: Date;
  validityEnd: Date;

  public constructor() {
    this.valid = false;
    this.validityStart = new Date();
    this.validityEnd = new Date();
  }
}
