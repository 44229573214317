
import {of as observableOf,  Observable, EMPTY } from 'rxjs';

import {map, switchMap, filter, distinctUntilChanged, debounceTime, startWith} from 'rxjs/operators';
import { Component, Input, ViewChild, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ItemService } from '../services/item.service';

import { NamedNode, Node } from '../model';

@Component( {
    selector: 'node-unique-name',
    styles: ['.full-width {width: 100%}', 'i {font-size: 20px}', 'i.fa-check {color: rgba(0,255,0,1)}', 'i.fa-close {color: rgba(255,0,0,1)}'],
    template: `<div fxLayout="row" fxLayoutAlign="start">
                    <!-- dividerColor="{{(!input.valid && !input.pristine) ? 'warn' : 'primary' }}" -->
                    <mat-form-field xFlex="1 1 auto" class="full-width">
                          <input matInput [placeholder]="placeholder" type="text" id="name" required [(ngModel)]="value" name="name" [formControl]="input"/>
                          <mat-hint align="start" [class.alert-danger]="!input.valid" *ngIf="!input.valid">{{placeholder}} must not be empty and must be unique within the company</mat-hint>
                    </mat-form-field>
                    <div xFlex="0 1 auto" style="margin: auto 0; text-align: center"><i class="fa" [ngClass]="{'fa-check':(unique | async), 'fa-close':!(unique | async)}"></i></div>
               </div>`
})
export class NodeUniqueNameComponent implements OnInit {

    protected _item: NamedNode = {};
    protected _initialName?: string;
    public unique: Observable<boolean> = EMPTY;

    @Input()
    public input!: FormControl;

    @Input()
    public service!: ItemService<NamedNode>;
    @Input()
    public field: string = 'name';
    @Input()
    public placeholder: string = 'Name';

    constructor() {
    }

    @Input()
    set item( value: NamedNode ) {
        this._item = value;
        this._initialName = (value as any)[this.field];
    }

    get item(): Node {
        return this._item;
    }

    set value( val: string | undefined ) {
        (this._item as any)[this.field] = val;
    }

    get value(): string | undefined {
        return (this._item as any)[this.field];
    }

    ngOnInit() {
        this.unique = this.input.valueChanges.pipe(
            startWith( this._initialName ),
            debounceTime( 500 ),
            distinctUntilChanged(),
            filter( term => !!term ),
            switchMap( term => {
                if ( term == this._initialName || !this.service ) {
                    return observableOf( { 'count': 0 });
                } else {
                    return this.service.searchCount( [{ 'field': this.field, 'operation': 'equal', 'value': term }] );
                }
            }),
            map( d => 0 == d.count ),);
    }

}
