import { Component } from '@angular/core';

import { Lock } from '../model';
import { EntityListComponent } from '../entity-component/entity-list.component';

@Component( {
    selector: 'locks-list',
    templateUrl: './locks-list.component.html',
    styleUrls: ['./locks-list.component.scss'],
})
export class LocksListComponent extends EntityListComponent<Lock> {

    protected getEditItemPath(): string {
        return '/edit-lock';
    }

}
