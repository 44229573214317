import { Component } from '@angular/core';

import { Area } from '../model';
import { EntityListComponent } from '../entity-component/entity-list.component';

@Component( {
    selector: 'areas-list',
    templateUrl: './areas-list.component.html',
    styleUrls: ['./areas-list.component.scss'],
})
export class AreasListComponent extends EntityListComponent<Area> {

    protected getEditItemPath(): string {
        return '/edit-area';
    }

}
