<span *ngIf="!(ap | async)">Loading...</span>


<div class="content full-page t-grid t-flex-column t-flex-nowrap t-gap-4 t-justify-content-start t-align-content-center">
       <div class="full-page-content t-grid t-flex-row t-flex-nowrap t-justify-content-between t-align-content-center t-gap-4" style="flex: 0 0 20%; max-height: 20%;">
            <div class="header area separate t-col-9 t-grid t-flex-column t-flex-nowrap t-justify-content-start t-align-content-center">
                <div class="t-flex-auto t-grid t-flex-row t-align-content-center" *ngIf="(areas | async ) as a">
                    <div class="pad t-flex-auto">{{ (recovery || a?.T2?.recoveryMode) ? 'R' : ((express || !a?.T2?.inUse) ? 'X' : a?.T2?.occupancy)}}</div>
                    <div class="pad t-flex-auto">{{ recovery ? 'R' : ((express || !a?.T1?.inUse) ? 'X' : a?.T1?.occupancy)}}</div>
                    <div class="pad t-flex-auto">{{ recovery ? 'R' : a?.T0?.occupancy}}</div>
                </div>
                <div  class="t-flex-auto t-grid t-flex-row t-align-content-center">
                    <div class="pad t-flex-auto" >
                        <div class="pad-btn" [ngClass]="{'express-mode': express}">Pt</div>
                    </div>
                    <div class="pad t-flex-auto">
                        <div class="pad-btn" [ngClass]="{'express-mode': express}">Re</div>
                    </div>
                    <div class="pad t-flex-auto">
                        <div class="pad-btn">Ac</div>
                    </div>
                </div>
            </div>
            <div class="header area total t-col-3 t-grid t-flex-column t-flex-nowrap t-justify-content-start t-align-content-center">
                <div class="t-flex-auto t-grid t-flex-row t-align-content-center">
                    <div class="pad t-flex-auto">{{ recovery ? 'R' : (totalCars | async) }}</div>
                </div>
                <div class="t-flex-auto t-grid t-flex-row t-align-content-center">
                    <div class="pad t-flex-auto">
                        <div class="pad-btn">T</div>
                    </div>
                </div>
            </div>
       </div>

       <div class="full-page-content t-grid t-flex-row t-flex-nowrap t-justify-content-between t-align-content-center t-gap-4" style="flex: 0 0 50%; max-height: 50%;">
            <div class="area body-area board t-col-9 t-grid t-flex-column t-flex-nowrap t-justify-content-around t-align-content-center t-gap-2">
                <ng-template #tooltipContent  let-name="name" let-area="area">
                     <table>
                         <thead>
                             <tr>
                                 <th>License plate</th>
                                 <th>Merk</th>
                                 <th>Model</th>
                                 <th>Color</th>
                                 <th>Company</th>
                                 <th *ngIf="'T0' === name">Assigned to</th>
                             </tr>
                         </thead>
                         <tbody>
                             <tr *ngFor="let car of area">
                                 <td>{{car.licensePlate}}</td>
                                 <td>{{car.properties?.merk}}</td>
                                 <td>{{car.properties?.model}}</td>
                                 <td>{{car.properties?.color}}</td>
                                 <td>
                                     <i *ngIf="-1 !== car.carFeatures.indexOf('UNITAX')">UT</i>
                                     <i *ngIf="-1 !== car.carFeatures.indexOf('AUTOTAX')">AL</i>
                                     <i *ngIf="-1 !== car.carFeatures.indexOf('TWALTER')">TW</i>
                                 </td>
                                 <td *ngIf="'T0' === name">{{car.assignedTo ? car.assignedTo : ' '}}</td>
                             </tr>
                         </tbody>
                     </table>
                </ng-template>
                <div [ngClass]="['area-content']" class="t-flex-auto t-grid t-flex-row t-flex-nowrap t-justify-content-around t-align-content-center" *ngFor="let f of ['TAXI', 'BREAK', 'MINIVAN']">
                    <div class="pad" style="flex: 1 1 26%;" >
                        <display-area class="small" [type]="f" area="T2" [present]="hasFeatures( allFeatures | async, $any(f), 'T2')" (click)="op2.toggle($event)"></display-area>
                        <p-overlayPanel #op2 class="car-tooltip">
                            <ng-container *ngTemplateOutlet="tooltipContent; context: {area: getAllCars(allFeatures | async, $any(f), ['T2'])}"></ng-container>
                        </p-overlayPanel>
                    </div>
                    <div class="pad" style="flex: 1 1 11%;"></div>
                    <div class="pad" style="flex: 1 1 26%;" >
                        <display-area class="small" [type]="f" area="T1"  [present]="hasFeatures( allFeatures | async, $any(f), 'T1')" [inApproach]="hasFeatures( allFeatures | async, $any(f), 'T2T1')" (click)="op1.toggle($event)"></display-area>
                        <p-overlayPanel #op1 class="car-tooltip">
                            <ng-container *ngTemplateOutlet="tooltipContent; context: {area: getAllCars(allFeatures | async, $any(f), ['T2T1','T1'])}"></ng-container>
                        </p-overlayPanel>
                    </div>
                    <div class="pad" style="flex: 1 1 11%;"></div>
                    <div class="pad" style="flex: 1 1 26%;" >
                        <display-area class="small" [type]="f" area="T0"  [present]="hasFeatures( allFeatures | async, $any(f), 'T0')" [inApproach]="hasFeatures( allFeatures | async, $any(f), 'T1T0')" (click)="op0.toggle($event)"></display-area>
                        <p-overlayPanel #op0 class="car-tooltip">
                            <ng-container *ngTemplateOutlet="tooltipContent; context: {area: getAllCars(allFeatures | async, $any(f), ['T1T0','T0']), name: 'T0'}"></ng-container>
                        </p-overlayPanel>
                    </div>
                </div>
            </div>

            <div *ngIf="multiSelect" class="area body-area buttons t-col-3 t-grid t-flex-column t-flex-nowrap t-justify-content-start t-align-content-center t-gap-2" [ngClass]="{'br1':1==brigadier, 'br2':2==brigadier, 'br3':3==brigadier}">
                <div class="t-flex-auto t-grid t-flex-row t-justify-content-center t-gap-2" >
                    <div class="t-flex-auto t-grid t-flex-column t-justify-content-center t-gap-2">
                        <button mat-button-toggle type="button" class="pad-btn t-flex-auto" [ngClass]="{'checked': multiFeature['BREAK']}" (click)="toggleFeature($event, 'BREAK')">
                            <mat-icon svgIcon="break" class="mat-icon break car-icon"></mat-icon>
                        </button>
                        <button mat-button-toggle type="button" class="pad-btn t-flex-auto" [ngClass]="{'checked': multiFeature['MINIVAN']}" (click)="toggleFeature($event, 'MINIVAN')">
                            <mat-icon svgIcon="minivan" class="mat-icon minivan car-icon"></mat-icon>
                        </button>
                    </div>
                    <div class="t-flex-auto t-grid t-flex-column t-justify-content-center t-gap-2">
                        <button type="button" class="pad-btn t-flex-auto" [ngClass]="{'checked': multiFeature['AUTOTAX']}" (click)="toggleFeature($event, 'AUTOTAX')">
                            <span class="btn-letter">A</span>
                        </button>
                        <button type="button" class="pad-btn t-flex-auto" [ngClass]="{'checked': multiFeature['UNITAX']}" (click)="toggleFeature($event, 'UNITAX')">
                            <span class="btn-letter">U</span>
                        </button>
                        <button type="button" class="pad-btn t-flex-auto" [ngClass]="{'checked': multiFeature['TWALTER']}" (click)="toggleFeature($event, 'TWALTER')">
                            <span class="btn-letter">W</span>
                        </button>
                    </div>
                </div>
                <div class="t-flex-auto t-grid t-flex-column t-justify-content-center" *ngIf="(0 < selectedFeatures.length)">
                    <button mat-raised-button type="button" class="pad-btn btn-ok t-flex-auto" [ngStyle]="{'width': '100%'}" (click)="confirmMultiSelect($event)">OK</button>
                </div>
                <div class="t-flex-auto t-grid t-flex-column t-justify-content-center" *ngIf="(0 == selectedFeatures.length)">
                    <button mat-raised-button type="button" class="pad-btn btn-ok-disabled t-flex-auto" [ngStyle]="{'width': '100%'}">OK</button>
                </div>
            </div>

            <div *ngIf="!multiSelect" class="area body-area calls t-col-3 t-grid t-flex-column t-flex-nowrap t-justify-content-start t-align-content-center t-gap-5"
                [ngClass]="{'br1':1==brigadier, 'br2':2==brigadier, 'br3':3==brigadier}">
                 <div class="info-car t-flex-shrink-1 t-grid t-flex-row t-flex-nowrap t-justify-content-center" *ngFor="let r of (requests | async)" [ngStyle]="{'width':'100%'}"
                    [ngClass]="{'br1': r?.brigadier === 1, 'br2': r?.brigadier === 2, 'br3': r?.brigadier === 3}" (click)="uncallRequest($event, r)">
                    <span class="t-flex-grow-1"></span>
                    <div style="flex: 0 0 auto;">
                      <i class="fa fa-phone" *ngIf="!r.inT0"></i>
                      <i class="fa fa-thumbs-up" *ngIf="r.inT0"></i>
                    </div>
                    <div style="flex: 0 0 auto;">
                      <mat-icon svgIcon="BREAK" class="mat-icon break car-icon" *ngIf="r.featureRequested && r.featureRequested.indexOf('BREAK') > -1"></mat-icon>
                      <mat-icon svgIcon="MINIVAN" class="mat-icon minivan car-icon" *ngIf="r.featureRequested && r.featureRequested.indexOf('MINIVAN') > -1"></mat-icon>
                      <mat-icon svgIcon="TAXI" class="mat-icon taxi car-icon" *ngIf="!r.featureRequested || (r.featureRequested.indexOf('BREAK') === -1 && r.featureRequested.indexOf('MINIVAN') === -1)"></mat-icon>
                    </div>
                    <div style="flex: 0 0 auto;">
                      <span class="btn-letter"  *ngIf="r.featureRequested && r.featureRequested.indexOf('AUTOTAX') > -1">A</span>
                      <span class="btn-letter"  *ngIf="r.featureRequested && r.featureRequested.indexOf('UNITAX') > -1">U</span>
                      <span class="btn-letter"  *ngIf="r.featureRequested && r.featureRequested.indexOf('TWALTER') > -1">W</span>
                      <span class="btn-letter"  *ngIf="!r.featureRequested || (r.featureRequested.indexOf('TWALTER') === -1 && r.featureRequested.indexOf('UNITAX') === -1 && r.featureRequested.indexOf('AUTOTAX') === -1)">X</span>
                    </div>
                    <span class="t-flex-grow-1"></span>
                 </div>
            </div>
        </div>

       <div class="full-page-content t-grid t-flex-row t-flex-nowrap t-justify-content-between t-align-content-center t-gap-4" style="flex: 0 0 10%; max-height: 10%;">
            <div class="header area extra full-width t-grid t-flex-row t-align-content-center t-flex-auto t-gap-2" style="border: none;" [ngClass]="{'br1':1==brigadier, 'br2':2==brigadier, 'br3':3==brigadier}">
                    <div class="t-flex-auto" *ngIf="0!=brigadier">
                        <button mat-raised-button color="primary" type="button" class="pad-btn" [ngClass]="{'pad-btn-checked': multiSelect}" (click)="showMultiSelect($event)">
                            <mat-icon svgIcon="minivan" class="minivan car-icon"></mat-icon>
                            <mat-icon svgIcon="break" class="break car-icon"></mat-icon>
                        </button>
                    </div>
                    <div class="t-flex-auto">
                        <button mat-raised-button type="button" class="pad-btn" [ngClass]="{'express-btn': express}" fxFlex="1 1 auto" (click)="toggleExpress()">
                            EXPRESS
                        </button>
                    </div>
            </div>
       </div>

</div>
