<div class="container-fluid">
       <form class="full-width t-grid t-flex-row t-gap-3 t-align-content-center t-flex-wrap" #searchForm >
            <mat-form-field class="t-flex-auto">
                <input matInput #licensePlate placeholder="Filter by license plate" [autofocus]="true" class="full-width" (keyup.enter)="sendCmd(searchForm)" />
            </mat-form-field>
            <div class="t-flex-auto calendar-field" *ngIf="showDate">
                <label class="t-flex-auto" for="filterDate">For date:</label>
                <p-calendar class="t-flex-auto" id="filterDate" name="filterDate" #filterDate [showTime]="false" [showIcon]="true"></p-calendar>
            </div>

            <div class="t-flex-auto t-grid t-flex-row t-gap-3 t-align-content-center">
              <div style="height: 10px;"></div>
              <button class="t-col-5 btn btn-primary" mat-raised-button (click)="sendCmd(searchForm)" type="button">Filter</button>
              <button class="t-col-5 btn btn-primary" mat-raised-button (click)="licensePlate.value = ''; searchForm.filterDate.value = ''; sendCmd(searchForm)" type="button">Reset</button>
              <div style="height: 10px;"></div>
            </div>
       </form>

       <p-table #dt [value]="(alerts | async) || []" [tableStyle]="{'min-width': '60rem'}" [loading]="loading" [resizableColumns]="true"
	       [rows]="20" [paginator]="true" [pageLinks]="3" [rowsPerPageOptions]="[10,20,50,100]" [reorderableColumns]="true" >
           <ng-template pTemplate="caption">
                <div class="pull-left"><h4>Transitions</h4></div>
                <div class="pull-right">
	                <button mat-icon-button color="primary" (click)="dt.exportCSV()" type="button">
	                       <mat-icon fontSet="fa" fontIcon="fa-file-archive-o" class="mat-24"></mat-icon>
	                </button>
	            </div>
                <div class="clearfix"></div>
            </ng-template>

            <ng-template pTemplate="header">
                <tr>
                    <th>Time</th>
                    <th>Car</th>
                    <th>Driver</th>
                    <th>Entry</th>
                    <th>Old state</th>
                    <th>New state</th>
                    <th>Granted</th>
                    <th>Message</th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-car>
                <tr>
                    <td>{{ car.time | date: 'dd/MM/yyyy HH:mm:ss'}}</td>
                    <td>{{ car.car }}</td>
                    <td>{{ car.driver }}</td>
                    <td>{{ car.entry }}</td>
                    <td>{{ car.from }}</td>
                    <td>{{ car.to }}</td>
                    <td><span [ngClass]="{'red': !car.granted, 'green': car.granted }">{{car.granted}}</span></td>
                    <td>{{ car.msg }}</td>
                </tr>
            </ng-template>

        </p-table>
</div>
