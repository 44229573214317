export class LoginCredentials {
    username: string;
    password: string;
    company: string

    public constructor( username: string, password: string, company: string) {
        this.username = username;
        this.password = password;
        this.company = company;
    }
}
